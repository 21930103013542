// @flow
import React, { Component } from 'react'
import {
  withStyles,
  Grid,
  Input,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { compose } from 'recompose'
import SubmitButton from './SubmitButton'

const styles = theme => {
  const { unit } = theme.spacing
  return {
    title: {
      textAlign: 'center'
    },
    grid: {
      marginTop: `${unit * 8}px`
    },
    formControl: {
      margin: `${unit}px 0`
    },
    submit: {
      margin: `${unit * 2}px 0`,
      fontWeight: 'bold'
    }
  }
}

type Props = {
  onChangeForm: (param: string) => (e: any) => void,
  onLogin: (e: any) => Promise<any>,
  input: {
    email: string,
    password: string
  },
  error: {
    email: string,
    password: string
  },
  loading: boolean
}

type State = {}

class LoginForm extends Component<Props, State> {
  render() {
    const { classes, input, error, onChangeForm, onLogin, loading } = this.props

    return (
      <form onSubmit={onLogin}>
        <h2 className={classes.title}>ログイン</h2>
        <FormControl
          className={classes.formControl}
          error={!!error.email}
          fullWidth
        >
          <InputLabel htmlFor="email" shrink>
            メールアドレス
          </InputLabel>
          <Input
            id="email"
            type="email"
            onChange={onChangeForm('email')}
            value={input.email}
            autoFocus={true}
            required
          />
          <FormHelperText>{error.email}</FormHelperText>
        </FormControl>
        <FormControl
          className={classes.formControl}
          error={!!error.password}
          fullWidth
        >
          <InputLabel htmlFor="adornment-password" shrink>
            パスワード
          </InputLabel>
          <Input
            type="password"
            id="adornment-password"
            autoComplete="off"
            onChange={onChangeForm('password')}
            value={input.password}
            required
          />
          <FormHelperText>{error.password}</FormHelperText>
        </FormControl>
        <Grid container justify="center">
          <Grid item xs={6}>
            <SubmitButton
              className={classes.submit}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              disabled={loading}
            >
              ログイン
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default compose(withStyles(styles))(LoginForm)
