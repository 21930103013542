/* @flow */
/* 靴管理(品番単位) */
import produce from 'immer'

import * as ActionTypes from '../../constants'

type State = {
  loading: {
    csvUpload: boolean,
    csvDownload: boolean
  }
}

const initialState: State = {
  loading: {
    csvUpload: false,
    csvDownload: false
  }
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SHOES_PRODUCT_CSV_DOWNLOAD_REQUEST:
        draft.loading.csvDownload = true
        break
      case ActionTypes.SHOES_PRODUCT_CSV_DOWNLOAD_SUCCESS:
        draft.loading.csvDownload = false
        break
      case ActionTypes.SHOES_PRODUCT_CSV_DOWNLOAD_FAILURE:
        draft.loading.csvDownload = false
        break
      default:
    }
  })
}
