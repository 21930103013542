import { grey } from '@material-ui/core/colors'

export default ({ spacing }) => {
  return {
    row: {
      padding: `${spacing.unit * 3}px`
    },
    files: {
      backgroundColor: `${grey[100]}`,
      position: 'relative',
      minHeight: '160px'
    },
    drop: {
      marginTop: '20px',
      textAlign: 'center',
      padding: '20px',
      backgroundColor: `${grey[100]}`,
      border: `3px dotted ${grey[300]}`
    },
    text: {
      fontSize: '12px',
      margin: '10px 0 0 0'
    },
    titleBar: {
      background: 'none',
      pointerEvents: 'none'
    },
    titleBarIcon: {
      pointerEvents: 'auto'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    buttons: {
      margin: '0 5px'
    }
  }
}
