export default ({ spacing }) => ({
  row: {
    padding: `${spacing.unit * 3}px`
  },
  center: {
    textAlign: 'center'
  },
  input: {
    maxWidth: '50px'
  },
  buttons: {
    marginTop: `${spacing.unit * 4}px`,
    textAlign: 'center'
  },
  button: {
    margin: `${spacing.unit}px`
  }
})
