/* @flow */
import * as actionTypes from '../constants'
import { apiClient } from '../api/v1'

export function onChange(param, value) {
  return {
    type: actionTypes.SHOES_PUBLIC_FORM_CHANGE,
    payload: { param, value }
  }
}

export function loadClients() {
  return function() {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'get',
        url: `/clients?take=9999`
      })
      console.log(data)
      if (data.statusCode === 200) {
        return resolve(data.data)
      }
      return reject()
    })
  }
}
