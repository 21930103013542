import produce from 'immer'
import * as ActionTypes from '../../constants'

const initialState = {
  error: '',
  totalCount: 0,
  searchParams: {
    take: 10,
    skip: 0,
    digitizerCode: '',
    clientCode: undefined
  },
  columns: [
    {
      name: 'digitizerCode',
      title: 'デジタイザコード',
      sortingEnabled: false,
      columnName: 'digitizerCode'
    },
    {
      name: 'clientCode',
      title: 'クライアントコード',
      sortingEnabled: false,
      columnName: 'clientCode'
    },
    {
      name: 'createdTime',
      title: '作成日',
      sortingEnabled: false,
      columnName: 'createdTime'
    },
    {
      name: 'tool',
      title: '操作',
      sortingEnabled: false,
      columnName: 'tool'
    }
  ],
  loading: false,
  indexList: [],
  clients: [],
  detail: {}
}

export default (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.DIGITIZER_INDEX_SUCCESS: {
        const { data, totalCount } = action.payload
        draft.indexList = data
        draft.totalCount = totalCount
        break
      }
      case ActionTypes.DIGITIZER_UPDATE_SEARCH: {
        Object.assign(draft.searchParams, action.payload)
        break
      }
      case ActionTypes.DIGITIZER_CLIENTS_FETCH: {
        draft.clients = action.payload
        break
      }
      case ActionTypes.DIGITIZER_CLIENTS_CHANGE: {
        const { type, value } = action.payload
        draft.searchParams[type] = value
        break
      }
      case ActionTypes.DIGITIZER_CLIENTS_SEARCH_CLEAR: {
        draft.searchParams = initialState.searchParams
        break
      }
      default:
    }
  })
}
