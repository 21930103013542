/* @flow */
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/shoesProductDetail'

import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Grid,
  Button,
  TextField,
  Chip
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

type Props = {
  variation: Object,
  common: Object,
  actions: Object
}

class FormSizeList extends React.Component<Props> {
  handleAddSize = () => {
    const { input } = this.props.variation
    this.props.actions.registerSize({
      meshId: input.mesh.id,
      variationId: input.id,
      makerWidth: input.makerWidth,
      ffMakerWidth: input.ffMakerWidth
    })
  }

  handleDelete = measurementId => () => {
    const { meshId } = this.props
    this.props.actions.deleteSize(measurementId, meshId)
  }

  inputSize = e => {
    const { input } = this.props.variation
    this.props.actions.inputSize({
      variationId: input.id,
      key: 'makerSize',
      value: e.target.value + ''
    })
  }

  inputFFSize = e => {
    const { input } = this.props.variation
    this.props.actions.inputFFSize({
      variationId: input.id,
      key: 'ffMakerSize',
      value: e.target.value + ''
    })
  }

  inputJancode = e => {
    const { input } = this.props.variation
    this.props.actions.inputSize({
      variationId: input.id,
      key: 'jancode',
      value: e.target.value + ''
    })
  }

  render() {
    const { common, variation } = this.props
    const { input, newSize } = variation

    if (!input.items) {
      return <div />
    }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          {input.items.map(item => (
            <List dense={false} key={item.id}>
              <ListItem divider={true}>
                <ListItemText
                  primary={`${item.measurement.makerSize} / ${
                    item.measurement.ffMakerSize
                  } ${common.unit}`}
                  secondary={`JANコード: ${item.jancode}`}
                />
                <Chip
                  label={`${item.publicCount}件`}
                  style={{ marginRight: '30px' }}
                  variant="outlined"
                  color={item.publicCount > 0 ? 'secondary' : 'default'}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={this.handleDelete(item.measurement.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <TextField
            onChange={this.inputSize}
            value={newSize.input.makerSize}
            placeholder="サイズ"
            style={{ marginRight: '10px' }}
          />
          <TextField
            onChange={this.inputFFSize}
            value={newSize.input.ffMakerSize}
            placeholder="cmサイズ"
            style={{ marginRight: '10px' }}
          />
          <TextField
            onChange={this.inputJancode}
            value={newSize.input.jancode}
            placeholder="JANコード"
          />
          <Button
            variant="contained"
            color="primary"
            className="bold"
            style={{ marginLeft: '20px' }}
            disabled={newSize.input.makerSize.length <= 0 || newSize.loading}
            onClick={this.handleAddSize}
          >
            追加
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  common: state.pages.shoesProductDetail.formShoesCommon
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FormSizeList)
