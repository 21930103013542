/* @flow */
import * as React from 'react'
import { confirmable } from 'react-confirm'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700]
    }
  }
})

const styles = theme => ({
  text: {
    color: '#000',
    fontSize: '14px',
    minWidth: '300px'
  }
})

const Confirmation = props => {
  const {
    okLabel = 'はい',
    cancelLabel = 'いいえ',
    confirmation,
    show,
    proceed,
    cancel,
    classes
  } = props

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog open={show}>
        <DialogContent>
          <DialogContentText className={classes.text}>
            {confirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            className="bold"
            onClick={cancel}
            color="primary"
          >
            {cancelLabel}
          </Button>
          <Button
            size="small"
            variant="contained"
            className="bold"
            onClick={proceed}
            color="primary"
            autoFocus
          >
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export default compose(
  withStyles(styles),
  confirmable
)(Confirmation)
