import * as React from 'react'
import { Route } from 'react-router-dom'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Sidebar from './Sidebar'

function MainLayout({ component: Component, classes, menu, ...props }) {
  return (
    <Route
      {...props}
      render={matchProps => (
        <div className={classes.wrapper}>
          {menu && (
            <div className={classes.sidebar}>
              <Sidebar />
            </div>
          )}
          <MainContents menu={menu}>
            <Component {...matchProps} />
          </MainContents>
        </div>
      )}
    />
  )
}

const styles = theme => ({
  wrapper: {
    display: 'flex'
  },
  sidebar: {
    width: '200px',
    background: '#fff',
    minHeight: 'calc(100vh - 34px)'
  }
})

const mapStateToProps = state => ({
  menu: state.common.app.menu
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(MainLayout)

const MainContents = styled.div`
  padding: 0 30px;
  width: ${({ menu }) => (menu ? 'calc(100% - 200px)' : '100%')};
  padding-bottom: 100px;
`
