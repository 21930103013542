/* @flow */
import * as React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import each from 'lodash/each'
import styled from 'styled-components'

import {
  Paper,
  Grid,
  Button,
  TextField,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

import AutoSelect from '../components/AutoSelect'
import ListTable from '../components/ListTable'
import SelectionStateChange from '../components/SelectionStateChange'
import * as actions from '../actions/app'
import * as shoesPublicActions from '../actions/shoesPublic'
import withShoesData from '../hocs/withShoesData'
import withPage from '../hocs/withPage'
import styles from '../styles/ListPage.style'
import PageTitle from '../components/PageTitle'

type Props = {
  actions: Object,
  classes: Object,
  table: Object,
  handleChange: Function,
  handleBrandsChange: Function,
  handleProvidersChange: Function,
  handlePageSizeChange: Function,
  handleChangeCurrentPage: Function,
  handleChangeSorting: Function,
  handleClear: Function,
  handleSelection: Function,
  handleDeleteRow: Function,
  handleHiddenColumnNamesChange: Function,
  handleUpdateFeedback: Function,
  masterData: Object,
  csvLoading: Object,
  handleChangeState: Function,
  handlePublicStatusChange: Function,
  pageName: string
}

type State = {
  clients: Array<any>
}

class ShoesPublicPage extends React.Component<Props, State> {
  state = {
    clients: []
  }

  handleCsvDownload = () => {
    this.props.actions.downloadCsv(this.props.pageName)
  }

  handleChange = (param, value) => {
    this.props.shoesPublicActions.onChange(param, value)
  }

  mappedRows() {
    const { masterData } = this.props
    const { rows } = this.props.table.searchSettings
    return rows.map(row => {
      const tmp: Object = {
        id: row.id,
        productNumber: row.product.productNumber,
        brand: null,
        category: null,
        productName: row.product.productName,
        provider: null,
        toe: null,
        heelType: null,
        heelHeight: row.product.heelHeight,
        strap: null,
        targetName: row.product.targetName,
        makerColor: row.mesh.makerColor,
        makerMaterial: row.mesh.makerMaterial,
        ffColor: row.mesh.ffColor,
        ffMaterial: row.mesh.ffMaterial,
        makerSize: row.measurement.makerSize,
        makerWidth: row.measurement.makerWidth,
        ffSize: row.measurement.ffSize,
        ffWidth: row.measurement.ffWidth,
        pictures: row.mesh.pictures.length,
        threeImages: row.measurement.threeImage.length,
        workProcessName: row.workProcessName,
        publicFlag: row.publicFlag,
        jancode: row.jancode,
        comment: row.product.comment,
        feedback: row.feedback,
        createdTime: row.createdTime,
        updatedTime: row.updatedTime,
        shoeTypeName: row.product.shoeTypeName,
        shoelace: row.product.shoelace ? 'あり' : 'なし',
        patternName: row.mesh.patternName,
        ffMakerSize: row.measurement.ffMakerSize,
        publicCount: row.publicCount
      }
      // ブランド
      const brand = masterData.brand.find(b => b.id === row.product.brandId)
      tmp.brand = brand ? brand.name : ''
      // カテゴリー
      const category = masterData.category.find(
        b => b.id === row.product.categoryId
      )
      tmp.category = category ? category.name : ''
      // 提供
      const provider = masterData.provider.find(
        b => b.id === row.product.providerId
      )
      tmp.provider = provider ? provider.name : ''
      // ストラップの有無
      tmp.strap = row.product.strap ? 'あり' : 'なし'
      // つま先形状
      const toe = masterData.toe.find(b => b.id === row.product.toeId)
      tmp.toe = toe.name || ''
      // ヒール形状
      const heel = masterData.heelType.find(
        b => b.id === row.product.heelTypeId
      )
      tmp.heelType = heel ? heel.name : ''
      // 0がboolean判定されてしまうので文字でキャスト
      each(tmp, (value, key) => {
        if (value === 0) {
          tmp[key] = value + ''
        }
      })
      return tmp
    })
  }

  async componentDidMount() {
    const clients = await this.props.shoesPublicActions.loadClients()
    console.log(clients)
    this.setState({ clients })
  }

  render() {
    const {
      classes,
      table,
      handlePageSizeChange,
      handleChangeCurrentPage,
      handleChangeSorting,
      handleClear,
      handleSelection,
      handleDeleteRow,
      handleHiddenColumnNamesChange,
      handleUpdateFeedback,
      masterData,
      handleChangeState,
      shoesPublicActions
    } = this.props

    return (
      <div>
        <PageTitle title="靴公開" />
        <Grid className={classes.headButton} container spacing={8}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel shrink>クライアント選択</InputLabel>
              <Select
                onChange={e => {
                  shoesPublicActions.onChange('clientId', e.target.value)
                }}
                value={table.searchParams.clientId == null ? "": table.searchParams.clientId}
              >
                {this.state.clients.map(c => {
                  return (
                    <MenuItem key={c.id} value={c.id}>
                      {c.companyName} ({c.storeName})
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <SearchMenu>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="品番検索"
                  InputLabelProps={{ shrink: true }}
                  value={table.searchParams.productNumber || ''}
                  onChange={e =>
                    this.handleChange('productNumber', e.target.value)
                  }
                  margin="dense"
                  variant="outlined"
                  disabled={table.searchParams.clientId == null}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="JANコード検索"
                  InputLabelProps={{ shrink: true }}
                  value={table.searchParams.jancode || ''}
                  onChange={e => this.handleChange('jancode', e.target.value)}
                  margin="dense"
                  variant="outlined"
                  disabled={table.searchParams.clientId == null}
                />
              </Grid>

              {/* ブランド */}
              <Grid item xs={6}>
                <AutoSelect
                  label="ブランド検索"
                  multiple={true}
                  onChange={value => this.handleChange('brandIds', value)}
                  value={table.searchParams.brandIds}
                  suggestions={masterData.brand}
                  disabled={table.searchParams.clientId == null}
                />
              </Grid>

              {/* プロバイダー */}
              <Grid item xs={6}>
                <AutoSelect
                  label="プロバイダー検索"
                  multiple={true}
                  onChange={value => this.handleChange('providerIds', value)}
                  value={table.searchParams.providerIds}
                  suggestions={masterData.provider}
                  disabled={table.searchParams.clientId == null}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container alignItems="center">
                  {/* 更新日検索 */}
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="更新日(From)"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={table.searchParams.updatedTimeFrom || ''}
                      onChange={e =>
                        this.handleChange('updatedTimeFrom', e.target.value)
                      }
                      disabled={table.searchParams.clientId == null}
                      required
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: 'center' }}>〜</div>
                  </Grid>
                  {/* 更新日検索 */}
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="更新日(To)"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={table.searchParams.updatedTimeTo || ''}
                      onChange={e =>
                        this.handleChange('updatedTimeFrom', e.target.value)
                      }
                      disabled={table.searchParams.clientId == null}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* 作業ステータス */}
              <Grid item xs={6}>
                <AutoSelect
                  label="公開ステータス"
                  onChange={value => this.handleChange('publicFlag', value)}
                  value={table.searchParams.publicFlag}
                  suggestions={[
                    { id: 'true', name: '公開' },
                    { id: 'false', name: '非公開' }
                  ]}
                  disabled={table.searchParams.clientId == null}
                />
              </Grid>

              <Grid container xs={12} item justify="flex-end" spacing={24}>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      this.props.handleRemoveSkip()
                      this.props.updateSearchCondition()
                    }}
                    disabled={
                      table.loading || table.searchParams.clientId == null
                    }
                  >
                    検索する
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={handleClear}
                    disabled={
                      table.loading || table.searchParams.clientId == null
                    }
                  >
                    検索条件をクリア
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SearchMenu>

        <Paper>
          <ListTable
            loading={table.loading}
            searchParams={table.searchParams}
            searchSettings={table.searchSettings}
            changePageSize={pageSize => handlePageSizeChange(pageSize)}
            changeCurrentPage={currentPage =>
              handleChangeCurrentPage(currentPage)
            }
            changeSorting={sorting => handleChangeSorting(sorting)}
            changeSelection={selection => handleSelection(selection)}
            rows={this.mappedRows()}
            deleteRow={handleDeleteRow}
            customCell="link"
            customCellLinkTo={value => `/shoes/item/${value}`}
            feedbackHandler={handleUpdateFeedback}
            hiddenColumnNamesChange={hiddenColumnNames =>
              handleHiddenColumnNamesChange(hiddenColumnNames)
            }
          >
            <SelectionStateChange
              label="公開ステータスを更新"
              selections={table.searchSettings.selections}
              statusList={[
                { id: 'true', status: '公開' },
                { id: 'false', status: '非公開' }
              ]}
              changeState={handleChangeState('publicFlag')}
              disabled={
                table.searchSettings.selections.length <= 0 ||
                table.searchParams.clientId == null
              }
            />
          </ListTable>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  page: state.pages.shoesItem,
  loading: state.pages.shoesItem.loading,
  masterData: state.masterData,
  pageName: 'shoesPublic'
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  shoesPublicActions: bindActionCreators({ ...shoesPublicActions }, dispatch)
})

export default compose(
  withPage(),
  withStyles(styles),
  withShoesData('shoesPublic'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShoesPublicPage)

const SearchMenu = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px 0;
`
