export default theme => ({
  form: {},
  divider: {
    margin: '50px 0'
  },
  row: {
    marginBottom: '30px'
  },
  buttons: {
    textAlign: 'right'
  }
})
