import queryString from 'query-string'
import convertKeys from 'convert-keys'
import { push } from 'react-router-redux'
import crateToast from '../utils/createToast'
import * as ActionTypes from '../constants'
import { apiClient } from '../api/v1'

export function updateSearchCondition() {
  return function(dispatch, getState) {
    const location = getState().router.location
    const searchParams = getState().pages.adminUsers.searchParams
    const searchParamStr = queryString.stringify(
      convertKeys.toSnake(searchParams)
    )
    const url = `${location.pathname}?${searchParamStr}${location.hash}`
    dispatch(push(url))
    dispatch(loadIndexData())
  }
}

export function loadIndexData() {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: ActionTypes.ADMIN_USERS_INDEX_REQUEST })
      const {
        pages: { adminUsers }
      } = getState()
      const response = await apiClient({
        method: 'get',
        url: `/admin?${queryString.stringify(
          convertKeys.toSnake(adminUsers.searchParams)
        )}`
      })
      const data = convertKeys.toCamel(response.data)
      if (data.statusCode !== 200) {
        dispatch({ type: ActionTypes.ADMIN_USERS_INDEX_FAILURE })
        return reject(data)
      }
      dispatch({
        type: ActionTypes.ADMIN_USERS_INDEX_SUCCESS,
        payload: {
          totalCount: data.totalCount,
          items: data.data
        }
      })
      resolve(data)
    })
  }
}

export function register(sendData) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'post',
        url: `/admin`,
        data: convertKeys.toSnake(sendData)
      })
      if (data.statusCode === 200) {
        crateToast('success')('ユーザーを登録しました')
        dispatch(updateSearchCondition())
        return resolve({ ok: true })
      }
      crateToast('error')('ユーザーの登録に失敗しました')
      reject()
    })
  }
}

export function onChangePageSize(pageSize) {
  return function(dispatch, getState) {
    dispatch({
      type: ActionTypes.ADMIN_USERS_UPDATE_SEARCH,
      payload: {
        take: pageSize,
        skip: 0
      }
    })
    dispatch(updateSearchCondition())
  }
}

export function onPageChange(currentPage) {
  return function(dispatch, getState) {
    const {
      pages: {
        adminUsers: {
          searchParams: { take }
        }
      }
    } = getState()
    dispatch({
      type: ActionTypes.ADMIN_USERS_UPDATE_SEARCH,
      payload: {
        skip: currentPage * take
      }
    })
    dispatch(updateSearchCondition())
  }
}

function deleteRecord(id) {
  return async function(dispatch, getState) {
    const response = await apiClient({
      method: 'delete',
      url: `/admin/${id}`
    })
    const data = convertKeys.toCamel(response.data)
    if (data.statusCode === 200) {
      crateToast('success')('ユーザーを削除しました')
      return dispatch(loadIndexData())
    }
    crateToast('error')('ユーザーの削除に失敗しました')
  }
}

export function onDelete(digitizerCode) {
  return function(dispatch, getState) {
    dispatch({
      type: ActionTypes.ADMIN_USERS_DELETE,
      payload: digitizerCode,
      meta: {
        confirm: '本当に削除しますか？',
        callbackAction: deleteRecord
      }
    })
  }
}
