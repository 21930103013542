import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/shoesProductDetail'
import * as appActions from '../actions/app'
import AppModal from './AppModal'
import ThreeView from './ThreeView'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Button,
  Modal
} from '@material-ui/core'
import styles from '../styles/FormShoesMeasurement.style'
import MeasValue from '../components/MeasValue'

type Props = {
  classes: Object,
  actions: Object,
  measurements: Array<Array>,
  location: Object,
  common: Object
}

class FormMeasurementList extends React.Component<Props> {
  state = {
    activemeasurementId: null,
    threeUrl: null,
    offsetErr: [],
    json: null,
    measurement: null
  }

  inputChange = (index, param) => e => {
    const regex = new RegExp(/^[+-]?\d+$/)
    let error = this.state.offsetErr.slice()
    if (typeof error[index] === 'undefined') error[index] = {}
    error[index][param] = false
    if (!e.target.value.match(regex)) {
      error[index][param] = true
    }
    this.setState({ offsetErr: error })
    this.setState({
      setButtonDisabled:
        error.filter(v => v.ffWidthOffset || v.ffSizeOffset).length > 0
          ? true
          : false
    })
    this.props.actions.inputFormMeasurement(index, param, e.target.value)
  }

  handleDelete = measurementId => () => {
    this.props.actions.deleteSize(measurementId)
  }

  handleThreeUpload = measurementId => e => {
    const file = e.target.files[0]
    this.props.actions.uploadThreeData(file, measurementId)
  }

  handleModal = (filename, measurementId) => async () => {
    const threeUrl = await this.props.actions.getThreeUrl(
      filename,
      measurementId
    )
    this.setState({ threeUrl })
    this.props.appActions.modalOpen()
  }

  handleUploadJson = (jsonFile, measurementId) => {
    this.props.actions.uploadJson(jsonFile, measurementId)
  }

  handleEditJson = measurement => async () => {
    const res = await this.props.actions.fetchmeasurement(measurement)
    if (!res) return
    this.setState({ json: res.json, measurement })
  }

  handleCloseJsonModal = () => {
    this.setState({ json: null, measurement: null })
  }

  handlePutJson = sendData => {
    return new Promise(async resolve => {
      await this.props.actions.updatemeasurement(
        sendData,
        this.state.measurement
      )
      this.handleCloseJsonModal()
    })
  }

  renderEditCell(measurement) {
    const myId = 'upload3d-' + measurement.id

    return (
      <div>
        <Button
          className="bold"
          size="small"
          color="primary"
          variant="contained"
          style={{ marginRight: '10px' }}
          onClick={this.handleEditJson(measurement)}
        >
          計測・補正
        </Button>
        <Button
          className="bold"
          size="small"
          color="primary"
          variant="contained"
          component="label"
          htmlFor={myId}
          style={{ marginRight: '10px' }}
        >
          3Dデータ登録
        </Button>
        <input
          id={myId}
          type="file"
          accept=".pcd"
          style={{ display: 'none' }}
          onChange={this.handleThreeUpload(measurement.id)}
        />
        <Button
          className="bold"
          size="small"
          color="secondary"
          variant="contained"
          onClick={this.handleDelete(measurement.id)}
        >
          削除
        </Button>
      </div>
    )
  }

  renderThreeButton(measurement) {
    return (
      <div>
        {measurement.fileName ? (
          <Button
            className="bold"
            size="small"
            color="primary"
            variant="contained"
            onClick={this.handleModal(measurement.fileName, measurement.id)}
          >
            3Dデータを確認
          </Button>
        ) : (
          '3Dデータ未登録'
        )}
      </div>
    )
  }

  render() {
    const { classes, measurements, common } = this.props
    if (measurements.length <= 0) return <div />

    return (
      <div>
        <AppModal>
          <ThreeView threeUrl={this.state.threeUrl} />
        </AppModal>
        <Modal open={!!this.state.json}>
          <MeasValue
            measurement={this.state.measurement}
            json={this.state.json}
            onClose={this.handleCloseJsonModal}
            onPut={this.handlePutJson}
            onUploadJson={this.handleUploadJson}
          />
        </Modal>
        <h2>計測リスト</h2>
        <Paper className={classes.row}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.center}>ID</TableCell>
                <TableCell className={classes.center}>メーカーサイズ</TableCell>
                <TableCell className={classes.center}>メーカーワイズ</TableCell>
                <TableCell className={classes.center}>3Dデータの確認</TableCell>
                <TableCell className={classes.center}>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measurements.map((measurement, idx) => (
                <TableRow key={measurement.id}>
                  <TableCell className={classes.center}>
                    {measurement.id}
                  </TableCell>
                  <TableCell className={classes.center}>
                    {measurement.makerSize}
                    {common.unit}
                  </TableCell>
                  <TableCell className={classes.center}>
                    {measurement.makerWidth}
                  </TableCell>
                  <TableCell className={classes.center}>
                    {this.renderThreeButton(measurement)}
                  </TableCell>
                  <TableCell className={classes.center}>
                    {this.renderEditCell(measurement)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  common: state.pages.shoesProductDetail.formShoesCommon,
  measurements: state.pages.shoesProductDetail.formmeasurementList.data,
  loading: state.pages.shoesProductDetail.formmeasurementList.loading,
  location: state.router.location
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FormMeasurementList)
