import produce from 'immer'
import * as ActionTypes from '../../constants'

const initialState = {
  error: '',
  totalCount: 0,
  searchParams: {
    take: 10,
    skip: 0
  },
  columns: [
    {
      name: 'id',
      title: 'ID',
      sortingEnabled: false,
      columnName: 'id'
    },
    {
      name: 'uuid',
      title: 'uuid',
      sortingEnabled: false,
      columnName: 'uuid'
    },
    {
      name: 'email',
      title: 'メールアドレス',
      sortingEnabled: false,
      columnName: 'email'
    },
    {
      name: 'userStatus',
      title: 'UserStatus',
      sortingEnabled: false,
      columnName: 'userStatus'
    },
    {
      name: 'isSuperuser',
      title: '管理者',
      sortingEnabled: false,
      columnName: 'isSuperuser'
    },
    {
      name: 'companyName',
      title: '企業名',
      sortingEnabled: false,
      columnName: 'companyName'
    },
    {
      name: 'storeName',
      title: '店舗名',
      sortingEnabled: false,
      columnName: 'storeName'
    },
    {
      name: 'createdTime',
      title: '登録日',
      sortingEnabled: false,
      columnName: 'createdTime'
    },
    {
      name: 'updatedTime',
      title: '更新日',
      sortingEnabled: false,
      columnName: 'updatedTime'
    },
    {
      name: 'tool',
      title: '操作',
      sortingEnabled: false,
      columnName: 'tool',
      width: 300
    }
  ],
  loading: false,
  indexList: [],
  detail: {},
  clients: []
}

export default (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.ADMIN_CLIENTS_INDEX_SUCCESS: {
        const { items, totalCount } = action.payload
        draft.indexList = items
        draft.totalCount = totalCount
        break
      }
      case ActionTypes.ADMIN_CLIENTS_UPDATE_SEARCH: {
        Object.assign(draft.searchParams, action.payload)
        break
      }
      case ActionTypes.ADMIN_CLIENTS_CLIENTS_FETCH: {
        draft.clients = action.payload
        break
      }
      default:
    }
  })
}
