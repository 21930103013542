import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { Dialog, Button, withStyles } from '@material-ui/core'
import * as actions from '../actions/app'

const styles = theme => {
  return {
    root: {
      width: '600px',
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    title: {
      color: '#CC0000',
      fontWeight: 'bold'
    },
    ul: {
      margin: 0,
      padding: 0
    },
    button: {
      paddingBottom: '20px',
      textAlign: 'right'
    }
  }
}

class ErrorDialog extends React.Component {
  componentDidMount() {}

  handleClose = () => {
    this.props.actions.closeErrorDialog()
  }

  render() {
    const { classes } = this.props
    const { open, data } = this.props.errorDetail
    const message = data.message
    if (!message) return <div />
    const messageTitles = Object.keys(message)

    return (
      <Dialog onClose={this.handleClose} open={open}>
        <div className={classes.root}>
          <p className={classes.title}>{data.title}</p>
          <div>
            {messageTitles.map((title, idx) => (
              <dl key={`error_title_${idx}`}>
                <dt>{title}</dt>
                <dd>
                  <ul className={classes.ul}>
                    {message[title].map((text, idx) => (
                      <li key={`error_text_${idx}`}>{text}</li>
                    ))}
                  </ul>
                </dd>
              </dl>
            ))}
          </div>
          <div className={classes.button}>
            <Button
              className="bold"
              variant="contained"
              color="primary"
              onClick={this.handleClose}
            >
              閉じる
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  errorDetail: state.common.app.errorDetail
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ErrorDialog)
