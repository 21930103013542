/* @flow */
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { routerActions } from 'react-router-redux'
import { compose } from 'recompose'
import styled from 'styled-components'

import { IconButton, Menu, MenuItem, withStyles, Icon } from '@material-ui/core'

import crateToast from '../utils/createToast'
import { logout } from '../actions/login'
import { toggleMenu } from '../actions/app'
import Logo from '../logo.svg'
import styles from '../styles/GlobalHeader.style'

type Props = {
  actions: Object,
  routerActions: Object,
  history: Object,
  classes: Object,
  token: string
}

type State = {
  anchorEl: ?React.Element<any>
}

class GlobalHeader extends React.Component<Props, State> {
  state = {
    anchorEl: null
  }

  handleOpen = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMyPage = () => {
    const { routerActions } = this.props
    this.handleClose()
    routerActions.push('/mypage')
  }

  handleLogout = e => {
    const { actions } = this.props
    this.handleClose()
    actions.logout()
    crateToast('success')('ログアウトしました')
  }

  handleToggleMenu = () => {
    this.props.actions.toggleMenu()
  }

  render() {
    const { anchorEl } = this.state
    const { classes, token } = this.props

    return (
      <header className={classes.header}>
        <div className={classes.inner}>
          <IconButton
            style={{ marginLeft: '-5px' }}
            onClick={this.handleToggleMenu}
          >
            <MenuButton>menu</MenuButton>
          </IconButton>
          <div className={classes.rightMenu}>
            <Link to="/" className={classes.title}>
              <img src={Logo} alt="Flick Fit" height="18" />
            </Link>
            {token && (
              <nav className={classes.nav}>
                <IconButton
                  aria-owns={anchorEl ? 'simple-menu' : null}
                  aria-haspopup="true"
                  className={classes.accountButton}
                  onClick={this.handleOpen}
                >
                  <Icon style={{ color: '#fff' }}>keyboard_arrow_down</Icon>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    className={classes.menu}
                    onClick={this.handleMyPage}
                  >
                    マイページ
                  </MenuItem>
                  <MenuItem
                    className={classes.menu}
                    onClick={this.handleLogout}
                  >
                    ログアウト
                  </MenuItem>
                </Menu>
              </nav>
            )}
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = state => ({
  token: state.common.auth.token,
  location: state.router.location
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logout, toggleMenu }, dispatch),
  routerActions: bindActionCreators(routerActions, dispatch)
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(GlobalHeader)

const MenuButton = styled(Icon)`
  color: #fff;
`
