/* @flow */
import merge from 'lodash/merge'

import * as ActionTypes from '../constants'
import * as validator from '../utils/validator'
import crateToast from '../utils/createToast'
import http from '../api/v1'
import formatValidationError from '../utils/formatValidationError'

// バリデーション実行
export function validate(key: string, error: Object) {
  return {
    type: ActionTypes.MYPAGE_VALIDATE_EXEC,
    payload: {
      key,
      error: error[key]
    }
  }
}

// 入力
export function input(inputKey: string, inputValue: string) {
  return function(dispatch: Function, getState: Function) {
    const error = merge({}, validator.blank(inputKey, inputValue))
    dispatch(validate(inputKey, error))
    dispatch({
      type: ActionTypes.MYPAGE_INPUT,
      payload: { inputKey, inputValue }
    })
  }
}

// 送信
export function submit() {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.MYPAGE_INPUT_REQUEST })
    const formData = getState().pages.mypage.input
    http.changePassword(formData).then(res => {
      if (res.data.statusCode === 200) {
        dispatch({ type: ActionTypes.MYPAGE_INPUT_SUCCESS })
        crateToast('success')('パスワードを更新しました')
      } else {
        // バリデーションエラー
        dispatch({
          type: ActionTypes.MYPAGE_INPUT_ERROR,
          payload: formatValidationError(res.data)
        })
      }
    })
  }
}
