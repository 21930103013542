/* @flow */
import React from 'react'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import {
  withStyles,
  Paper,
  GridList,
  GridListTile,
  Button
} from '@material-ui/core'

import withPage from '../hocs/withPage'
import createPageTitle from '../utils/createPageTitle'
import styles from '../styles/ShoesItemDetailPage.style'
import * as actions from '../actions/shoesItemDetail'
import * as appActions from '../actions/app'
import AppModal from './AppModal'
import ThreeView from './ThreeView'
import { API_BASE_URL } from '../constants'

type Props = {
  match: Object,
  classes: Object,
  actions: Object,
  appActions: Object,
  loading: boolean,
  data: Object,
  masterData: Object
}

type State = {
  threeUrl: ?string
}

class ShoesItemDetailPage extends React.Component<Props, State> {
  state = { threeUrl: null }

  createPageTitle = () => {
    const { match } = this.props
    return createPageTitle(`${match.params.productId} | 靴管理(SKU単位)`)
  }

  handleThreeData = threeImage => () => {
    const { data } = this.props
    if (!data) return
    const url = `${String(API_BASE_URL)}/shoe/measurement/${
      data.measurement.id
    }/${threeImage.fileName}`
    this.setState({
      threeUrl: url
    })
    this.props.appActions.modalOpen()
  }

  componentDidMount() {
    const { actions, match } = this.props
    actions.fetch(match.params.productId)
  }

  render3dButton(threeImage) {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          className="bold"
          style={{ marginRight: '15px' }}
          onClick={this.handleThreeData(threeImage)}
        >
          3Dデータを確認する
        </Button>
      </div>
    )
  }

  render() {
    const { classes, match, data, loading, masterData } = this.props

    const { product, mesh, measurement } = data
    if (loading || !product || masterData.brand.length <= 0) return <div />

    const threeImage = this.props.data.measurement.threeImage

    return (
      <div className={classes.wrapper}>
        <AppModal>
          <ThreeView threeUrl={this.state.threeUrl} />
        </AppModal>
        <Helmet>
          <title>{this.createPageTitle()}</title>
        </Helmet>
        <div className={classes.header}>
          <h2>ITEM ID: {match.params.productId}</h2>
          {threeImage.length > 0 && this.render3dButton(threeImage[0])}
        </div>
        <div className={classes.row}>
          <Paper className={classes.dlWrap}>
            <dl>
              <dt>品番</dt>
              <dd>
                <Link to={`/shoes/product/${product.id}`}>
                  {product.productNumber}
                </Link>
              </dd>
            </dl>
            <dl>
              <dt>ブランド</dt>
              <dd>{product.brandName}</dd>
            </dl>
            <dl>
              <dt>カテゴリ</dt>
              <dd>{product.categoryName}</dd>
            </dl>
            <dl>
              <dt>商品名</dt>
              <dd>{product.productName}</dd>
            </dl>
            <dl>
              <dt>提供</dt>
              <dd>{product.providerName}</dd>
            </dl>
            <dl>
              <dt>つま先形状</dt>
              <dd>{product.toeName}</dd>
            </dl>
            <dl>
              <dt>ヒール形状</dt>
              <dd>{product.heelTypeName}</dd>
            </dl>
            <dl>
              <dt>ヒール高</dt>
              <dd>{product.heelHeight}</dd>
            </dl>
            <dl>
              <dt>ストラップ</dt>
              <dd>{product.strap}</dd>
            </dl>
            <dl>
              <dt>ターゲット</dt>
              <dd>{product.targetName}</dd>
            </dl>
            <dl>
              <dt>メーカー色</dt>
              <dd>{mesh.makerColor}</dd>
            </dl>
            <dl>
              <dt>メーカー素材</dt>
              <dd>{mesh.makerMaterial}</dd>
            </dl>
            <dl>
              <dt>FF色</dt>
              <dd>{mesh.ffColor}</dd>
            </dl>
            <dl>
              <dt>FF素材</dt>
              <dd>{mesh.ffMaterial}</dd>
            </dl>
            <dl>
              <dt>メーカーサイズ</dt>
              <dd>{measurement.makerSize}</dd>
            </dl>
            <dl>
              <dt>メーカーワイズ</dt>
              <dd>{measurement.makerWidth}</dd>
            </dl>
            <dl>
              <dt>FFサイズ</dt>
              <dd>{measurement.ffSize}</dd>
            </dl>
            <dl>
              <dt>FFワイズ</dt>
              <dd>{measurement.ffWidth}</dd>
            </dl>
            <dl>
              <dt>作業</dt>
              <dd>{data.workProcessName}</dd>
            </dl>
            <dl>
              <dt>レコメンド</dt>
              <dd>{data.publicFlg}</dd>
            </dl>
            <dl>
              <dt>JANコード</dt>
              <dd>{data.jancode}</dd>
            </dl>
            <dl>
              <dt>コメント</dt>
              <dd>{product.comment}</dd>
            </dl>
            <dl>
              <dt>フィードバック</dt>
              <dd>{data.feedback}</dd>
            </dl>
            <dl>
              <dt>登録</dt>
              <dd>{data.createdTime}</dd>
            </dl>
            <dl>
              <dt>更新</dt>
              <dd>{data.updatedTime}</dd>
            </dl>
          </Paper>
        </div>

        <div className={classes.row}>
          <h2>画像</h2>
          <Paper className={classes.paper}>
            <GridList cellHeight={160} cols={5}>
              {mesh.pictures.map((picture, idx) => {
                return (
                  <GridListTile key={idx}>
                    <img
                      src={`${String(API_BASE_URL)}/shoe/picture/${
                        picture.mesh
                      }/${picture.fileName}?height=160`}
                      alt={idx}
                    />
                  </GridListTile>
                )
              })}
            </GridList>
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  data: state.pages.shoesItemDetail.data,
  loading: state.pages.shoesItemDetail.loading,
  masterData: state.masterData
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default compose(
  withPage(),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShoesItemDetailPage)
