import { combineReducers } from 'redux'

import formShoesCommon from './formShoesCommon'
import formShoesDetail from './formShoesDetail'
import formShoesVariation from './formShoesVariation'
import formmeasurementList from './formMeasurementList'

export default combineReducers({
  formShoesCommon,
  formShoesDetail,
  formShoesVariation,
  formmeasurementList
})
