/* @flow */
import produce from 'immer'
import * as ActionTypes from '../../constants'

type State = {
  loading: boolean,
  input: {
    productNumber: string,
    productName: string,
    brandId: number,
    categoryId: number,
    toeId: number,
    heelTypeId: number,
    strap: boolean,
    providerId: number,
    unit: string,
    targetId: number,
    heelHeight: string,
    comment: string,
    shoeTypeId: string,
    shoelace: boolean,
    features: []
  },
  errors: {
    productNumber: string,
    productName: string,
    brandId: string,
    categoryId: string,
    toeId: string,
    heelTypeId: string,
    strap: string,
    providerId: string,
    unit: string,
    targetId: string,
    heelHeight: string,
    comment: string,
    shoeTypeId: string,
    shoelace: boolean,
    features: string
  }
}

const initialState: State = {
  loading: false,
  input: {
    productNumber: '',
    productName: '',
    brandId: 0,
    categoryId: 0,
    toeId: 0,
    heelTypeId: 0,
    strap: true,
    providerId: 0,
    unit: 'cm',
    targetId: 0,
    heelHeight: '',
    comment: '',
    shoeTypeId: '',
    shoelace: false,
    features: []
  },
  errors: {
    productNumber: '',
    productName: '',
    brandId: '',
    categoryId: '',
    toeId: '',
    heelTypeId: '',
    strap: '',
    providerId: '',
    unit: '',
    targetId: '',
    heelHeight: '',
    comment: '',
    shoeTypeId: '',
    shoelace: false,
    features: ''
  }
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      // 詳細情報の入力
      case ActionTypes.FORM_SHOES_DETAIL_INPUT:
        const { key, value } = action.payload
        draft.input[key] = value
        break
      // フォームの初期化
      case ActionTypes.FORM_SHOES_ALL_CLEAR:
        draft.input = initialState.input
        draft.errors = initialState.errors
        draft.loading = false
        break
      // 編集データ
      case ActionTypes.FORM_SHOES_FETCH_DETAIL_SUCCESS:
        draft.input = action.payload
        break
      // 初期データ投入
      case ActionTypes.FORM_SHOES_SET_INITIAL_DATA: {
        draft.input = Object.assign({}, initialState.input, action.payload)
        break
      }
      // 送信時のバリデーションエラー
      case ActionTypes.FORM_SHOES_SUBMIT_DETAIL_ERROR:
        draft.errors = action.payload.message
        break
      case ActionTypes.FORM_SHOES_ADD_FEATURE: {
        draft.input.features.push(action.payload)
        break
      }
      case ActionTypes.FORM_SHOES_REMOVE_FEATURE: {
        const updateFeatureList = state.input.features.filter(
          f => f.featureListId != action.payload
        )
        draft.input.features = updateFeatureList
        break
      }
      default:
    }
  })
}

export function selectedFeatureMaster(featureMaster, featureList) {
  const featureListIds = featureList.map(f => f.featureListId * 1)
  return featureMaster.filter(f => {
    return !featureListIds.includes(f.featureListId)
  })
}

export function featureName(featureMaster, featureListId) {
  const target = featureMaster.find(f => f.featureListId == featureListId)
  return target ? target.featureListName : undefined
}
