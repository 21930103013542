/* @flow */
import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  withStyles,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  Button,
  Paper,
  NativeSelect,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import AutoSelect from '../components/AutoSelect'
import styles from '../styles/FormShoesDetail.style'
import * as actions from '../actions/shoesProductDetail'
import PageTitle from '../components/PageTitle'
import {
  selectedFeatureMaster,
  featureName
} from '../reducers/pages/formShoesDetail'

const spacing = 24

type Props = {
  masterData: Object,
  classes: Object,
  actions: Object,
  input: Object,
  errors: Object,
  location: Object,
  common: Object,
  featureListMaster: [Object]
}

type State = {
  selectedFeatureId: ?string,
  selectedValue: ?string
}

const initialState = {
  selectedFeatureId: '',
  selectedValue: ''
}

class FormShoesDetail extends React.Component<Props, State> {
  state = initialState

  inputChange = param => e => {
    this.props.actions.inputFormDetail(param, e.target.value)
  }

  selectChange = param => value => {
    this.props.actions.inputFormDetail(param, value)
  }

  handleAddFeature = () => {
    this.props.actions.addFeature({
      featureListId: this.state.selectedFeatureId,
      value: this.state.selectedValue
    })
    this.setState({ ...initialState })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.props.common.productId) {
      // 編集
      this.props.actions.putFormDetail()
    } else {
      // 新規
      this.props.actions.postFormDetail()
    }
  }

  handleDelete = () => {
    this.props.actions.deleteFormDetail()
  }

  getSelectMasterId(inputId, targetMasterData) {
    return inputId || targetMasterData[0].id
  }

  componentDidMount() {
    const { location, actions } = this.props
    actions.formReset()
    if (location.pathname.indexOf('new') < 0) {
      // 編集時
      const pathArr = location.pathname.split('/')
      const id = pathArr[pathArr.length - 1]
      actions.fetchDetail(id)
    } else {
      // 新規
      actions.setInitialData()
    }
  }

  render() {
    const { masterData, classes, input, errors, common } = this.props

    if (!input) {
      return <React.Fragment />
    }

    return (
      <div>
        <PageTitle
          title={`靴登録(${
            !common.productId ? '新規作成' : '編集 ID:' + common.productId
          })`}
        />
        <Paper className={classes.row}>
          <form onSubmit={this.handleSubmit}>
            <FormControl margin="normal" fullWidth>
              <Grid container spacing={spacing}>
                <Grid item xs={4}>
                  <TextField
                    required
                    autoComplete="off"
                    id="productNumber"
                    label="品番"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="V55-K"
                    value={input.productNumber}
                    fullWidth
                    onChange={this.inputChange('productNumber')}
                    error={!!errors.productNumber}
                    helperText={errors.productNumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="productName"
                    autoComplete="off"
                    label="商品名"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={input.productName}
                    fullWidth
                    onChange={this.inputChange('productName')}
                    error={!!errors.productName}
                    helperText={errors.productName}
                    required
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Grid container spacing={spacing}>
                <Grid item xs={3}>
                  <AutoSelect
                    required
                    label="ブランド"
                    multiple={false}
                    id="brandId"
                    suggestions={masterData.brand}
                    value={input.brandId}
                    onChange={value => {
                      this.selectChange('brandId')(value)
                    }}
                    error={!!errors.brandId}
                    helperText={errors.brandId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    required
                    label="カテゴリー"
                    multiple={false}
                    id="categoryId"
                    suggestions={masterData.category}
                    value={input.categoryId}
                    onChange={value => {
                      this.selectChange('categoryId')(value)
                    }}
                    error={!!errors.categoryId}
                    helperText={errors.categoryId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    label="靴タイプ"
                    multiple={false}
                    id="providerId"
                    suggestions={masterData.shoeType.filter(shoeType => {
                      const myCategory = masterData.category.find(
                        m => m.id === input.categoryId
                      )
                      if (myCategory) {
                        return myCategory.name === shoeType.parent
                      }
                      return false
                    })}
                    value={input.shoeTypeId}
                    onChange={value => {
                      this.selectChange('shoeTypeId')(value)
                    }}
                    required
                    error={!!errors.shoeTypeId}
                    helperText={errors.shoeTypeId}
                    disabled={!!!input.categoryId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    required
                    label="つま先"
                    multiple={false}
                    id="toeId"
                    suggestions={masterData.toe}
                    value={input.toeId}
                    onChange={value => {
                      this.selectChange('toeId')(value)
                    }}
                    error={!!errors.toeId}
                    helperText={errors.toeId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    label="ヒールタイプ"
                    multiple={false}
                    id="heelTypeId"
                    suggestions={masterData.heelType}
                    value={input.heelTypeId}
                    onChange={value => {
                      this.selectChange('heelTypeId')(value)
                    }}
                    error={!!errors.heelTypeId}
                    helperText={errors.heelTypeId}
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Grid container spacing={spacing}>
                <Grid item xs={3}>
                  <RadioGroup
                    row
                    required
                    value={String(input.strap)}
                    onChange={e => {
                      this.selectChange('strap')(e.target.value + '')
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label="ストラップあり"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="ストラップなし"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    label="プロバイダー"
                    multiple={false}
                    id="providerId"
                    suggestions={masterData.provider}
                    value={input.providerId}
                    onChange={value => {
                      this.selectChange('providerId')(value)
                    }}
                    error={!!errors.providerId}
                    helperText={errors.providerId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    autoComplete="off"
                    id="unit"
                    label="サイズ単位"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={input.unit}
                    fullWidth
                    onChange={this.inputChange('unit')}
                    error={!!errors.unit}
                    helperText={errors.unit}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoSelect
                    label="ターゲット"
                    multiple={false}
                    id="targetId"
                    suggestions={masterData.target}
                    value={input.targetId}
                    onChange={value => {
                      this.selectChange('targetId')(value)
                    }}
                    error={!!errors.targetId}
                    helperText={errors.targetId}
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Grid container spacing={spacing}>
                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    id="heelHeight"
                    label="ヒール高(mm単位)"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="number"
                    value={input.heelHeight}
                    fullWidth
                    onChange={this.inputChange('heelHeight')}
                    error={!!errors.heelHeight}
                    helperText={errors.heelHeight}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true}>商品コメント</InputLabel>
                    <Input
                      id="comment"
                      value={input.comment}
                      className={classes.textarea}
                      onChange={this.inputChange('comment')}
                      multiline={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Grid container spacing={spacing}>
                <Grid item xs={3}>
                  <RadioGroup
                    row
                    required
                    value={String(input.shoelace)}
                    onChange={e => {
                      this.selectChange('shoelace')(
                        e.target.value === 'true' ? true : false
                      )
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label="紐あり"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="紐なし"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </FormControl>

            {input.features.length > 0 && (
              <FormControl margin="normal" fullWidth>
                <FormLabel component="legend">属性</FormLabel>
                <Grid container spacing={spacing} style={{ marginTop: '10px' }}>
                  {input.features.map(f => {
                    const name = featureName(
                      this.props.featureListMasterAll,
                      f.featureListId
                    )
                    return (
                      <Grid item xs={3} key={f.featureListId}>
                        <TextField
                          autoComplete="off"
                          label={name}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={f.value}
                          fullWidth
                          onChange={this.inputChange('heelHeight')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.props.actions.deleteFeature(
                                      f.featureListId
                                    )
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </FormControl>
            )}

            {this.props.featureListMaster.length > 0 && (
              <Grid container spacing={spacing} style={{ marginTop: '10px' }}>
                <Grid item xs={5}>
                  <FormLabel component="legend">属性追加</FormLabel>
                  <Grid container spacing={spacing}>
                    <Grid item>
                      <NativeSelect
                        value={this.state.selectedFeatureId}
                        onChange={e => {
                          this.setState({ selectedFeatureId: e.target.value })
                        }}
                      >
                        <option value="" />
                        {this.props.featureListMaster.map(f => (
                          <option key={f.featureListId} value={f.featureListId}>
                            {f.featureListName}
                          </option>
                        ))}
                      </NativeSelect>
                    </Grid>
                    <Grid item>
                      <TextField
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        type="text"
                        placeholder="詳細を入力"
                        value={this.state.selectedValue}
                        onChange={e =>
                          this.setState({ selectedValue: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        disabled={
                          !this.state.selectedValue ||
                          !this.state.selectedFeatureId
                        }
                        variant="contained"
                        onClick={this.handleAddFeature}
                      >
                        追加
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={`bold ${classes.button}`}
              >
                {common.productId ? '編集する' : '登録する'}
              </Button>
              {(() => {
                if (common.productId) {
                  return (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={`bold ${classes.button}`}
                      onClick={this.handleDelete}
                    >
                      削除する
                    </Button>
                  )
                }
              })()}
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterData: state.masterData,
  featureListMasterAll: state.masterData.featureList.map(f => {
    return {
      featureListId: f.id,
      featureListName: f.name
    }
  }),
  featureListMaster: selectedFeatureMaster(
    state.masterData.featureList.map(f => {
      return {
        featureListId: f.id,
        featureListName: f.name
      }
    }),
    state.pages.shoesProductDetail.formShoesDetail.input.features
  ),
  common: state.pages.shoesProductDetail.formShoesCommon,
  input: state.pages.shoesProductDetail.formShoesDetail.input,
  errors: state.pages.shoesProductDetail.formShoesDetail.errors,
  location: state.router.location
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FormShoesDetail)
