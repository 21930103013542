/* @flow */
/* ログインページ */
import produce from 'immer'
import values from 'lodash/values'
import compact from 'lodash/compact'

import * as ActionTypes from '../../constants'

type State = {
  input: {
    email: string,
    password: string
  },
  errors: {
    email: string,
    password: string
  },
  loading: boolean,
  isValid: boolean,
  settings: {
    showPassword: boolean
  }
}

const initialState: State = {
  input: {
    email: '',
    password: ''
  },
  errors: {
    email: '',
    password: ''
  },
  loading: false,
  isValid: false,
  settings: {
    showPassword: false
  }
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.LOGIN_INPUT_MAIL:
        draft.input.email = action.payload
        break
      case ActionTypes.LOGIN_INPUT_PASSWORD:
        draft.input.password = action.payload
        break
      case ActionTypes.LOGIN_SHOW_PASSWORD:
        draft.settings.showPassword = !state.settings.showPassword
        break
      case ActionTypes.LOGIN_VALIDATE_EXEC:
        const { key, error } = action.payload
        draft.errors[key] = error
        draft.isValid = compact(values(draft.errors)).length === 0
        break
      case ActionTypes.LOGIN_REQUEST:
        draft.loading = true
        break
      case ActionTypes.LOGIN_SUCCESS:
        draft.loading = false
        break
      case ActionTypes.LOGIN_FAILURE:
        draft.loading = false
        break
      case ActionTypes.LOGOUT:
        draft.input = { email: '', password: '' }
        draft.settings.showPassword = false
        break
      default:
    }
  })
}
