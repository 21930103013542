import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { PagingState, CustomPaging } from '@devexpress/dx-react-grid'
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui'

import styles from '../styles/EditOnModalPage.style'
import withPage from '../hocs/withPage'
import PageTitle from '../components/PageTitle'
import AppModal from './AppModal'
import * as actions from '../actions/app'
import * as pageActions from '../actions/adminUsers'
import moment from 'moment'

const RowBase = (params: Object) => {
  return <Table.Row className={params.classes.row}>{params.children}</Table.Row>
}

const rowStyles = (theme: Object) => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

const StyledRow = withStyles(rowStyles)(RowBase)

const initialState = {
  email: '',
  isSuperuser: false,
  mode: 'add'
}

class AdminUsersPage extends Component {
  state = initialState

  handleAddNew = () => {
    const { actions } = this.props
    this.setState(initialState)
    actions.modalOpen()
  }

  handleChange = type => e => {
    if (type === 'email') {
      this.setState({ email: e.target.value })
    } else {
      const isSuperuser = !this.state.isSuperuser
      this.setState({ isSuperuser })
    }
  }

  handleSubmit = async e => {
    const { pageActions } = this.props
    e.preventDefault()
    await pageActions.register(this.state)
    this.clearForm()
  }

  clearForm = () => {
    const { actions } = this.props
    actions.modalClose()
    this.setState(initialState)
  }

  async componentDidMount() {
    const { pageActions } = this.props
    await pageActions.updateSearchCondition()
  }

  render() {
    const {
      classes,
      adminUsers: { columns, searchParams, totalCount },
      pageActions,
      indexList
    } = this.props

    return (
      <div>
        <PageTitle title="管理システム" />
        <Grid
          container
          spacing={8}
          justify="flex-end"
          className={classes.headButton}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="bold"
              onClick={this.handleAddNew}
            >
              新規追加
            </Button>
          </Grid>
        </Grid>

        <Paper>
          <GridTable columns={columns} rows={indexList}>
            <Table
              columnExtensions={columns}
              rowComponent={StyledRow}
              cellComponent={({ value, column: { name }, row }) => {
                if (name === 'tool') {
                  return (
                    <Table.Cell>
                      <Button
                        className="bold"
                        color="primary"
                        size="small"
                        onClick={() => {
                          this.props.pageActions.onDelete(row.id)
                        }}
                      >
                        削除
                      </Button>
                    </Table.Cell>
                  )
                }
                return <Table.Cell>{value}</Table.Cell>
              }}
            />
            <TableHeaderRow />
            <PagingState
              currentPage={Math.floor(searchParams.skip / searchParams.take)}
              onCurrentPageChange={currentPage => {
                pageActions.onPageChange(currentPage)
              }}
              pageSize={searchParams.take}
              onPageSizeChange={pageSize =>
                pageActions.onChangePageSize(pageSize)
              }
            />
            <CustomPaging totalCount={totalCount} />
            <PagingPanel
              pageSizes={[1, 10, 15]}
              messages={{ rowsPerPage: '1ページあたりの表示数' }}
            />
          </GridTable>
        </Paper>
        <AppModal>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  required
                  type="email"
                  label="メールアドレス"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="superuser"
                      color="primary"
                      checked={this.state.isSuperuser}
                      onChange={this.handleChange('isSuperuser')}
                    />
                  }
                  label="管理者"
                />
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="bold"
                  type="submit"
                >
                  {this.state.mode === 'add' ? '追加する' : '編集する'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AppModal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  adminUsers: state.pages.adminUsers,
  indexList: state.pages.adminUsers.indexList.map(obj => {
    return {
      ...obj,
      createdTime: moment(obj.createdTime).format('YYYY/MM/DD HH:MM'),
      updatedTime: moment(obj.updatedTime).format('YYYY/MM/DD HH:MM'),
      isSuperuser: String(obj.isSuperuser)
    }
  })
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  pageActions: bindActionCreators({ ...pageActions }, dispatch)
})

export default compose(
  withPage({ title: '管理システム' }),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminUsersPage)
