// @flow
import React, { Component } from 'react'
import {
  withStyles,
  Grid,
  Input,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { compose } from 'recompose'
import SubmitButton from './SubmitButton'

const styles = theme => {
  const { unit } = theme.spacing
  return {
    title: {
      textAlign: 'center'
    },
    grid: {
      marginTop: `${unit * 8}px`
    },
    formControl: {
      margin: `${unit}px 0`
    },
    submit: {
      margin: `${unit * 2}px 0`,
      fontWeight: 'bold'
    }
  }
}

class PasswordInitialForm extends Component {
  render() {
    const { classes, onChangeForm, loading, onPasswordInitial } = this.props

    return (
      <form onSubmit={onPasswordInitial}>
        <h2 className={classes.title}>パスワード設定</h2>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel shrink>新規パスワード</InputLabel>
          <Input
            type="password"
            onChange={onChangeForm('password')}
            autoFocus={true}
            required
          />
        </FormControl>
        <Grid container justify="center">
          <Grid item xs={6}>
            <SubmitButton
              className={classes.submit}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              disabled={loading}
            >
              パスワード設定
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default compose(withStyles(styles))(PasswordInitialForm)
