/* @flow */
import * as React from 'react'
import { Link } from 'react-router-dom'

import { withStyles, IconButton, TextField, Switch } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import {
  PagingState,
  SortingState,
  CustomPaging,
  SelectionState,
  IntegratedSelection,
  EditingState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  TableEditColumn,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import ListTableLoading from './ListTableLoading'

const RowBase = (params: Object) => {
  return <Table.Row className={params.classes.row}>{params.children}</Table.Row>
}

const rowStyles = (theme: Object) => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

const StyledRow = withStyles(rowStyles)(RowBase)

type Props = {
  loading: boolean,
  searchSettings: Object,
  searchParams: Object,
  disabledRemoveButton?: boolean,
  customCell?: string,
  children?: React.Node,
  rows: Array<any>,
  changePageSize?: () => void,
  changeCurrentPage?: () => void,
  changeSorting?: () => void,
  changeSelection?: () => void,
  hiddenColumnNamesChange?: () => void,
  deleteRow?: Object => void,
  cellClickHandler?: string => void,
  customCellLinkTo?: string => string,
  feedbackHandler?: Object => void,
  switchHandler?: Object => void
}

const DeleteButton = ({ onExecute }) => (
  <IconButton>
    <DeleteIcon onClick={onExecute} />
  </IconButton>
)

const commandComponents = {
  delete: DeleteButton
}

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id]
  return <CommandButton onExecute={onExecute} />
}

class ListTable extends React.Component<Props> {
  // リンクセル
  CellLink = (props: Object) => {
    const {
      value,
      column: { name }
    } = props
    if (name === 'id') {
      // リンク
      return (
        <Table.Cell>
          <Link to={this.props.customCellLinkTo(value)}>{value}</Link>
        </Table.Cell>
      )
    } else if (name === 'feedback') {
      return (
        <Table.Cell>
          <TextField
            fullWidth
            value={value || ''}
            onChange={e => {
              this.props.feedbackHandler({
                id: props.row.id,
                feedback: e.target.value
              })
            }}
          />
        </Table.Cell>
      )
    } else if (name === 'productlistId') {
      return (
        <Table.Cell>
          <Switch
            disabled={!this.props.searchParams.clientId}
            checked={value !== null}
            value={String(value) || ''}
            onChange={e => {
              this.props.switchHandler({
                itemId: props.row.id,
                recommend: value !== null
              })
            }}
          />
        </Table.Cell>
      )
    } else {
      return <Table.Cell>{value}</Table.Cell>
    }
  }

  // モーダルを開くセル
  CellModal = (props: Object) => {
    const {
      value,
      column: { name }
    } = props
    if (name === 'id') {
      // リンク
      return (
        <Table.Cell>
          <a
            href="."
            onClick={e => {
              e.preventDefault()
              this.props.cellClickHandler(value)
            }}
          >
            {value}
          </a>
        </Table.Cell>
      )
    } else {
      return <Table.Cell>{value}</Table.Cell>
    }
  }

  customCell() {
    const { customCell } = this.props
    if (!customCell) return null
    switch (customCell) {
      case 'modal':
        return this.CellModal
      case 'feedback':
        return this.Feedback
      default:
        return this.CellLink
    }
  }

  render() {
    const props = this.props
    const {
      loading,
      searchSettings,
      changePageSize,
      searchParams,
      changeCurrentPage,
      changeSorting,
      changeSelection,
      hiddenColumnNamesChange,
      deleteRow,
      rows
    } = this.props

    return (
      <div style={{ position: 'relative' }}>
        <Grid rows={rows} columns={searchSettings.columns}>
          <SortingState
            columnExtensions={searchSettings.columns}
            sorting={searchParams.sorting}
            onSortingChange={changeSorting}
          />
          {changeSelection && (
            <SelectionState
              selection={searchSettings.selections}
              onSelectionChange={changeSelection}
            />
          )}
          <PagingState
            currentPage={Math.floor(searchParams.skip / searchParams.take)}
            onCurrentPageChange={changeCurrentPage}
            pageSize={searchParams.take}
            onPageSizeChange={changePageSize}
          />
          {changeSelection && <IntegratedSelection />}
          <CustomPaging totalCount={searchSettings.totalCount} />
          {props.customCell ? (
            <Table
              messages={{ noData: 'データがありません' }}
              cellComponent={this.customCell()}
              rowComponent={StyledRow}
            />
          ) : (
            <Table messages={{ noData: 'データがありません' }} />
          )}
          {/* 削除ボタン表示 */}
          <EditingState
            onCommitChanges={({ deleted }) =>
              deleteRow(searchSettings.rows[deleted])
            }
          />
          {deleteRow && (
            <div>
              <TableEditColumn
                width={100}
                showDeleteCommand
                commandComponent={Command}
              />
            </div>
          )}
          {/* 選択 */}
          {changeSelection && <TableSelection showSelectAll />}
          <TableHeaderRow
            showSortingControls
            messages={{ sortingHint: 'ソート' }}
          />
          <TableColumnVisibility
            defaultHiddenColumnNames={searchSettings.defaultHiddenColumnNames}
            onHiddenColumnNamesChange={hiddenColumnNamesChange}
          />
          <PagingPanel
            pageSizes={searchSettings.pageSizes}
            messages={{ rowsPerPage: '1ページあたりの表示数' }}
          />
          <Toolbar />
          {props.children}
          <ColumnChooser messages={{ showColumnChooser: '表示項目の選択' }} />
        </Grid>
        {loading && <ListTableLoading />}
      </div>
    )
  }
}

export default ListTable
