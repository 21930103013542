/* @flow */
/* マイページ */
import produce from 'immer'
import values from 'lodash/values'
import compact from 'lodash/compact'

import * as ActionTypes from '../../constants'

type State = {
  input: {
    previousPassword: string,
    proposedPassword: string
  },
  errors: {
    previousPassword: string,
    proposedPassword: string
  },
  loading: boolean,
  isValid: boolean
}

const initialState: State = {
  input: {
    previousPassword: '',
    proposedPassword: ''
  },
  errors: {
    previousPassword: '',
    proposedPassword: ''
  },
  loading: false,
  isValid: false
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.MYPAGE_VALIDATE_EXEC:
        const { key, error } = action.payload
        draft.errors[key] = error
        draft.isValid = compact(values(draft.errors)).length === 0
        break
      case ActionTypes.MYPAGE_INPUT:
        const { inputKey, inputValue } = action.payload
        draft.input[inputKey] = inputValue
        break
      case ActionTypes.MYPAGE_INPUT_REQUEST:
        draft.loading = true
        break
      case ActionTypes.MYPAGE_INPUT_SUCCESS:
        draft.input = initialState.input
        draft.loading = false
        break
      case ActionTypes.MYPAGE_INPUT_ERROR:
        draft.loading = false
        draft.errors = action.payload.message
        break
      case ActionTypes.MYPAGE_INPUT_FAILURE:
        draft.loading = false
        break
      default:
    }
  })
}
