/* @flow */
import crateToast from '../utils/createToast'

const defaultFailSuffix = 'FAILURE'

export default (props: { dispatch: Function }) => (next: any) => (
  action: Function
) => {
  if (action.type) {
    const isFail = new RegExp(`${defaultFailSuffix}$`, 'g')
    if (action.type.match(isFail)) {
      if (action.payload) {
        const { message } = action.payload
        crateToast('error')(`${message}`)
      } else {
        crateToast('error')(
          '予期しないエラーです。ブラウザをリロードしてください。'
        )
      }
    }
  }
  return next(action)
}
