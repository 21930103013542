/* 足カルテ一覧ページ */
import produce from 'immer'
import moment from 'moment'
import * as ActionTypes from '../../constants'

const defaultCreatedTimeFrom = moment().format('YYYY-MM-DD[T00:00]')

const initialState = {
  loading: false,
  isValid: false,
  totalCount: 0,
  defaultHiddenColumnNames: [],
  searchParams: {
    take: 10,
    skip: 0,
    createdTimeFrom: defaultCreatedTimeFrom,
    createdTimeTo: '',
    orderBy: 'createdTime'
  },
  columns: [
    {
      name: 'footUuid',
      title: 'UUID',
      sortingEnabled: false,
      columnName: 'footUuid',
      width: 300
    },
    {
      name: 'leftLength',
      title: '左足長',
      sortingEnabled: false,
      columnName: 'left',
      getCellValue: indexList =>
        indexList.left ? indexList.left.length : undefined
    },
    {
      name: 'leftWidth',
      title: '左足幅',
      sortingEnabled: false,
      columnName: 'leftWidth',
      getCellValue: indexList =>
        indexList.left ? indexList.left.width : undefined
    },
    /*
    {
      name: 'leftJisSize',
      title: '左 J長',
      sortingEnabled: false,
      columnName: 'leftJisSize',
      getCellValue: indexList => (indexList.left ? indexList.left.jisSize : undefined)
    },
    {
      name: 'lefJistWidth',
      title: '左 J幅',
      sortingEnabled: false,
      columnName: 'leftJisWidth',
      getCellValue: indexList => (indexList.left ? indexList.left.jisWidth : undefined)
    },
    */
    {
      name: 'rightLength',
      title: '右足長',
      sortingEnabled: false,
      columnName: 'rightLength',
      getCellValue: indexList =>
        indexList.right ? indexList.right.length : undefined
    },
    {
      name: 'rightWidth',
      title: '右足幅',
      sortingEnabled: false,
      columnName: 'rightWidth',
      getCellValue: indexList =>
        indexList.right ? indexList.right.width : undefined
    },
    /*
    {
      name: 'rightJisSize',
      title: '右 J長',
      sortingEnabled: false,
      columnName: 'rightJisSize',
      getCellValue: indexList => (indexList.right ? indexList.right.jisSize : undefined)
    },
    {
      name: 'rightJistWidth',
      title: '右 J幅',
      sortingEnabled: false,
      columnName: 'leftJisWidth',
      getCellValue: indexList => (indexList.right ? indexList.right.jisWidth : undefined)
    },
    */
    {
      name: 'carte',
      title: '足カルテ',
      sortingEnabled: false,
      columnName: 'carte'
    },
    {
      name: 'createdTime',
      title: '撮影日時',
      sortingEnabled: true,
      columnName: 'createdTime',
      width: 140
    }
  ],
  indexList: [],
  threeUrl: '',
  footMeasurement: { left: {}, right: {} },
  endUser: {},
  client: {}
}

export default (state: State = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FOOT_INDEX_SUCCESS: {
        const { data } = action.payload
        draft.indexList = data.feet
        draft.totalCount = data.feet_total_count
        draft.client = data.client
        break
      }
      case ActionTypes.FEET_INPUT_CHANGE: {
        const { type, value } = action.payload
        draft.searchParams[type] = value
        break
      }
      case ActionTypes.FEET_SEARCH_CLEAR: {
        draft.searchParams = initialState.searchParams
        break
      }
      case ActionTypes.FEET_UPDATE_SEARCH: {
        Object.assign(draft.searchParams, action.payload)
        break
      }
      default:
    }
  })
}
