/* @flow */
import produce from 'immer'
import * as ActionTypes from '../constants'
import each from 'lodash/each'

type State = {
  brand: Array<any>,
  provider: Array<any>,
  category: Array<any>,
  toe: Array<any>,
  heelType: Array<any>,
  target: Array<any>,
  color: Array<any>,
  material: Array<any>,
  workProcess: Array<any>,
  client: Array<any>
}

const initialState = {
  brand: [],
  provider: [],
  category: [],
  client: [],
  toe: [],
  heelType: [],
  target: [],
  color: [],
  material: [],
  workProcess: []
}

export default (state: State = initialState, action: Function) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.APP_INITIALIZE_SUCCESS:
        each(action.payload, (value, key) => {
          draft[key] = value
        })
        break
      default:
    }
  })
}
