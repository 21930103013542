/* @flow */
import { ERRORS_MSG } from '../constants'

// 正規表現
export const regExp = {
  email: new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/, 'i'),
  number: new RegExp(/^[0-9]+$/, 'i'),
  zenkaku: new RegExp(/^(\w| |'|,|&)+$/)
}

export const email = (key: string, email: string) => {
  const errors = {}
  if (!regExp.email.test(email)) {
    errors[key] = ERRORS_MSG.email.inValid
  }
  return errors
}

export const number = (key: string, number: string) => {
  const errors = {}

  if (number && !regExp.number.test(number)) {
    errors[key] = ERRORS_MSG.number
  }
  return errors
}

export const blank = (key: string, text: string) => {
  const errors = {}
  if (text.length === 0) {
    errors[key] = ERRORS_MSG.blank
  }
  return errors
}

export const zenkaku = (key: string, text: string) => {
  const errors = {}
  if (!regExp.zenkaku.test(text)) {
    errors[key] = ERRORS_MSG.zenkaku
  }
  return errors
}
