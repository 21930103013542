import produce from 'immer'
import * as ActionTypes from '../../constants'

const initialState = {
  data: []
}

const addState = {
  id: null,
  mesh: {
    ffColorId: '',
    ffMaterialId: '',
    makerColor: '',
    makerMaterial: '',
    pictures: [],
    patternId: null
  },
  price: '',
  makerWidth: ''
}

export default (state = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FORM_SHOES_FETCH_DETAIL_SUCCESS: {
        const variations = action.payload.variations
        const arr = variations.length ? [...variations, addState] : [addState]
        const tmp = arr.map(obj => {
          return {
            variationId: obj.id,
            input: obj,
            newSize: {
              loading: false,
              input: {
                makerSize: '',
                ffMakerSize: '',
                jancode: ''
              }
            },
            errors: { mesh: {} },
            loading: false
          }
        })
        draft.data = tmp
        break
      }
      case ActionTypes.FORM_SHOES_INPUT_SIZE: {
        const { variationId, key, value } = action.payload
        const targetVariation = draft.data.find(
          obj => obj.variationId === variationId
        )
        targetVariation.newSize.input[key] = value
        break
      }
      case ActionTypes.FORM_SHOES_INPUT_FF_SIZE: {
        const { variationId, key, value } = action.payload
        const targetVariation = draft.data.find(
          obj => obj.variationId === variationId
        )
        targetVariation.newSize.input[key] = value
        break
      }
      case ActionTypes.FORM_VARIATION_INPUT_CHANGE: {
        const variation = draft.data.find(
          obj => obj.variationId === action.payload.variationId
        )
        if (action.meta.type === 'mesh') {
          variation.input.mesh[action.payload.key] = action.payload.value
        } else {
          variation.input[action.payload.key] = action.payload.value
        }
        break
      }
      case ActionTypes.FORM_SHOES_ALL_CLEAR: {
        draft.data = []
        break
      }
      // 画像アップロードのリクエスト
      case ActionTypes.FORM_VARIATION_UPLOAD_IMG_REQUEST: {
        const uploadingVariation = draft.data.find(
          obj => obj.variationId === action.payload.variationId
        )
        uploadingVariation.loading = true
        return
      }
      // 画像アップロード成功
      case ActionTypes.FORM_VARIATION_UPLOAD_IMG_SUCCESS:
      case ActionTypes.FORM_VARIATION_UPLOAD_IMG_FAILURE:
        const uploadingSuccessVariation = draft.data.find(
          obj => obj.variationId === action.payload.variationId
        )
        uploadingSuccessVariation.loading = false
        break
      // バリデーションエラー
      case ActionTypes.FORM_VARIATION_SUBMIT_ERROR: {
        const { variationId, response } = action.payload
        let targetVariation
        if (variationId) {
          targetVariation = draft.data.find(
            obj => obj.variationId === variationId
          )
        } else {
          targetVariation = draft.data.find(obj => obj.variationId === null)
        }
        console.log(response.message)
        console.log(targetVariation.errors)
        targetVariation.errors = Object.assign({ mesh: {} }, response.message)
        break
      }
      default:
    }
  })
}
