/* @flow */
/* ユーザーページ */
import produce from 'immer'
import values from 'lodash/values'
import compact from 'lodash/compact'

import * as ActionTypes from '../../constants'

type State = {
  input: {
    username: string,
    email: string,
    organization: string,
    password: string,
    isSuperuser: boolean,
    passwordConfirm: string
  },
  errors: {
    username: string,
    email: string,
    organization: string,
    password: string,
    passwordConfirm: string
  },
  loading: boolean,
  isValid: boolean
}

const initialState: State = {
  input: {
    username: '',
    email: '',
    organization: '',
    password: '',
    passwordConfirm: '',
    isSuperuser: false
  },
  errors: {
    username: '',
    email: '',
    organization: '',
    password: '',
    passwordConfirm: ''
  },
  loading: false,
  isValid: false
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.USER_VALIDATE_EXEC:
        const { key, error } = action.payload
        draft.errors[key] = error
        draft.isValid = compact(values(draft.errors)).length === 0
        break
      case ActionTypes.USER_INPUT_NAME:
        draft.input.username = action.payload
        break
      case ActionTypes.USER_INPUT_ORGANIZATION:
        draft.input.organization = action.payload
        break
      case ActionTypes.USER_INPUT_MAIL:
        draft.input.email = action.payload
        break
      case ActionTypes.USER_INPUT_PASSWORD:
        draft.input.password = action.payload
        break
      case ActionTypes.USER_INPUT_PASSWORD_CONFIRM:
        draft.input.passwordConfirm = action.payload
        break
      case ActionTypes.USER_CHANGE_SUPERUSER:
        draft.input.isSuperuser = !state.input.isSuperuser
        break
      case ActionTypes.USER_GET_REQUEST:
        draft.loading = true
        draft.input = initialState.input
        draft.errors = initialState.errors
        break
      case ActionTypes.USER_INPUT_SUCCESS:
      case ActionTypes.USER_PUT_SUCCESS:
        draft.loading = false
        draft.input = initialState.input
        draft.errors = initialState.errors
        break
      case ActionTypes.USER_INPUT_FAILURE:
      case ActionTypes.USER_PUT_FAILURE:
      case ActionTypes.USER_GET_FAILURE:
        draft.loading = false
        break
      case ActionTypes.USER_GET_SUCCESS:
        draft.loading = false
        draft.input = action.payload
        break
      case ActionTypes.USER_INPUT_OPEN:
        draft.input = initialState.input
        draft.errors = initialState.errors
        break
      case ActionTypes.USER_INPUT_ERROR:
        draft.errors = action.payload.message
        break
      case ActionTypes.USER_PUT_ERROR:
        draft.errors = action.payload.message
        draft.loading = false
        break
      default:
    }
  })
}
