const ITEM_HEIGHT = 48

export default theme => {
  return {
    '@global': {
      '*': {
        '-webkit-font-smoothing': 'antialiased',
        boxSizing: 'border-box'
      },
      body: {
        fontSize: '16px',
        lineHeight: 1.7,
        fontFamily: 'Roboto, sans-serif',
        position: 'relative',
        overflowX: 'hidden',
        background: '#EEEEEE'
      },
      a: {
        textDecoration: 'underline',
        color: '#0366d6',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      // toast
      '.toast-success': {
        background: '#388E3C',
        fontWeight: 'bold',
        fontSize: '14px',
        paddingLeft: '20px'
      },
      '.toast-error': {
        background: '#D32F2F',
        fontWeight: 'bold',
        fontSize: '14px',
        paddingLeft: '20px'
      },
      '.Select-control': {
        display: 'flex',
        alignItems: 'center',
        border: 0,
        height: 'auto',
        background: 'transparent',
        '&:hover': {
          boxShadow: 'none'
        }
      },
      '.Select-multi-value-wrapper': {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.Select--multi .Select-input': {
        margin: 0
      },
      '.Select.has-value.is-clearable.Select--single > .Select-control .Select-value': {
        padding: 0
      },
      '.Select-noresults': {
        padding: theme.spacing.unit * 2
      },
      '.Select-input': {
        display: 'inline-flex !important',
        padding: 0,
        height: 'auto'
      },
      '.Select-input input': {
        background: 'transparent',
        border: 0,
        padding: 0,
        cursor: 'default',
        display: 'inline-block',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        margin: 0,
        outline: 0
      },
      '.Select-placeholder, .Select--single .Select-value': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        color: theme.palette.common.black
      },
      '.Select-placeholder': {
        opacity: 0.42,
        color: theme.palette.common.black
      },
      '.Select-placeholder, .Select--single > .Select-control .Select-value': {
        color: theme.palette.common.black
      },
      '.Select-menu-outer': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        position: 'absolute',
        left: 0,
        top: `calc(100% + ${theme.spacing.unit}px)`,
        width: '100%',
        zIndex: 2,
        maxHeight: ITEM_HEIGHT * 4.5
      },
      '.Select.is-focused:not(.is-open) > .Select-control': {
        boxShadow: 'none'
      },
      '.Select-menu': {
        maxHeight: ITEM_HEIGHT * 4.5,
        overflowY: 'auto'
      },
      '.Select-menu div': {
        boxSizing: 'content-box'
      },
      '.Select-arrow-zone, .Select-clear-zone': {
        color: theme.palette.action.active,
        cursor: 'pointer',
        height: 21,
        width: 21,
        zIndex: 1
      },
      // Only for screen readers. We can't use display none.
      '.Select-aria-only': {
        position: 'absolute',
        overflow: 'hidden',
        clip: 'rect(0 0 0 0)',
        height: 1,
        width: 1,
        margin: -1
      },
      '.bold': {
        fontWeight: 'bold !important'
      }
    },
    main: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}
