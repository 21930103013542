/* @flow */
/* 靴管理(品番単位) */
import produce from 'immer'

import * as ActionTypes from '../../constants'

type State = {
  loading: {
    csvUpload: boolean,
    csvDownload: boolean
  }
}

const initialState: State = {
  loading: {
    csvUpload: false,
    csvDownload: false
  }
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_REQUEST:
        draft.loading.csvUpload = true
        break
      case ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_SUCCESS:
      case ActionTypes.SHOW_DIALOG_ERROR:
        draft.loading.csvUpload = false
        break
      case ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_FAILURE:
        draft.loading.csvUpload = false
        break
      default:
    }
  })
}
