/* @flow */
import React from 'react'

var container
var camera, controls, scene, renderer
var THREE = window.THREE
var req = null

type Props = {
  threeUrl: ?string
}

class ThreeView extends React.Component<Props> {
  componentDidMount() {
    // const width = 836
    // const height = 627
    const width = 752
    const height = 564
    let isPcd = false

    const { threeUrl } = this.props

    if (threeUrl && threeUrl.indexOf('pcd') >= 0) {
      isPcd = true
    }

    function animate() {
      req = requestAnimationFrame(animate)
      controls.update()
      renderer.render(scene, camera)
    }

    function init() {
      scene = new THREE.Scene()
      scene.background = new THREE.Color(0x000000)

      if (isPcd) {
        camera = new THREE.PerspectiveCamera(
          45,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        )
        camera.position.x = 0
        camera.position.y = -700
        camera.position.z = 350
        camera.lookAt(new THREE.Vector3(0, 0, 0))
      } else {
        camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          1,
          1000
        )
        camera.position.x = -50
        camera.position.y = -45
        camera.position.z = -320
        camera.lookAt(new THREE.Vector3(0, 0, 0))
      }

      controls = new THREE.TrackballControls(camera)

      controls.rotateSpeed = 2.0
      controls.zoomSpeed = 0.3
      controls.panSpeed = 0.2

      controls.staticMoving = true
      controls.dynamicDampingFactor = 0.3

      controls.minDistance = 0.3
      controls.maxDistance = Infinity

      scene.add(camera)

      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(width, height)

      const threeEl = document.getElementById('three')
      if (threeEl) {
        threeEl.appendChild(renderer.domElement)
      }

      var loader

      if (isPcd) {
        loader = new THREE.PCDLoader()
        loader.load(threeUrl, function(mesh) {
          scene.add(mesh)
          mesh.material.size *= 500
          mesh.material.needsUpdate = true
          var center = mesh.geometry.boundingSphere.center
          controls.target.set(center.x, center.y, center.z)
          controls.update()
        })
      } else {
        loader = new THREE.STLLoader()
        var material = new THREE.MeshBasicMaterial({ color: 0xeeefff })
        loader.load(threeUrl, function(geometry) {
          var mesh = new THREE.Mesh(geometry, material)
          mesh.position.set(0, 0, 0)
          mesh.rotation.set(Math.PI / 2, Math.PI / 4, -Math.PI / 2)
          mesh.scale.set(1.5, 1.5, 1.5)
          mesh.castShadow = true
          mesh.receiveShadow = true
          scene.add(mesh)
          var center = mesh.geometry.boundingSphere.center
          controls.target.set(center.x, center.y, center.z)
          controls.update()
        })
      }

      container = document.createElement('div')
      const threeeEl = document.getElementById('three')
      if (threeeEl) {
        threeeEl.appendChild(container)
      }
      container.appendChild(renderer.domElement)
    }

    init()
    animate()
  }

  componentWillUnmount() {
    controls.enabled = false
    if (req) {
      cancelAnimationFrame(req)
    }
  }

  render() {
    return <div id="three" />
  }
}

export default ThreeView
