/* クライアントページ */
import produce from 'immer'

import * as ActionTypes from '../../constants'

const initialState = {
  input: { clientCode: '', clientName: '', storeName: '' },
  loading: false,
  isValid: false,
  totalCount: 0,
  defaultHiddenColumnNames: [],
  searchParams: {
    take: 10,
    skip: 0,
    clientCode: '',
    companyName: '',
    storeName: '',
    orderBy: ''
  },
  columns: [
    {
      name: 'clientCode',
      title: 'クライアントコード',
      sortingEnabled: true,
      columnName: 'clientCode'
    },
    {
      name: 'companyName',
      title: '企業名',
      sortingEnabled: true,
      columnName: 'companyName'
    },
    {
      name: 'storeName',
      title: '店舗名',
      sortingEnabled: true,
      columnName: 'storeName'
    },
    {
      name: 'createdTime',
      title: '登録日',
      sortingEnabled: true,
      columnName: 'createdTime'
    },
    {
      name: 'tool',
      title: '操作',
      sortingEnabled: false,
      columnName: 'tool'
    }
  ],
  indexList: [],
  digitizer: []
}

export default (state: State = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.CLIENT_INPUT: {
        const { type, text } = action.payload
        draft.input[type] = text
        break
      }
      case ActionTypes.CLIENT_INDEX_SUCCESS: {
        const { data, totalCount } = action.payload
        draft.indexList = data
        draft.totalCount = totalCount
        break
      }
      case ActionTypes.CLIENT_LOAD_DATA: {
        draft.input = action.payload
        break
      }
      case ActionTypes.CLIENT_INPUT_INITIALIZE: {
        draft.input = initialState.input
        break
      }
      case ActionTypes.CLIENTS_INPUT_CHANGE: {
        const { type, value } = action.payload
        draft.searchParams[type] = value
        break
      }
      case ActionTypes.CLIENTS_SEARCH_CLEAR: {
        draft.searchParams = initialState.searchParams
        break
      }
      case ActionTypes.CLIENTS_UPDATE_SEARCH: {
        Object.assign(draft.searchParams, action.payload)
        break
      }
      default:
    }
  })
}
