/* @flow */
import * as React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles, Grid, TextField } from '@material-ui/core'

import SubmitButton from '../components/SubmitButton'
import withPage from '../hocs/withPage'
import PageTitle from '../components/PageTitle'
import styles from '../styles/MyPage.style'
import * as actions from '../actions/mypage'

type Props = { classes: Object, actions: Object, mypage: Object }

class MyPage extends React.Component<Props> {
  handleSubmit = e => {
    e.preventDefault()
    this.props.actions.submit()
  }

  handleChange = param => e => {
    this.props.actions.input(param, e.target.value)
  }

  render() {
    const { classes } = this.props
    const { input, errors } = this.props.mypage

    return (
      <div>
        <PageTitle title="マイページ" />
        <Grid item xs={4}>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            <h3>パスワードの更新</h3>
            <TextField
              label="既存パスワード"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              helperText={errors.current}
              className={classes.row}
              onChange={this.handleChange('previousPassword')}
              value={input.current}
              error={!!errors.current}
              required
            />
            <TextField
              label="新しいパスワード"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              helperText={errors.change}
              className={classes.row}
              onChange={this.handleChange('proposedPassword')}
              value={input.change}
              error={!!errors.change}
              required
            />
            <div className={classes.buttons}>
              <SubmitButton
                variant="contained"
                color="primary"
                className="bold"
                type="submit"
              >
                パスワードを変更する
              </SubmitButton>
            </div>
          </form>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mypage: state.pages.mypage
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withPage({ title: 'マイページ' }),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyPage)
