/* @flow */
import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'

import { withStyles, Snackbar, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { toastHide } from '../actions/app'

const styles = theme => {
  return {
    close: {
      width: theme.spacing.unit * 4,
      height: theme.spacing.unit * 4
    },
    error: {
      color: theme.palette.secondary.main
    }
  }
}

type Props = {
  classes: Object,
  actions: Object,
  toast: Object
}

class Toast extends React.Component<Props> {
  handleClose = () => {
    this.props.actions.toastHide()
  }

  render() {
    const { classes, toast } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={toast.open}
        autoHideDuration={5000}
        onClose={this.handleClose}
        message={
          <span
            className={classNames({
              [classes.error]: toast.type !== 'success'
            })}
          >
            {toast.message}
          </span>
        }
        action={[
          <IconButton
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
            key="closeButton"
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }
}

const mapStateToProps = state => ({
  toast: state.common.app.toast
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ toastHide }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Toast)
