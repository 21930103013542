/* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withStyles, CircularProgress } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const styles = {
  loading: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: `${grey[100]}`,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const PageLoading = ({ classes, loading }) => {
  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  loading: state.common.app.loading
})

export default compose(withStyles(styles), connect(mapStateToProps))(
  PageLoading
)
