import produce from 'immer'
import * as ActionTypes from '../../constants'
import { API_BASE_URL } from '../../constants'

const initialState = {
  data: [],
  loading: false
}

export default (state = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FORM_SHOES_FETCH_DETAIL_SUCCESS:
        const measurements = action.payload.measurements
        const tmpArr = measurements.map(obj => {
          const fileName =
            obj.threeImage.length > 0 ? obj.threeImage[0].fileName : ''
          ///products/measurements/three/{measurement_id}/{filename}
          // const fileName =
          //   obj.threeImage.length > 0
          //     ? `${API_BASE_URL}/shoe/measurement/${obj.id}/${
          //         obj.threeImage[0].fileName
          //       }`
          //     : ''
          return {
            ...obj,
            state: 0,
            fileName
          }
        })
        draft.data = tmpArr
        break
      case ActionTypes.FORM_VARIATION_UPLOAD_THREE_REQUEST:
        draft.loading = true
        break
      case ActionTypes.FORM_VARIATION_UPLOAD_THREE_SUCCESS:
      case ActionTypes.FORM_VARIATION_UPLOAD_THREE_ERROR:
        draft.loading = false
        break
      // 詳細情報の入力
      case ActionTypes.FORM_VARIATION_MEASUREMENT_INPUT:
        const { index, key, value } = action.payload
        draft.data[index][key] = value
        break
      case ActionTypes.FORM_SHOES_ALL_CLEAR:
        draft.data = []
        break
      default:
    }
  })
}
