import queryString from 'query-string'
import convertKeys from 'convert-keys'
import { push } from 'react-router-redux'
import crateToast from '../utils/createToast'
import * as ActionTypes from '../constants'
import { apiClient } from '../api/v1'
import { pickBy } from 'lodash'

export function updateSearchCondition() {
  return function(dispatch, getState) {
    const location = getState().router.location
    const searchParams = getState().pages.digitizer.searchParams
    const cleanedObj = pickBy(searchParams, v => v != null)
    const searchParamStr = queryString.stringify(
      convertKeys.toSnake(cleanedObj)
    )
    const url = `${location.pathname}?${searchParamStr}${location.hash}`
    dispatch(push(url))
    dispatch(loadIndexData())
  }
}

export function loadClients() {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'get',
        url: `/clients?take=9999`
      })
      if (data.statusCode === 200) {
        const clients = data.data.map(({ id, clientCode, companyName }) => {
          return { id, clientCode, companyName }
        })
        dispatch({
          type: ActionTypes.DIGITIZER_CLIENTS_FETCH,
          payload: [
            { id: 99999, clientCode: '', companyName: '未設定' },
            ...clients
          ]
        })
        return resolve({ ok: true })
      }
      return reject()
    })
  }
}

export function loadIndexData() {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const location = getState().router.location
      const { data } = await apiClient({
        method: 'get',
        url: `/digitizer?${location.search.substr(1)}`
      })
      if (data.statusCode === 200) {
        dispatch({
          type: ActionTypes.DIGITIZER_INDEX_SUCCESS,
          payload: data
        })
        return resolve()
      }
      return reject(data)
    })
  }
}

function deleteRecord(digitizerCode) {
  return async function(dispatch, getState) {
    const response = await apiClient({
      method: 'delete',
      url: `/digitizer/${digitizerCode}`
    })
    const data = convertKeys.toCamel(response.data)
    if (data.statusCode === 200) {
      crateToast('success')('デジタイザを削除しました')
      return dispatch(loadIndexData())
    }
    crateToast('error')('デジタイザの削除に失敗しました')
  }
}

export function onDelete(digitizerCode) {
  return function(dispatch, getState) {
    dispatch({
      type: ActionTypes.DIGITIZER_DELETE,
      payload: digitizerCode,
      meta: {
        confirm: '本当に削除しますか？',
        callbackAction: deleteRecord
      }
    })
  }
}

export function loadData(digitizerCode) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'get',
        url: `/digitizer/${digitizerCode}`
      })
      if (data.statusCode === 200) {
        return resolve({
          digitizerCode: data.digitizerCode,
          clientCode: data.clientCode
        })
      }
      crateToast('error')('デジタイザが存在しません')
      reject()
    })
  }
}

// 新規登録
export function register(sendData) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'post',
        url: `/digitizer`,
        data: convertKeys.toSnake(sendData)
      })
      if (data.statusCode === 200) {
        crateToast('success')('デジタイザを登録しました')
        dispatch(loadIndexData())
        return resolve({ ok: true })
      }
      crateToast('error')(data.message.digitizerCode)
      return reject()
    })
  }
}

// 編集
export function put(sendData) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'put',
        url: `/digitizer/${sendData.digitizerCode}`,
        data: convertKeys.toSnake(sendData)
      })
      if (data.statusCode === 200) {
        crateToast('success')('デジタイザを更新しました')
        dispatch(loadIndexData())
        return resolve({ ok: true })
      }
      crateToast('error')(data.message | 'デジタイザの更新に失敗しました')
      return reject()
    })
  }
}

export function onChangePageSize(pageSize) {
  return function(dispatch, getState) {
    dispatch({
      type: ActionTypes.DIGITIZER_UPDATE_SEARCH,
      payload: {
        take: pageSize
      }
    })
    dispatch(updateSearchCondition())
  }
}

export function onPageChange(currentPage) {
  return function(dispatch, getState) {
    const {
      pages: {
        digitizer: {
          searchParams: { take }
        }
      }
    } = getState()
    dispatch({
      type: ActionTypes.DIGITIZER_UPDATE_SEARCH,
      payload: {
        skip: currentPage * take
      }
    })
    dispatch(updateSearchCondition())
  }
}

export function searchClear() {
  return function(dispatch, getState) {
    dispatch({
      type: ActionTypes.DIGITIZER_CLIENTS_SEARCH_CLEAR
    })
    dispatch(updateSearchCondition())
  }
}

export function onChange(type, value) {
  return {
    type: ActionTypes.DIGITIZER_CLIENTS_CHANGE,
    payload: { type, value }
  }
}
