import { grey } from '@material-ui/core/colors'

export default ({ spacing, palette }) => ({
  wrapper: {
    padding: `0 ${spacing.unit * 4}px`
  },
  row: {
    marginBottom: `${spacing.unit * 5}px`
  },
  paper: {
    padding: `${spacing.unit}px`
  },
  dlWrap: {
    padding: `${spacing.unit}px`,
    display: 'flex',
    flexWrap: 'wrap',
    '& dl': {
      width: '50%',
      margin: 0,
      fontSize: '14px',
      display: 'flex',
      borderBottom: `1px solid ${grey[200]}`
    },
    '& dt': {
      width: '40%',
      padding: '10px 20px',
      backgroundColor: grey[300],
      borderTop: `1px solid ${grey[200]}`
    },
    '& dd': {
      margin: 0,
      fontSize: '14px',
      width: '60%',
      padding: '10px 20px',
      borderTop: `1px solid ${grey[200]}`,
      borderRight: `1px solid ${grey[200]}`
    }
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
