/* @flow */
import produce from 'immer'
import each from 'lodash/each'
import moment from 'moment'
import store from 'store'

import * as ActionTypes from '../constants'

type BaseState = {
  loading: ?boolean,
  searchParams: {
    // 1ページあたりの表示数
    take?: ?number,
    // 現在のページ(0スタート)
    skip?: ?number,
    brandIds: ?string,
    providerIds?: ?string,
    // 品番
    productNumber?: ?string,
    // JANコード
    jancode?: ?string,
    // 登録日（範囲指定_開始）
    createdTimeFrom?: ?string,
    // 登録日（範囲指定_終了）
    createdTimeTo?: ?string,
    // 更新日（範囲指定_開始）
    updatedTimeFrom?: ?string,
    // 更新日（範囲指定_終了）
    updateTimeTo?: ?string,
    // ソート
    orderBy?: ?string,
    public?: ?boolean,
    // 検索ステータス
    mode?: string,
    name?: string,
    clientId?: ?number,
    workProcessId?: ?number,
    publicFlag?: ?boolean
  },
  // 検索設定
  searchSettings: {
    // トータルページ数
    totalCount: ?number,
    // 1ページあたりの表示数
    pageSizes: ?Array<number>,
    // 行の選択
    selections: ?Array<any>,
    // ロードしたデータ
    rows: ?Array<any>,
    // フェッチしたデータを入れる配列
    columns?: ?Array<any>,
    // 作業ステータス
    workStatus?: ?Array<any>,
    // レコメンドステータス
    recommendStatus?: Array<any>,
    // 非表示カラム
    defaultHiddenColumnNames?: Array<any>,
    workProcessId?: ?number
  }
}

const defaultCreatedTimeFrom = moment()
  .subtract(3, 'months')
  .format('YYYY-MM-DD')

const baseState: BaseState = {
  loading: false,
  searchParams: {
    take: 10,
    skip: 0,
    brandIds: '',
    providerIds: '',
    productNumber: '',
    jancode: null,
    createdTimeTo: null,
    updatedTimeFrom: null,
    updateTimeTo: null,
    orderBy: 'id'
  },
  searchSettings: {
    totalCount: 0,
    pageSizes: [5, 10, 15],
    selections: [],
    rows: []
  }
}

type State = {
  shoesProduct: Object,
  shoesItem: Object,
  shoesPublic: Object,
  recommends: Object,
  clients: Object,
  brands: Object,
  users: Object
}

const getStoreHiddenColumn = pageName => {
  const storage = store.get('hiddenColumns')
  if (!storage || !storage[pageName]) return null
  return storage[pageName]
}

const initialState: State = {
  shoesProduct: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      { name: 'id', title: 'PID', sortingEnabled: false, columnName: 'id' },
      {
        name: 'productNumber',
        title: '品番',
        sortingEnabled: true,
        columnName: 'productNumber'
      },
      {
        name: 'brand',
        title: 'ブランド',
        sortingEnabled: true,
        columnName: 'brand'
      },
      {
        name: 'category',
        title: 'カテゴリ',
        sortingEnabled: true,
        columnName: 'category'
      },
      {
        name: 'shoeTypeName',
        title: '靴タイプ',
        sortingEnabled: true,
        columnName: 'shoeTypeName'
      },
      {
        name: 'productName',
        title: '品名',
        sortingEnabled: true,
        columnName: 'productName'
      },
      {
        name: 'provider',
        title: '提供',
        sortingEnabled: true,
        columnName: 'provider'
      },
      {
        name: 'toe',
        title: 'つま先形状',
        sortingEnabled: true,
        columnName: 'toe'
      },
      {
        name: 'heelType',
        title: 'ヒール形状',
        sortingEnabled: true,
        columnName: 'heelType'
      },
      {
        name: 'heelHeight',
        title: 'ヒール高',
        sortingEnabled: true,
        columnName: 'heelHeight'
      },
      {
        name: 'strap',
        title: 'ストラップ',
        sortingEnabled: false,
        columnName: 'strap'
      },
      {
        name: 'shoelace',
        title: '靴紐',
        sortingEnabled: false,
        columnName: 'shoelace'
      },
      {
        name: 'targetName',
        title: 'ターゲット',
        sortingEnabled: false,
        columnName: 'targetName'
      },
      {
        name: 'items',
        title: 'アイテム数',
        sortingEnabled: false,
        columnName: 'items'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      },
      {
        name: 'comment',
        title: '商品コメント',
        sortingEnabled: false,
        columnName: 'comment'
      }
    ]
    // 初期非表示カラム
    draft.searchSettings.defaultHiddenColumnNames = getStoreHiddenColumn(
      'shoesProduct'
    ) || ['category', 'target', 'createdTime', 'updatedTime', 'comment']
    draft.searchParams.createdTimeFrom = defaultCreatedTimeFrom
  }),

  // 靴確認 (SKU単位)
  shoesItem: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      { name: 'id', title: 'ID', sortingEnabled: false, columnName: 'id' },
      {
        name: 'productNumber',
        title: '品番',
        sortingEnabled: true,
        columnName: 'productNumber'
      },
      {
        name: 'brand',
        title: 'ブランド',
        sortingEnabled: true,
        columnName: 'brand'
      },
      {
        name: 'category',
        title: 'カテゴリ',
        sortingEnabled: false,
        columnName: 'category'
      },
      {
        name: 'shoeTypeName',
        title: '靴タイプ​',
        sortingEnabled: false,
        columnName: 'shoeTypeName'
      },
      {
        name: 'productName',
        title: '品名',
        sortingEnabled: false,
        columnName: 'productName'
      },
      {
        name: 'provider',
        title: '提供',
        sortingEnabled: false,
        columnName: 'provider'
      },
      {
        name: 'toe',
        title: 'つま先形状',
        sortingEnabled: false,
        columnName: 'toe'
      },
      {
        name: 'heelType',
        title: 'ヒール形状',
        sortingEnabled: false,
        columnName: 'heelType'
      },
      {
        name: 'heelHeight',
        title: 'ヒール高',
        sortingEnabled: false,
        columnName: 'heelHeight'
      },
      {
        name: 'strap',
        title: 'ストラップ',
        sortingEnabled: false,
        columnName: 'strap'
      },
      {
        name: 'shoelace',
        title: '紐',
        sortingEnabled: false,
        columnName: 'shoelace'
      },
      {
        name: 'targetName',
        title: 'ターゲット',
        sortingEnabled: false,
        columnName: 'targetName'
      },
      {
        name: 'makerColor',
        title: 'メーカー色',
        sortingEnabled: false,
        columnName: 'makerColor'
      },
      {
        name: 'makerMaterial',
        title: 'メーカー素材',
        sortingEnabled: false,
        columnName: 'makerMaterial'
      },
      {
        name: 'makerDesign',
        title: 'デザイン・柄​',
        sortingEnabled: false,
        columnName: 'makerDesign'
      },
      {
        name: 'ffColor',
        title: 'FF色',
        sortingEnabled: false,
        columnName: 'ffColor'
      },
      {
        name: 'ffMaterial',
        title: 'FF素材',
        sortingEnabled: false,
        columnName: 'ffMaterial'
      },
      {
        name: 'makerSize',
        title: 'メーカーサイズ',
        sortingEnabled: true,
        columnName: 'makerSize'
      },
      {
        name: 'ffMakerSize',
        title: 'cmサイズ',
        sortingEnabled: true,
        columnName: 'ffMakerSize'
      },
      {
        name: 'makerWidth',
        title: 'メーカーワイズ',
        sortingEnabled: true,
        columnName: 'makerWidth'
      },
      {
        name: 'pictures',
        title: '画像',
        sortingEnabled: false,
        columnName: 'pictures'
      },
      {
        name: 'threeImages',
        title: '3Dデータ',
        sortingEnabled: false,
        columnName: 'threeImages'
      },
      {
        name: 'workProcessName',
        title: '作業',
        sortingEnabled: true,
        columnName: 'workProcessName'
      },
      {
        name: 'publicFlg',
        title: 'レコメンド',
        sortingEnabled: false,
        columnName: 'publicFlg'
      },
      {
        name: 'jancode',
        title: 'JANコード',
        sortingEnabled: false,
        columnName: 'jancode'
      },
      {
        name: 'comment',
        title: 'コメント',
        sortingEnabled: false,
        columnName: 'comment'
      },
      {
        name: 'feedback',
        title: '作業フィードバック',
        sortingEnabled: false,
        columnName: 'feedback'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      }
    ]
    // 初期非表示カラム
    draft.searchSettings.defaultHiddenColumnNames = getStoreHiddenColumn(
      'shoesItem'
    ) || [
      'category',
      'productName',
      'toe',
      'heelHeight',
      'targetName',
      'ffColor',
      'ffMaterial',
      'ffSize',
      'ffWidth',
      'threeImages',
      'jancode',
      'comment',
      'feedback',
      'createdTime',
      'updatedTime'
    ]
    draft.searchParams.orderBy = 'id'
    draft.searchParams.public = false
    draft.searchParams.updatedTimeFrom = defaultCreatedTimeFrom
    draft.searchParams.workProcessId = 1
  }),

  // 靴公開 (SKU単位)
  shoesPublic: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      { name: 'id', title: 'ID', sortingEnabled: false, columnName: 'id' },
      {
        name: 'productNumber',
        title: '品番',
        sortingEnabled: true,
        columnName: 'productNumber'
      },
      {
        name: 'brand',
        title: 'ブランド',
        sortingEnabled: true,
        columnName: 'brand'
      },
      {
        name: 'category',
        title: 'カテゴリ',
        sortingEnabled: false,
        columnName: 'category'
      },
      {
        name: 'shoeTypeName',
        title: '靴タイプ',
        sortingEnabled: false,
        columnName: 'shoeTypeName'
      },
      {
        name: 'productName',
        title: '品名',
        sortingEnabled: false,
        columnName: 'productName'
      },
      {
        name: 'provider',
        title: '提供',
        sortingEnabled: false,
        columnName: 'provider'
      },
      {
        name: 'toe',
        title: 'つま先形状',
        sortingEnabled: false,
        columnName: 'toe'
      },
      {
        name: 'heelType',
        title: 'ヒール形状',
        sortingEnabled: false,
        columnName: 'heelType'
      },
      {
        name: 'heelHeight',
        title: 'ヒール高',
        sortingEnabled: false,
        columnName: 'heelHeight'
      },
      {
        name: 'strap',
        title: 'ストラップ',
        sortingEnabled: false,
        columnName: 'strap'
      },
      {
        name: 'shoelace',
        title: '紐',
        sortingEnabled: false,
        columnName: 'shoelace'
      },
      {
        name: 'targetName',
        title: 'ターゲット',
        sortingEnabled: false,
        columnName: 'targetName'
      },
      {
        name: 'makerColor',
        title: 'メーカー色',
        sortingEnabled: false,
        columnName: 'makerColor'
      },
      {
        name: 'makerMaterial',
        title: 'メーカー素材',
        sortingEnabled: false,
        columnName: 'makerMaterial'
      },
      {
        name: 'patternName',
        title: 'デザイン・柄​',
        sortingEnabled: false,
        columnName: 'patternName'
      },
      {
        name: 'ffColor',
        title: 'FF色',
        sortingEnabled: false,
        columnName: 'ffColor'
      },
      {
        name: 'ffMaterial',
        title: 'FF素材',
        sortingEnabled: false,
        columnName: 'ffMaterial'
      },
      {
        name: 'makerSize',
        title: 'メーカーサイズ',
        sortingEnabled: true,
        columnName: 'makerSize​'
      },
      {
        name: 'makerWidth',
        title: 'メーカーワイズ',
        sortingEnabled: true,
        columnName: 'makerWidth'
      },
      {
        name: 'ffMakerSize',
        title: 'cmサイズ​',
        sortingEnabled: true,
        columnName: 'ffMakerSize'
      },
      {
        name: 'pictures',
        title: '画像',
        sortingEnabled: false,
        columnName: 'pictures'
      },
      {
        name: 'threeImages',
        title: '3Dデータ',
        sortingEnabled: false,
        columnName: 'threeImages'
      },
      {
        name: 'publicCount',
        title: '公開数',
        sortingEnabled: false,
        columnName: 'publicCount'
      },
      {
        name: 'workProcessName',
        title: '作業',
        sortingEnabled: false,
        columnName: 'workProcessName'
      },
      {
        name: 'publicFlag',
        title: 'レコメンド',
        sortingEnabled: false,
        columnName: 'publicFlag'
      },
      {
        name: 'jancode',
        title: 'JANコード',
        sortingEnabled: false,
        columnName: 'jancode'
      },
      {
        name: 'comment',
        title: 'コメント',
        sortingEnabled: false,
        columnName: 'comment'
      },
      {
        name: 'feedback',
        title: '作業フィードバック',
        sortingEnabled: false,
        columnName: 'feedback'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      }
    ]
    // 初期非表示カラム
    draft.searchSettings.defaultHiddenColumnNames = getStoreHiddenColumn(
      'shoesPublic'
    ) || [
      'category',
      'productName',
      'toe',
      'heelHeight',
      'targetName',
      'ffColor',
      'ffMaterial',
      'ffSize',
      'ffWidth',
      'threeImages',
      'jancode',
      'comment',
      'feedback',
      'createdTime',
      'updatedTime'
    ]
    draft.searchParams.orderBy = 'id'
    draft.searchParams.publicFlag = true
    draft.searchParams.updatedTimeFrom = defaultCreatedTimeFrom
    draft.searchParams.clientId = null
  }),

  // レコメンド
  recommends: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      {
        name: 'productlistId',
        title: 'OFF/ON',
        sortingEnabled: false,
        columnName: 'productlistId'
      },
      { name: 'id', title: 'ID', sortingEnabled: false, columnName: 'id' },
      {
        name: 'productNumber',
        title: '品番',
        sortingEnabled: true,
        columnName: 'productNumber'
      },
      {
        name: 'brand',
        title: 'ブランド',
        sortingEnabled: true,
        columnName: 'brand'
      },
      {
        name: 'category',
        title: 'カテゴリ',
        sortingEnabled: false,
        columnName: 'category'
      },
      {
        name: 'productName',
        title: '品名',
        sortingEnabled: false,
        columnName: 'productName'
      },
      {
        name: 'provider',
        title: '提供',
        sortingEnabled: false,
        columnName: 'provider'
      },
      {
        name: 'toe',
        title: 'つま先',
        sortingEnabled: false,
        columnName: 'toe'
      },
      {
        name: 'heelType',
        title: 'ヒール形状',
        sortingEnabled: false,
        columnName: 'heelType'
      },
      {
        name: 'heelHeight',
        title: 'ヒール高',
        sortingEnabled: false,
        columnName: 'heelHeight'
      },
      {
        name: 'strap',
        title: 'ストラップ',
        sortingEnabled: false,
        columnName: 'strap'
      },
      {
        name: 'targetName',
        title: 'ターゲット',
        sortingEnabled: false,
        columnName: 'targetName'
      },
      {
        name: 'makerColor',
        title: 'メーカー色',
        sortingEnabled: false,
        columnName: 'makerColor'
      },
      {
        name: 'makerMaterial',
        title: 'メーカー素材',
        sortingEnabled: false,
        columnName: 'makerMaterial'
      },
      {
        name: 'ffColor',
        title: 'FF色',
        sortingEnabled: false,
        columnName: 'ffColor'
      },
      {
        name: 'ffMaterial',
        title: 'FF素材',
        sortingEnabled: false,
        columnName: 'ffMaterial'
      },
      {
        name: 'makerSize',
        title: 'メーカーサイズ',
        sortingEnabled: true,
        columnName: 'makerSize'
      },
      {
        name: 'makerWidth',
        title: 'メーカーワイズ',
        sortingEnabled: true,
        columnName: 'makerWidth'
      },
      {
        name: 'ffSize',
        title: 'FFサイズ',
        sortingEnabled: false,
        columnName: 'ffSize'
      },
      {
        name: 'ffWidth',
        title: 'FFワイズ',
        sortingEnabled: false,
        columnName: 'ffWidth'
      },
      {
        name: 'pictures',
        title: '画像',
        sortingEnabled: false,
        columnName: 'pictures'
      },
      {
        name: 'jancode',
        title: 'JANコード',
        sortingEnabled: false,
        columnName: 'jancode'
      },
      {
        name: 'comment',
        title: 'コメント',
        sortingEnabled: false,
        columnName: 'feedback'
      },
      {
        name: 'feedback',
        title: '作業フィードバック',
        sortingEnabled: false,
        columnName: 'feedback'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      }
    ]
    // 検索項目の追加
    draft.searchParams.clientId = null
    draft.searchSettings.defaultHiddenColumnNames = getStoreHiddenColumn(
      'recommends'
    ) || [
      'category',
      'productName',
      'toe',
      'heelHeight',
      'targetName',
      'ffColor',
      'ffMaterial',
      'ffSize',
      'ffWidth',
      'threeImages',
      'jancode',
      'comment',
      'feedback',
      'createdTime',
      'updatedTime'
    ]
  }),
  clients: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      {
        name: 'code',
        title: 'クライアントコード',
        sortingEnabled: true,
        columnName: 'code'
      },
      {
        name: 'name',
        title: 'クライアント名',
        sortingEnabled: true,
        columnName: 'name'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      },
      {
        name: 'tool',
        title: '操作',
        sortingEnabled: false,
        columnName: 'tool'
      }
    ]
    draft.searchSettings.defaultHiddenColumnNames =
      getStoreHiddenColumn('clients') || []
    draft.searchParams.name = ''
  }),
  brands: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      {
        name: 'OrderNumber',
        title: 'No',
        sortingEnabled: false,
        columnName: 'OrderNumber'
      },
      {
        name: 'OrderDate',
        title: '作成日',
        sortingEnabled: false,
        columnName: 'OrderDate'
      },
      {
        name: 'StoreState',
        title: 'クライアント名',
        sortingEnabled: false,
        columnName: 'StoreState'
      },
      {
        name: 'StoreCity',
        title: 'レコメンドSKU数',
        sortingEnabled: false,
        columnName: 'StoreCity'
      }
    ]
    draft.searchSettings.defaultHiddenColumnNames =
      getStoreHiddenColumn('brands') || []
  }),
  users: produce(baseState, draft => {
    // テーブル情報
    draft.searchSettings.columns = [
      { name: 'id', title: 'ID', sortingEnabled: false, columnName: 'id' },
      {
        name: 'username',
        title: 'ユーザー名',
        sortingEnabled: true,
        columnName: 'username'
      },
      {
        name: 'organization',
        title: '所属',
        sortingEnabled: true,
        columnName: 'organization'
      },
      {
        name: 'isSuperuser',
        title: '権限',
        sortingEnabled: false,
        columnName: 'isSuperuser'
      },
      {
        name: 'createdTime',
        title: '登録日',
        sortingEnabled: true,
        columnName: 'createdTime'
      },
      {
        name: 'updatedTime',
        title: '更新日',
        sortingEnabled: true,
        columnName: 'updatedTime'
      }
    ]
    draft.searchSettings.defaultHiddenColumnNames =
      getStoreHiddenColumn('users') || []
  })
}

export default (state: State = initialState, action: Object) => {
  return produce((state: State), draft => {
    if (action.meta && action.meta.pageName) {
      const pageName = action.meta.pageName
      const pageDraft = draft[pageName]
      switch (action.type) {
        // データの取得、行削除、アイテムのステータス変更のリクエスト
        case ActionTypes.TABLE_LOAD_DATA_REQUEST:
        case ActionTypes.TABLE_ROW_DELETE_REQUEST:
        case ActionTypes.TABLE_ROWS_STATUS_CHANGE_REQUEST:
          pageDraft.loading = true
          break
        // データの取得成功
        case ActionTypes.TABLE_LOAD_DATA_SUCCESS:
          const { totalCount, data } = action.payload
          pageDraft.loading = false
          pageDraft.searchSettings.totalCount = totalCount
          pageDraft.searchSettings.rows = data
          break
        // 取得した靴データの初期化
        case ActionTypes.TABLE_LOAD_DATA_CLEAR:
          pageDraft.searchSettings.rows = []
          break
        // 検索条件の更新
        case ActionTypes.TABLE_SEARCH_CONDITION_UPDATE:
          each(action.payload, (value, key) => {
            pageDraft.searchParams[key] = value
          })
          break
        // データの取得失敗, 検索条件のクリア
        case ActionTypes.TABLE_LOAD_DATA_FAILURE:
        case ActionTypes.TABLE_SEARCH_CONDITION_CLEAR:
          pageDraft.loading = false
          // 初期化する
          pageDraft.searchParams = initialState[pageName].searchParams
          break
        // 行選択
        case ActionTypes.TABLE_ROWS_SELECT:
          pageDraft.searchSettings.selections = action.payload
          break
        // 行選択の解除
        case ActionTypes.TABLE_ROWS_SELECT_CLEAR:
          pageDraft.searchSettings.selections = []
          break
        // アイテムのステータス変更の成功
        case ActionTypes.TABLE_ROWS_STATUS_CHANGE_SUCCESS:
          pageDraft.loading = false
          pageDraft.searchSettings.selections = []
          break
        // 行削除の成功
        case ActionTypes.TABLE_ROW_DELETE_SUCCESS:
          pageDraft.loading = false
          const targetIdx = pageDraft.searchSettings.rows.findIndex(
            o => o.id === action.payload
          )
          pageDraft.searchSettings.rows.splice(targetIdx, 1)
          break
        // フィードバック更新
        case ActionTypes.TABLE_ROWS_FEEDBACK_UPDATE_REQUEST:
          const feedbackUpdate = pageDraft.searchSettings.rows.find(
            o => o.id === action.payload.id
          )
          feedbackUpdate.feedback = action.payload.feedback
          break
        case ActionTypes.TABLE_SEARCH_REMOVE_SKIP: {
          pageDraft.searchParams.skip = 0
          break
        }
        default:
      }
    } else {
      switch (action.type) {
        case ActionTypes.SHOES_PRODUCT_FORM_CHANGE: {
          const { param, value } = action.payload
          draft.shoesProduct.searchParams[param] = value
          break
        }
        case ActionTypes.SHOES_ITEM_FORM_CHANGE: {
          const { param, value } = action.payload
          draft.shoesItem.searchParams[param] = value
          break
        }
        case ActionTypes.SHOES_PUBLIC_FORM_CHANGE: {
          const { param, value } = action.payload
          draft.shoesPublic.searchParams[param] = value
          break
        }
        default:
      }
      // return state
    }
  })
}
