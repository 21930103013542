/* @flow */
import * as React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import * as actions from '../actions/app'
import * as feetActions from '../actions/feet'
import styles from '../styles/EditOnModalPage.style'
import withPage from '../hocs/withPage'
import PageTitle from '../components/PageTitle'
import AppModal from './AppModal'
import ThreeView from './FootThreeView'
import SubmitButton from '../components/SubmitButton'
import {
  PagingState,
  SortingState,
  CustomPaging
} from '@devexpress/dx-react-grid'
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui'
import crateToast from '../utils/createToast'
import moment from 'moment'
import styled from 'styled-components'
import * as FlowTypes from '../flow-types'

type Props = {
  actions: Object
}

type State = {
  selectedId: ?string,
  errors: Object
}

const pageSizes = [5, 10, 15]

class Feet extends React.Component<Props, State> {
  state = {
    selectedId: null,
    errors: {}
  }

  handleModal = footUuid => async () => {
    const detailData = await this.props.pageActions.getFootData(footUuid)
    const setFootMeasValue = {
      left: detailData.foot.left,
      right: detailData.foot.right
    }
    this.setState({
      threeUrl: detailData.foot.threeUrl,
      endUser: detailData.endUser,
      footMeasurement: setFootMeasValue
    })
    this.props.actions.modalOpen()
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.modalClose()
  }

  async componentDidMount() {
    const { match } = this.props
    this.props.pageActions.updateSearchCondition(match.params.clientId)
  }

  render() {
    const {
      classes,
      match,
      indexList,
      client,
      feet: { columns, searchParams, totalCount },
      pageActions
    } = this.props
    console.log(this.props)

    return (
      <div>
        <AppModal>
          <ThreeView
            threeUrl={this.state.threeUrl}
            endUser={this.state.endUser}
            footMeasurement={this.state.footMeasurement}
          />
        </AppModal>
        <PageTitle
          title={'FOOT管理 (' + client.clientCode + client.storeName + ')'}
        />

        <Grid
          container
          spacing={8}
          justify="flex-end"
          className={classes.headButton}
        />

        <SearchMenu>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              {/* 登録日 */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="撮影日時(From)"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={searchParams.createdTimeFrom || ''}
                  required
                  onChange={e =>
                    pageActions.onChange('createdTimeFrom', e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid item xs={1}>
                <div style={{ textAlign: 'center' }}>〜</div>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="撮影日時(To)"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={searchParams.createdTimeTo || ''}
                  onChange={e =>
                    pageActions.onChange('createdTimeTo', e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid container xs={12} justify="flex-end" spacing={24}>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      pageActions.updateSearchCondition(match.params.clientId)
                    }}
                  >
                    検索する
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      pageActions.searchClear(match.params.clientId)
                    }
                  >
                    検索条件をクリア
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SearchMenu>

        <Paper>
          <div style={{ position: 'relative' }}>
            <GridTable rows={indexList} columns={columns}>
              <SortingState
                columnExtensions={columns}
                sorting={searchParams.sorting}
                onSortingChange={sorting => {}}
              />
              <Table
                messages={{ noData: 'データがありません' }}
                columnExtensions={columns}
                cellComponent={({ value, column: { name }, row }) => {
                  if (name === 'carte') {
                    return (
                      <Table.Cell>
                        <Button
                          className="bold"
                          color="primary"
                          size="small"
                          onClick={this.handleModal(row.footUuid, row)}
                        >
                          カルテ
                        </Button>
                      </Table.Cell>
                    )
                  } else {
                    return <Table.Cell>{value}</Table.Cell>
                  }
                }}
              />
              <TableHeaderRow
                showSortingControls
                messages={{ sortingHint: 'ソート' }}
              />
              <PagingState
                currentPage={Math.floor(searchParams.skip / searchParams.take)}
                onCurrentPageChange={currentPage => {
                  pageActions.onPageChange(currentPage, match.params.clientId)
                }}
                pageSize={searchParams.take}
                onPageSizeChange={pageSize => {
                  pageActions.onChangePageSize(pageSize, match.params.clientId)
                }}
              />
              <CustomPaging totalCount={totalCount} />
              <PagingPanel
                pageSizes={pageSizes}
                messages={{ rowsPerPage: '1ページあたりの表示数' }}
              />
            </GridTable>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  feet: state.pages.feet,
  client: state.pages.feet.client,
  threeUrl: state.pages.feet.threeUrl,
  footMeasurement: state.pages.feet.footMeasurement,
  endUser: state.pages.feet.endUser,
  indexList: state.pages.feet.indexList.map(o => {
    return {
      ...o,
      createdTime: moment(o.createdTime).format('YYYY/MM/DD HH:MM')
    }
  })
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  pageActions: bindActionCreators({ ...feetActions }, dispatch)
})

export default compose(
  withPage({ title: '足カルテ管理' }),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Feet)

const SearchMenu = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px 0;
`
