/* @flow */
import React from 'react'
import { compose } from 'recompose'

import { withStyles, CircularProgress } from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  }
})

const ListTableLoading = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.loading} size={50} />
    </div>
  )
}

export default compose(withStyles(styles))(ListTableLoading)
