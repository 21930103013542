/* @flow */
import * as React from 'react'

import { Menu, MenuItem, Button, withStyles } from '@material-ui/core'
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'

const styles = ({ spacing }) => ({
  MenuItem: {
    minWidth: '150px',
    fontSize: '14px'
  }
})

type Props = {
  label: string,
  classes: Object,
  selections: Array<string>,
  statusList: Array<Object>,
  changeState: Object => void
}

type State = {
  anchorEl: ?SyntheticEvent<HTMLButtonElement>
}

class TableSelectionButton extends React.Component<Props, State> {
  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      label,
      classes,
      selections,
      statusList,
      changeState,
      ...rest
    } = this.props
    const { anchorEl } = this.state

    return (
      <Plugin>
        <Template name="toolbarContent">
          <TemplateConnector>
            {() => (
              <div>
                <Button
                  color="primary"
                  className="bold"
                  onClick={this.handleClick}
                  {...rest}
                >
                  {label}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  {statusList.map((s, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        className={classes.MenuItem}
                        onClick={() => {
                          changeState(s)
                          this.handleClose()
                        }}
                      >
                        {s.status}
                      </MenuItem>
                    )
                  })}
                </Menu>
              </div>
            )}
          </TemplateConnector>
          <TemplatePlaceholder />
        </Template>
      </Plugin>
    )
  }
}

export default withStyles(styles)(TableSelectionButton)
