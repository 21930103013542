/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core'
import withPage from '../hocs/withPage'
import createPageTitle from '../utils/createPageTitle'
import FormShoesDetail from '../containers/FormShoesDetail'
import FormShoesVariation from '../containers/FormShoesVariation'
import FormMeasurementList from '../containers/FormMeasurementList'
import * as actions from '../actions/shoesProductDetail'
import { bindActionCreators } from 'redux'

const styles = ({ spacing }) => ({
  wrapper: {
    padding: `0 ${spacing.unit * 4}px`
  },
  row: {
    marginBottom: `${spacing.unit * 10}px`
  }
})

type Props = {
  classes: Object,
  common: Object
}

class ShoesStockNumberPage extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    const { location, actions } = this.props
    const currentPathname = this.props.location.pathname
    const prevPathname = prevProps.location.pathname
    if (prevPathname !== currentPathname) {
      this.props.actions.formReset()
      if (/\/shoes\/product\/\d.+/.test(location.pathname)) {
        // 編集時
        const pathArr = location.pathname.split('/')
        const id = pathArr[pathArr.length - 1]
        actions.fetchDetail(id)
      } else {
        // 新規
        actions.setInitialData()
      }
    }
  }

  render() {
    const { classes, common } = this.props

    return (
      <div className={classes.wrapper}>
        <Helmet>
          <title>
            {createPageTitle(
              (common.productId ? `ID ${common.productId}` : '新規作成') +
                ' 靴管理(品番単位)'
            )}
          </title>
        </Helmet>
        <div className={classes.row}>
          <FormShoesDetail />
        </div>
        {common.productId && (
          <div className={classes.row}>
            <FormShoesVariation />
          </div>
        )}
        <div className={classes.row}>
          <FormMeasurementList />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    common: state.pages.shoesProductDetail.formShoesCommon
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withPage(),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShoesStockNumberPage)
