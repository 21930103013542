/* @flow */
import * as React from 'react'
import compose from 'recompose/compose'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles, Modal, Button } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import * as actions from '../actions/app'

const styles = theme => ({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  inner: {
    position: 'relative',
    padding: theme.spacing.unit * 4,
    maxWidth: '900px',
    minWidth: '750px'
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    transform: 'translate(50%, -50%)'
  }
})

type Props = {
  actions: Object,
  classes: Object,
  modal: boolean,
  children: React.Node
}

class AppModal extends React.Component<Props> {
  el: HTMLElement

  handleClose = () => {
    const { actions } = this.props
    actions.modalClose()
  }

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  render() {
    const { classes, modal } = this.props

    return (
      <Modal open={modal}>
        <div className={classes.wrapper}>
          <div className={classes.inner}>
            <Button
              variant="fab"
              color="primary"
              mini
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </Button>
            {this.props.children}
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.common.app.modalOpen
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppModal)
