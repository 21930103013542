/* @flow */
import axios from 'axios'
import each from 'lodash/each'
import camelcaseObjectDeep from 'camelcase-object-deep'
import snakecaseKeys from 'snakecase-keys'

import { API_BASE_URL, APIS } from '../constants'
import crateToast from '../utils/createToast'
import { logout } from '../actions/login'
import formatSingleError from '../utils/formatSingleError'

let TOKEN = null
let store = null

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// AWS API専用
export const apiClient2 = axios.create({
  baseURL: API_BASE_URL
})

// リクエスト処理を共通化
apiClient.interceptors.request.use(
  config => {
    if (TOKEN) {
      config.headers.Authorization = TOKEN
      // config.headers.Authorization = 'JWT hogehoge'
      return config
    }
    return config
  },
  function(error) {
    const errorMessage = error.response.data
    crateToast('error')(formatSingleError(errorMessage))
    return Promise.reject(error)
  }
)

// レスポンス処理を共通化
apiClient.interceptors.response.use(
  response => {
    // 認証期限切れの場合の対応
    if (response.data.status_code === 401) {
      if (store) {
        store.dispatch(logout())
      }
    }
    return camelcaseObjectDeep(response, { deep: true })
  },
  error => {
    const errorMessage = error.response.data
    crateToast('error')(formatSingleError(errorMessage))
    return Promise.reject(error)
  }
)

// リクエスト処理を共通化
apiClient2.interceptors.request.use(
  config => {
    if (TOKEN) {
      config.headers.Authorization = TOKEN
      return config
    }
    return config
  },
  function(error) {
    const errorMsg = error.response.data
    crateToast('error')(errorMsg)
    return Promise.reject(error)
  }
)

// レスポンス処理を共通化
apiClient2.interceptors.response.use(
  response => {
    return camelcaseObjectDeep(response, { deep: true })
  },
  error => {
    console.error(error)
    if (error.response && error.response.data) {
      const errorMessage = error.response.data
      crateToast('error')(formatSingleError(errorMessage))
    }
    return Promise.reject(error)
  }
)

type Http = {
  login: ({ email: string, password: string }) => Promise<any>,
  masterdata: () => Promise<any>,
  createUser: Object => Promise<any>,
  createClinet: Object => Promise<any>,
  changePassword: Object => Promise<any>
}

const tmp: Object = {}

const apiDefinition = {
  masterdata: {
    method: 'get',
    url: APIS.masterdata
  },
  createUser: {
    method: 'post',
    url: APIS.users
  },
  createClinet: {
    method: 'post',
    url: APIS.clients
  },
  changePassword: {
    method: 'post',
    url: APIS.changePassword
  }
}

each(apiDefinition, (value, key) => {
  tmp[key] = params => {
    // オブジェクトキーをスネークケースに変換する
    const snakeParams = params ? snakecaseKeys(params) : {}
    return apiClient({ ...value, data: snakeParams })
  }
})

each(apiDefinition, (value, key) => {
  tmp[key] = params => {
    // オブジェクトキーをスネークケースに変換する
    const snakeParams = params ? snakecaseKeys(params) : {}
    return apiClient2({ ...value, data: snakeParams })
  }
})

export function setToken(token: string) {
  if (!token) return
  TOKEN = `JWT ${token}`
}

export function setStore(_store: Object) {
  store = _store
}

const http: Http = { ...tmp }

export default http
