/* @flow */
/* アプリの状態管理 */
import produce from 'immer'

import * as ActionTypes from '../../constants'

type State = {
  // 初期化フラグ
  initialized: boolean,
  // 前ページのLocation情報
  previousLocation: ?Object,
  // ページ全体のローディング
  loading: boolean,
  shoesItemCommonCsv: {
    upload: boolean,
    download: boolean
  },
  // ローカルナビゲーションのステート
  localNav: { shoes: number },
  // モーダルステート
  modalOpen: boolean,
  // APIが400や500エラーを返してアプリの復旧が不可能になった場合のフラグ
  kill: boolean,
  // トーストステート
  toast: {
    message: ?string,
    type: ?string,
    open: boolean
  },
  // エラー詳細ダイアログ
  errorDetail: {
    data: ?Object,
    open: boolean
  },
  menu: boolean
}

export const initialState: State = {
  initialized: false,
  previousLocation: null,
  loading: false,
  shoesItemCommonCsv: {
    upload: false,
    download: false
  },
  localNav: {
    shoes: 0
  },
  modalOpen: false,
  kill: false,
  toast: {
    message: null,
    type: 'success',
    open: false
  },
  errorDetail: {
    data: {
      title: '',
      message: {}
    },
    open: false
  },
  menu: true
}

const rootReducer = (state: State = initialState, action: Function) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.APP_INITIALIZE_SUCCESS:
        draft.initialized = true
        break
      case ActionTypes.APP_LOADING_RESUME:
        draft.loading = true
        break
      case ActionTypes.APP_LOADING_PAUSE:
        draft.loading = false
        break
      case ActionTypes.APP_PREV_LOCATION_SET:
        draft.previousLocation = action.payload
        break
      case ActionTypes.LOGIN_SUCCESS:
        draft.previousLocation = null
        break
      case ActionTypes.APP_MODAL_FORM_OPEN:
        draft.modalOpen = true
        break
      case ActionTypes.APP_MODAL_FORM_CLOSE:
        draft.modalOpen = false
        break
      case ActionTypes.APP_TOAST_SHOW:
        draft.toast.message = action.payload.message
        draft.toast.type = action.payload.type
        draft.toast.open = true
        break
      case ActionTypes.APP_TOAST_HIDE:
        draft.toast.open = false
        break
      case ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_REQUEST:
        draft.shoesItemCommonCsv.upload = true
        break
      case ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_SUCCESS:
        draft.shoesItemCommonCsv.upload = false
        break
      case ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_FAILURE:
        draft.shoesItemCommonCsv.upload = false
        break
      case ActionTypes.SHOW_DIALOG_ERROR: {
        draft.errorDetail.data = action.payload
        draft.errorDetail.open = true
        break
      }
      case ActionTypes.HIDE_DIALOG_ERROR: {
        draft.errorDetail.open = false
        break
      }
      case ActionTypes.TOGGLE_MENU: {
        draft.menu = !state.menu
        break
      }
      default:
    }
  })
}

export default rootReducer
