/* @flow */
import snakeCaseKeys from 'snakecase-keys'

import * as ActionTypes from '../constants'
import http from '../api/v1'
import { logout } from './login'
import { apiClient } from '../api/v1'
import crateToast from '../utils/createToast'

// トークンの生存確認
export function appInitialize(token: string) {
  return function(dispatch: Function, getState: Function): Promise<?Object> {
    dispatch({
      type: ActionTypes.APP_INITIALIZE_REQUEST
    })
    dispatch(appLoadingResume())
    // マスタデータのロード
    // 有効期限が切れていたらlogoutアクションを実行
    return new Promise(resolve => {
      // TOKENチェック
      http.masterdata().then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: ActionTypes.APP_INITIALIZE_SUCCESS,
            payload: response.data.data
          })
          dispatch(appLoadingPause())
          resolve()
        } else {
          dispatch({
            type: ActionTypes.APP_INITIALIZE_FAILURE,
            payload: response.data
          })
          dispatch(appLoadingPause())
          dispatch(logout())
        }
      })
    })
  }
}

export function appSetPrevLocation() {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.APP_PREV_LOCATION_SET,
      payload: getState().router.location
    })
  }
}

export function appDeletePrevLocation() {
  return {
    type: ActionTypes.APP_PREV_LOCATION_DELETE
  }
}

export function appLoadingResume() {
  return {
    type: ActionTypes.APP_LOADING_RESUME
  }
}

export function appLoadingPause() {
  return {
    type: ActionTypes.APP_LOADING_PAUSE
  }
}

export function modalOpen() {
  return {
    type: ActionTypes.APP_MODAL_FORM_OPEN
  }
}

export function modalClose() {
  return {
    type: ActionTypes.APP_MODAL_FORM_CLOSE
  }
}

export function toastShow(message: { message: string, type: string }) {
  return {
    type: ActionTypes.APP_TOAST_SHOW,
    payload: message
  }
}

export function toastHide() {
  return {
    type: ActionTypes.APP_TOAST_HIDE
  }
}

export function downloadCsv(pageName: string) {
  return function(dispatch: Function, getState: Function) {
    const params = getState().shoesData[pageName].searchParams
    apiClient({
      method: 'get',
      url: '/items/csv',
      params: snakeCaseKeys(params)
    }).then(response => {
      if (response.status === 200) {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const content = response.data
        const blob = new Blob([bom, content], { type: 'text/csv' })
        const el = document.createElement('a')
        el.download = `${pageName}.csv`
        el.href = window.URL.createObjectURL(blob)
        el.click()
      } else {
        crateToast('error')('CSVのダウンロードに失敗しました')
        console.log(response)
      }
    })
  }
}

export function closeErrorDialog() {
  return {
    type: ActionTypes.HIDE_DIALOG_ERROR
  }
}

export function toggleMenu() {
  return {
    type: ActionTypes.TOGGLE_MENU
  }
}
