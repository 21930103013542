/* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import store from 'store'

import * as actions from '../actions/shoesData'

type Props = {
  table: Object,
  location: Object,
  actions: Object,
  kill: boolean
}

const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_')

function withShoesData(pageName: string) {
  return function(WrappedComponent: React.ComponentType<any>) {
    class DecoreWithStore extends React.Component<Props> {
      // 検索条件の更新
      updateSearchCondition = (params, isPush = true, isChange = false) => {
        const { actions } = this.props
        actions.updateSearchCondition(params, pageName, isPush, isChange)
      }

      // データの取得
      loadData = () => {
        const { actions } = this.props
        actions.loadData(pageName)
      }

      handleChange = param => e => {
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ [param]: e.target.value }, true, true)
      }

      // 靴管理CSVアップロード
      handleProductCsvUpload = e => {
        const { actions } = this.props
        if (e.target.files[0]) actions.uploadProductCsv(e.target.files[0])
      }

      // SKUのCSVアップロード
      handleSkuCsvUpload = e => {
        const { actions } = this.props
        if (e.target.files[0]) actions.uploadSkuCsv(e.target.files[0])
      }

      // ブランド検索
      handleBrandsChange = value => {
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ brandIds: value })
      }

      // プロバイダー検索
      handleProvidersChange = value => {
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ providerIds: value })
      }

      // 表示ページ数の変更
      handlePageSizeChange = pageSize => {
        this.updateSearchCondition({ take: pageSize })
      }

      // ページング
      handleChangeCurrentPage = currentPage => {
        const { table } = this.props
        this.updateSearchCondition({
          skip: currentPage * table.searchParams.take
        })
      }

      // ソート
      handleChangeSorting = sorting => {
        let columnName = toSnakeCase(sorting[0].columnName)
        if (sorting[0].direction === 'desc') {
          columnName = `-${columnName}`
        }
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ orderBy: columnName })
      }

      // 検索条件をクリア
      handleClear = () => {
        const { actions } = this.props
        actions.clearConditions(pageName)
      }

      // 選択行の変更
      handleSelection = selection => {
        const { actions } = this.props
        actions.selectRows(selection, pageName)
      }

      // 行の削除
      handleDeleteRow = ({ id }) => {
        const { actions } = this.props
        actions.deleteRow(id, pageName)
      }

      // 選択したアイテムのステート変更
      handleChangeState = params => selectStatus => {
        const { actions } = this.props
        actions.removeSkip(pageName)
        actions.changeStatus(pageName, selectStatus, params)
      }

      // skipの削除
      handleRemoveSkip = () => {
        const { actions } = this.props
        actions.removeSkip(pageName)
      }

      // クライアント変更
      handleChangeClient = clientId => {
        this.updateSearchCondition({ clientId })
      }

      // 非表示カラムの管理
      handleHiddenColumnNamesChange = hiddenColumnNames => {
        const storage = store.get('hiddenColumns') || {}
        storage[pageName] = hiddenColumnNames
        store.set('hiddenColumns', storage)
      }

      // 作業ステータス
      handleWorkProcessChange = workProcessId => {
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ workProcessId })
      }

      // 公開ステータス
      handlePublicStatusChange = publicFlag => {
        this.props.actions.removeSkip(pageName)
        this.updateSearchCondition({ publicFlag })
      }

      // フィードバック更新
      handleUpdateFeedback = sendData => {
        const { actions } = this.props
        actions.feedback(sendData, pageName)
      }

      componentDidMount() {
        const queryParams = queryString.parse(window.location.search)
        // 履歴を残さない
        this.updateSearchCondition(queryParams, false)
        this.loadData()
      }

      componentDidUpdate(prevProps) {
        const { location, actions } = this.props
        if (prevProps.location.search !== location.search) {
          // const queryParams = queryString.parse(location.search)
          // // 履歴を残さない
          // this.updateSearchCondition(queryParams, false)
          this.loadData()
          actions.selectRowsClear(pageName)
        }
      }

      componentWillUnmount() {
        const { actions } = this.props
        actions.deleteLoadedData(pageName)
      }

      render() {
        return (
          <WrappedComponent
            updateSearchCondition={this.updateSearchCondition}
            loadData={this.loadData}
            handleChange={this.handleChange}
            handleBrandsChange={this.handleBrandsChange}
            handleProvidersChange={this.handleProvidersChange}
            handlePageSizeChange={this.handlePageSizeChange}
            handleChangeCurrentPage={this.handleChangeCurrentPage}
            handleChangeSorting={this.handleChangeSorting}
            handleClear={this.handleClear}
            handleSelection={this.handleSelection}
            handleDeleteRow={this.handleDeleteRow}
            handleChangeState={this.handleChangeState}
            handleChangeClient={this.handleChangeClient}
            handleHiddenColumnNamesChange={this.handleHiddenColumnNamesChange}
            handleUpdateFeedback={this.handleUpdateFeedback}
            handleProductCsvUpload={this.handleProductCsvUpload}
            handleSkuCsvUpload={this.handleSkuCsvUpload}
            handleWorkProcessChange={this.handleWorkProcessChange}
            handlePublicStatusChange={this.handlePublicStatusChange}
            handleRemoveSkip={this.handleRemoveSkip}
            {...this.props}
          />
        )
      }
    }

    const mapStateToProps = state => ({
      kill: state.common.app.kill,
      table: state.shoesData[pageName],
      location: state.router.location
    })

    const mapDispatchToProps = dispatch => ({
      actions: bindActionCreators({ ...actions }, dispatch)
    })

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(DecoreWithStore)
  }
}

export default withShoesData
