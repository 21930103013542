import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { PagingState, CustomPaging } from '@devexpress/dx-react-grid'
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui'
import styles from '../styles/EditOnModalPage.style'
import withPage from '../hocs/withPage'
import PageTitle from '../components/PageTitle'
import AppModal from './AppModal'
import * as actions from '../actions/app'
import * as pageActions from '../actions/digitizer'
import moment from 'moment'
import styled from 'styled-components'

const RowBase = (params: Object) => {
  return <Table.Row className={params.classes.row}>{params.children}</Table.Row>
}

const rowStyles = (theme: Object) => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

const StyledRow = withStyles(rowStyles)(RowBase)

const initialState = {
  digitizerCode: '',
  clientCode: '',
  mode: 'add'
}

class DigitizerPage extends Component {
  state = initialState

  handleDelete = digitizerCode => () => {
    const { pageActions } = this.props
    pageActions.onDelete(digitizerCode)
  }

  handleDetail = dcode => async () => {
    const { pageActions, actions } = this.props
    const { digitizerCode, clientCode } = await pageActions.loadData(dcode)
    this.setState({
      digitizerCode,
      clientCode,
      mode: 'edit'
    })
    actions.modalOpen()
  }

  handleAddNew = () => {
    const { actions } = this.props
    this.setState(initialState)
    actions.modalOpen()
  }

  handleChange = type => e => {
    this.setState({ [type]: e.target.value })
  }

  handleSubmit = async e => {
    const { pageActions } = this.props
    e.preventDefault()
    if (this.state.mode === 'add') {
      pageActions.register({
        digitizerCode: this.state.digitizerCode,
        clientCode: this.state.clientCode
      })
    } else {
      pageActions.put({
        digitizerCode: this.state.digitizerCode,
        clientCode: this.state.clientCode
      })
    }
    this.clearForm()
  }

  clearForm = () => {
    const { actions } = this.props
    actions.modalClose()
    this.setState(initialState)
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.modalClose()
  }

  componentDidMount() {
    const { pageActions } = this.props
    pageActions.loadClients()
    pageActions.updateSearchCondition()
  }

  render() {
    const {
      classes,
      digitizer: { columns, searchParams, totalCount, clients },
      pageActions,
      indexList
    } = this.props

    return (
      <div>
        <PageTitle title="デジタイザ管理" />
        <Grid
          container
          spacing={8}
          justify="flex-end"
          className={classes.headButton}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="bold"
              onClick={this.handleAddNew}
            >
              新規追加
            </Button>
          </Grid>
        </Grid>

        <SearchMenu>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="デジタイザコード"
                  InputLabelProps={{ shrink: true }}
                  value={searchParams.digitizerCode}
                  onChange={e => {
                    pageActions.onChange('digitizerCode', e.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel shrink>クライアントコード</InputLabel>
                  <Select
                    onChange={e => {
                      pageActions.onChange('clientCode', e.target.value)
                    }}
                    value={searchParams.clientCode ? searchParams.clientCode: ""}
                  >
                    {clients.map(c => {
                      return (
                        <MenuItem key={c.id} value={c.clientCode}>
                          {c.companyName} ({c.clientCode})
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid container item xs={12} justify="flex-end" spacing={24}>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      pageActions.updateSearchCondition()
                    }}
                  >
                    検索する
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => pageActions.searchClear()}
                  >
                    検索条件をクリア
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SearchMenu>

        <Paper>
          <div style={{ position: 'relative' }}>
            <GridTable columns={columns} rows={indexList}>
              <Table
                rowComponent={StyledRow}
                cellComponent={({ value, column: { name }, row }) => {
                  if (name === 'tool') {
                    return (
                      <Table.Cell>
                        <Button
                          className="bold"
                          color="primary"
                          size="small"
                          onClick={this.handleDetail(row.digitizerCode)}
                        >
                          設定
                        </Button>
                        <Button
                          className="bold"
                          color="primary"
                          size="small"
                          onClick={this.handleDelete(row.digitizerCode)}
                        >
                          削除
                        </Button>
                        <Button
                          href="https://snipeit.intra.mng.flicfit.com"
                          target="_blank"
                          className="bold"
                          color="primary"
                          size="small"
                        >
                          資産管理
                        </Button>
                        <Button
                          href="https://kibana-device.intra.mng.flicfit.com"
                          target="_blank"
                          className="bold"
                          color="primary"
                          size="small"
                        >
                          監視
                        </Button>
                      </Table.Cell>
                    )
                  }
                  return <Table.Cell>{value}</Table.Cell>
                }}
              />
              <TableHeaderRow />
              <PagingState
                currentPage={Math.floor(searchParams.skip / searchParams.take)}
                onCurrentPageChange={currentPage => {
                  pageActions.onPageChange(currentPage)
                }}
                pageSize={searchParams.take}
                onPageSizeChange={pageSize =>
                  pageActions.onChangePageSize(pageSize)
                }
              />
              <CustomPaging totalCount={totalCount} />
              <PagingPanel
                pageSizes={[5, 10, 15]}
                messages={{ rowsPerPage: '1ページあたりの表示数' }}
              />
            </GridTable>
          </div>
        </Paper>
        <AppModal>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="デジタイザコード"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.digitizerCode}
                  onChange={this.handleChange('digitizerCode')}
                  disabled={this.state.mode !== 'add'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel shrink>クライアントコード</InputLabel>
                  <Select
                    onChange={this.handleChange('clientCode')}
                    value={this.state.clientCode}
                  >
                    {clients.map(c => {
                      return (
                        <MenuItem key={c.id} value={c.clientCode}>
                          {c.companyName} ({c.clientCode})
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="bold"
                  type="submit"
                >
                  {this.state.mode === 'add' ? '追加する' : '編集する'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AppModal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  digitizer: state.pages.digitizer,
  indexList: state.pages.digitizer.indexList.map(o => {
    return {
      ...o,
      createdTime: moment(o.createdTime).format('YYYY/MM/DD HH:MM')
    }
  }),
  locationSearch: state.router.location.search
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  pageActions: bindActionCreators({ ...pageActions }, dispatch)
})

export default compose(
  withPage({ title: 'クライアント管理' }),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitizerPage)

const SearchMenu = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px 0;
`
