import React from 'react'
import Highlight from 'react-highlight'
import { compose, pure } from 'recompose'
import convertKeys from 'convert-keys'
import styled from 'styled-components'

function Code({ json }) {
  return (
    <Root className="github">
      {JSON.stringify(convertKeys.toSnake(json), null, '  ')}
    </Root>
  )
}

export default compose(pure)(Code)

const Root = styled(Highlight)`
  overflow: auto;
  height: 300px;
`
