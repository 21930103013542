/* @flow */
import * as React from 'react'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import { withStyles, Button } from '@material-ui/core'

import PageTitle from '../components/PageTitle'
import withPage from '../hocs/withPage'

const styles = theme => {
  return {
    button: {
      margin: '0 5px'
    }
  }
}

const TopPage = props => {
  const { classes } = props
  return (
    <div>
      <PageTitle title="ダッシュボード" />
      <dl>
        <dt>一覧系</dt>
        <dd>
          <Link to="/shoes/product">
            <Button variant="outlined" className={classes.button}>
              靴管理(品番単位)
            </Button>
          </Link>
          <Link to="/shoes/item">
            <Button variant="outlined" className={classes.button}>
              靴確認(SKU単位)
            </Button>
          </Link>
          <Link to="/shoes/public">
            <Button variant="outlined" className={classes.button}>
              靴公開(SKU単位)
            </Button>
          </Link>
          <Link to="/recommends">
            <Button variant="outlined" className={classes.button}>
              レコメンド管理
            </Button>
          </Link>
          <Link to="/clients">
            <Button variant="outlined" className={classes.button}>
              クライアント管理
            </Button>
          </Link>
          <Link to="/users">
            <Button variant="outlined" className={classes.button}>
              ユーザー管理
            </Button>
          </Link>
        </dd>
      </dl>
      <dl>
        <dt>新規作成</dt>
        <dd>
          <Link to="/shoes/product/new">
            <Button variant="outlined" className={classes.button}>
              基本情報
            </Button>
          </Link>
        </dd>
      </dl>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withPage({ title: 'ダッシュボード' })
)(TopPage)
