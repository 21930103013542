import { grey } from '@material-ui/core/colors'

export default theme => {
  return {
    header: {
      backgroundColor: '#2196f3'
    },
    inner: {
      padding: `0 ${theme.spacing.unit * 2}px`,
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      height: '50px',
      fontSize: '16px',
      '& a': {
        color: '#FFF',
        textDecoration: 'none',
        fontWeight: 'bold'
      }
    },
    rightMenu: {
      display: 'flex'
    },
    title: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginRight: '10px'
    },
    nav: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      '& a': {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        position: 'relative'
      },
      '& a.active': {
        opacity: 0.3
      }
    },
    icon: {
      color: grey[500]
    },
    accountButton: {
      padding: 0,
      minWidth: '50px'
    },
    menu: {
      fontSize: '11px',
      height: 'auto',
      fontWeight: 'bold'
    }
  }
}
