/* @flow */
import * as React from 'react'
import Select from 'react-select'
import { compose } from 'recompose'
import 'react-select/dist/react-select.css'

import {
  withStyles,
  TextField,
  Input,
  InputLabel,
  MenuItem,
  Chip,
  FormControl
} from '@material-ui/core'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 4
  }
})

const mappingSuggestions = (suggestions = []) =>
  suggestions.map(suggestion => ({
    value: suggestion.id,
    label: suggestion.name
  }))

type OptionProps = {
  option: Object,
  children: React.Node,
  isFocused: boolean,
  isSelected: boolean,
  suggestions: Array<any>,
  onSelect: (Object, SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus: () => void
}

class Option extends React.Component<OptionProps> {
  handleClick = event => {
    this.props.onSelect(this.props.option, event)
  }

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props

    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
      >
        {children}
      </MenuItem>
    )
  }
}

function SelectWrapped(props) {
  const { classes, ...other } = props

  return (
    <Select
      optionComponent={Option}
      noResultsText="検索結果がありません"
      valueComponent={valueProps => {
        const { value, children, onRemove } = valueProps
        const onDelete = event => {
          event.preventDefault()
          event.stopPropagation()
          onRemove(value)
        }
        if (onRemove) {
          return (
            <Chip
              tabIndex={-1}
              label={children}
              className={classes.chip}
              onDelete={onDelete}
            />
          )
        }

        return <div className="Select-value">{children}</div>
      }}
      {...other}
    />
  )
}

type AutoSelectProps = {
  classes: Object,
  label: string,
  value: string,
  multiple: boolean,
  suggestions: Array<Object>,
  onChange: string => void,
  required: boolean,
  disabled?: boolean
}

class AutoSelect extends React.Component<AutoSelectProps> {
  renderMulti() {
    const { classes, label, suggestions, required, disabled } = this.props
    return (
      <TextField
        required={required || false}
        fullWidth
        value={this.props.value}
        onChange={value => this.props.onChange(value)}
        placeholder=""
        label={label}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputComponent: SelectWrapped,
          inputProps: {
            classes,
            multi: true,
            simpleValue: true,
            options: mappingSuggestions(suggestions)
          }
        }}
        variant="outlined"
        margin="dense"
        disabled={disabled}
      />
    )
  }

  renderSingle() {
    const { classes, label, suggestions, required, disabled } = this.props
    return (
      <FormControl fullWidth required={required || false}>
        <InputLabel shrink={true}>{`${label}`}</InputLabel>
        <Input
          fullWidth
          inputComponent={SelectWrapped}
          value={this.props.value}
          onChange={value => this.props.onChange(value)}
          id="react-select-single"
          inputProps={{
            classes,
            name: 'react-select-single',
            instanceId: 'react-select-single',
            simpleValue: true,
            options: mappingSuggestions(suggestions)
          }}
          variant="outlined"
          margin="dense"
          disabled={disabled}
        />
      </FormControl>
    )
  }

  render() {
    const { multiple } = this.props

    return <div>{multiple ? this.renderMulti() : this.renderSingle()}</div>
  }
}

export default compose(withStyles(styles))(AutoSelect)
