/* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'

import {
  appInitialize,
  appSetPrevLocation,
  appLoadingPause
} from '../actions/app'
import crateToast from '../utils/createToast'

import createPageTitle from '../utils/createPageTitle'

type Meta = {
  // ページタイトル
  title?: string,
  // 認証が必要なページ
  auth?: boolean
}

export type Toast = {
  toastSuccess: string => void,
  toastError: string => void
}

const initialMeta = {
  title: null,
  auth: true
}

type Props = {
  routerActions: Object,
  actions: Object,
  token: string,
  initialized: boolean
}

function withPageBase(meta?: Meta) {
  const params = { ...initialMeta, ...meta }

  return function(WrappedComponent: React.ComponentType<any>) {
    class DecoreWithStore extends React.Component<Props> {
      toastSuccess(message: string) {
        crateToast('success')(message)
      }

      toastError(message: string) {
        crateToast('error')(message)
      }

      componentWillMount() {
        const { routerActions, token, actions } = this.props
        // 認証が必要なページの処理
        // トークンが無かったらloginにリダイレクト
        if (params.auth && !token) {
          actions.appSetPrevLocation()
          routerActions.push('/login')
        } else if (!params.auth) {
          // パブリックページはローディングは不要
          actions.appLoadingPause()
        }
      }

      componentDidMount() {
        const { token, actions, initialized } = this.props
        // 認証が必要なページの処理
        // 初期化されていなかったら初期化実行
        if (params.auth && !initialized) {
          actions.appInitialize(token)
        }
      }

      render() {
        const { initialized } = this.props
        if (params.auth && !initialized) {
          return <div />
        }

        return (
          <div>
            {meta &&
              meta.title && (
                <Helmet>
                  <title>{createPageTitle(meta.title)}</title>
                </Helmet>
              )}
            <WrappedComponent
              toastSuccess={this.toastSuccess}
              toastError={this.toastError}
              {...this.props}
            />
          </div>
        )
      }
    }

    const mapStateToProps = state => ({
      token: state.common.auth.token,
      initialized: state.common.app.initialized,
      masterData: state.masterData,
      loading: state.common.app.loading,
      location: state.router.location
    })

    const mapDispatchToProps = dispatch => ({
      actions: bindActionCreators(
        {
          appSetPrevLocation,
          appInitialize,
          appLoadingPause
        },
        dispatch
      ),
      routerActions: bindActionCreators(routerActions, dispatch)
    })

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(DecoreWithStore)
  }
}

export default withPageBase
