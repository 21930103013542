/* @flow */
import * as React from 'react'
import { compose } from 'recompose'

import { withStyles, Button, CircularProgress } from '@material-ui/core'

const styles = () => ({
  wrapper: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

const SubmitButton = props => {
  const { classes, loading } = props
  const buttonProps = { ...props, classes: {}, loading: {} }

  return (
    <div className={classes.wrapper}>
      <Button {...buttonProps}>{props.children}</Button>
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  )
}

export default compose(withStyles(styles))(SubmitButton)
