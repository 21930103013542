import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import 'typeface-roboto'

import {
  CssBaseline,
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

import thunkMiddleware from 'redux-thunk'
import confirmMiddleware from './middleware/confirm'
import errorMiddleware from './middleware/error'

import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import rootReducer from './reducers'
import { setToken as setTokenApi, setStore as setStoreApi } from './api/v1'
import { setStore as setStoreToast } from './utils/createToast'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700]
    }
  },
  size: {
    outerWidth: 1500
  }
})

const composeEnhancers = composeWithDevTools({})

const $root = document.getElementById('root')

const history = createHistory()

const middleweare = [
  routerMiddleware(history),
  thunkMiddleware,
  confirmMiddleware,
  errorMiddleware
]

const renderApp = (store, persistor) => {
  if ($root) {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App history={history} />
        </MuiThemeProvider>
      </Provider>,
      $root
    )
  }
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleweare))
)

const initialize = () => {
  // apiモジュールにtokenをセットする
  const token = store.getState().common.auth.token
  setTokenApi(token)
  setStoreApi(store)
  // Toastにstoreをセットする
  setStoreToast(store)
  renderApp(store)
  // ログイン時の対応
  store.subscribe(() => {
    const token = store.getState().common.auth.token
    setTokenApi(token)
  })
}

initialize()
registerServiceWorker()
