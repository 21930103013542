/* @flow */
import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableBody'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

var container
var camera, controls, scene, renderer
var THREE = window.THREE
var req = null

type Props = {
  threeUrl: ?string,
  footMeasurement: ?Object
}

class FootThreeView extends React.Component<Props> {
  componentDidMount() {
    // const width = 836
    // const height = 627
    const width = 752
    const height = 564

    const w = window.innerWidth
    const h = window.innerHeight

    const { threeUrl } = this.props

    function animate() {
      req = requestAnimationFrame(animate)
      controls.update()
      renderer.render(scene, camera)
    }

    function getNormal(currentPoint, nextPoint) {
      const vAB = nextPoint
        .clone()
        .sub(currentPoint)
        .normalize()
      const vAZ = new THREE.Vector3(0, 0, 1)

      return vAB.cross(vAZ)
    }

    function init() {
      scene = new THREE.Scene()
      scene.background = new THREE.Color(0x000000)

      camera = new THREE.PerspectiveCamera(
        30,
        window.innerWidth / window.innerHeight,
        1,
        //1000
        50000
      )
      camera.updateProjectionMatrix()
      camera.position.x = 250
      camera.position.y = 100
      camera.position.z = -350
      camera.lookAt(new THREE.Vector3(0, 0, 0))

      controls = new THREE.TrackballControls(camera)

      controls.rotateSpeed = 2.0
      controls.zoomSpeed = 2.0
      controls.panSpeed = 0.2

      controls.staticMoving = true
      controls.dynamicDampingFactor = 0.3

      controls.minDistance = 0.3
      controls.maxDistance = Infinity

      scene.add(camera)

      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(width, height)

      const threeEl = document.getElementById('three')
      if (threeEl) {
        threeEl.appendChild(renderer.domElement)
      }

      var loader

      loader = new THREE.PCDLoader()

      loader.load(threeUrl, function(mesh) {
        scene.add(mesh)
        mesh.material.size *= 200
        mesh.material.needsUpdate = true
        mesh.material.color.setHex(0x00bfff)
        var center = mesh.geometry.boundingSphere.center
        controls.target.set(center.x, center.y, center.z)
        controls.update()
      })

      container = document.createElement('div')
      const threeeEl = document.getElementById('three')
      if (threeeEl) {
        threeeEl.appendChild(container)
      }
      container.appendChild(renderer.domElement)
    }

    init()
    animate()
  }

  componentWillUnmount() {
    controls.enabled = false
    if (req) {
      cancelAnimationFrame(req)
    }
  }

  render() {
    console.log(this.props)
    const { footMeasurement } = this.props
    return (
      <div>
        <div id="three" />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>左</TableCell>
              <TableCell>{footMeasurement.left.length}</TableCell>
              <TableCell>{footMeasurement.left.width}</TableCell>
              <TableCell>{footMeasurement.left.jisSize}</TableCell>
              <TableCell>{footMeasurement.left.jisWidth}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>右</TableCell>
              <TableCell>{footMeasurement.right.length}</TableCell>
              <TableCell>{footMeasurement.right.width}</TableCell>
              <TableCell>{footMeasurement.right.jisSize}</TableCell>
              <TableCell>{footMeasurement.right.jisWidth}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default FootThreeView
