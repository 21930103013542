/* @flow */
import { push } from 'react-router-redux'
import * as ActionTypes from '../constants'
import crateToast from '../utils/createToast'
import { apiClient } from '../api/v1'

export function login(params) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'post',
        url: '/admin/login',
        data: params
      })
      resolve({ data })
    })
  }
}

export function passwordInitial(params) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'post',
        url: '/admin/users/password/initial',
        data: params
      })
      resolve({ data })
    })
  }
}

export function setToken(token) {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: token
  }
}

// ログアウト
export function logout() {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.LOGOUT
    })
    dispatch(push('/login'))
  }
}
