/* @flow */
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/shoesProductDetail'

import {
  withStyles,
  Grid,
  TextField,
  Paper,
  Button,
  List,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import styles from '../styles/FormShoesVariation.style'
import AutoSelect from '../components/AutoSelect'
import FormSizeList from './FormSizeList'
import ImageDrop from '../components/ImageDrop'

type Props = {
  masterData: Object,
  classes: Object,
  actions: Object,
  variations: Array<Object>,
  location: Object,
  common: Object
}

type State = {
  edit: boolean,
  variation: ?Object
}

const spacing = 24

class FormShoesVariation extends React.Component<Props, State> {
  state = {
    edit: false,
    variation: null
  }

  submit = e => {
    e.preventDefault()
    const { edit, variation } = this.state
    if (edit) {
      this.props.actions.updateVariation(variation)
    } else {
      this.props.actions.registerVariation(variation)
    }
  }

  update = variation => () => {
    this.setState({ edit: true, variation })
  }

  handleDrop = (files, meshId, variation) => {
    this.props.actions.uploadImage(files, meshId, variation)
  }

  register = variation => () => {
    console.log(variation)
    this.setState({ edit: false, variation })
  }

  inputChange = param => e => {
    this.props.actions.formVariationInput({ ...param, value: e.target.value })
  }

  inputMeshChange = param => value => {
    this.props.actions.formVariationInput(
      {
        ...param,
        value
      },
      'mesh'
    )
  }

  deleteVariation = variationId => () => {
    this.props.actions.formVariationDelete(variationId)
  }

  handleSwap = (dragIdx, dropIdx, mesh) => {
    const pictures = mesh.pictures.map(p => p.id)
    this.props.actions.swap(dragIdx, dropIdx, pictures, mesh.id)
  }

  handleDelete = (meshId, picName) => {
    this.props.actions.deletePicture(meshId, picName)
  }

  render() {
    const { classes, common, variations, masterData } = this.props

    if (!common.productId) {
      return <div />
    }

    return (
      <div>
        {variations.length > 0 && (
          <div className={classes.header}>
            <h2>バリエーション</h2>
          </div>
        )}
        {variations.map(variation => {
          return (
            <List key={variation.variationId}>
              <Paper
                className={classes.row}
                style={variation.variationId ? {} : { background: '#FFFFEF' }}
              >
                <form onSubmit={this.submit}>
                  <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                      <div className={classes.header}>
                        {variation.variationId && (
                          <h3>ID: {variation.variationId}</h3>
                        )}
                        {!variation.variationId && <h3>新規バリエーション</h3>}
                        <div>
                          {variation.variationId && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={`bold ${classes.buttons}`}
                              onClick={this.update(variation)}
                            >
                              更新する
                            </Button>
                          )}
                          {!variation.variationId && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={`bold ${classes.buttons}`}
                              onClick={this.register(variation)}
                            >
                              登録する
                            </Button>
                          )}
                          {variation.variationId && (
                            <IconButton
                              onClick={this.deleteVariation(
                                variation.variationId
                              )}
                            >
                              <Tooltip title="このバリエーションを削除">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={spacing}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            label="メーカーワイズ"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={variation.input.makerWidth}
                            error={!!variation.errors.makerWidth}
                            helperText={variation.errors.makerWidth}
                            onChange={this.inputChange({
                              key: 'makerWidth',
                              variationId: variation.variationId
                            })}
                            disabled={variation.variationId != null}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            label="メーカー価格(税抜)"
                            InputLabelProps={{
                              shrink: true
                            }}
                            type="number"
                            value={variation.input.price}
                            error={!!variation.errors.price}
                            helperText={variation.errors.price}
                            onChange={this.inputChange({
                              key: 'price',
                              variationId: variation.variationId
                            })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            label="メーカー色"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={variation.input.mesh.makerColor}
                            error={!!variation.errors.mesh.makerColor}
                            helperText={variation.errors.mesh.makerColor}
                            onChange={e => {
                              this.inputMeshChange({
                                key: 'makerColor',
                                variationId: variation.variationId
                              })(e.target.value)
                            }}
                            disabled={variation.variationId != null}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            label="メーカー素材"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={variation.input.mesh.makerMaterial}
                            error={!!variation.errors.mesh.makerMaterial}
                            helperText={variation.errors.mesh.makerMaterial}
                            onChange={e => {
                              this.inputMeshChange({
                                key: 'makerMaterial',
                                variationId: variation.variationId
                              })(e.target.value)
                            }}
                            disabled={variation.variationId != null}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutoSelect
                            required
                            value={variation.input.mesh.colorId}
                            label="FF色"
                            multiple={false}
                            suggestions={masterData.color}
                            onChange={value => {
                              this.inputMeshChange({
                                key: 'colorId',
                                variationId: variation.variationId
                              })(value)
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutoSelect
                            required
                            value={variation.input.mesh.materialId}
                            label="FF素材"
                            multiple={false}
                            suggestions={masterData.material}
                            onChange={value => {
                              this.inputMeshChange({
                                key: 'materialId',
                                variationId: variation.variationId
                              })(value)
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutoSelect
                            required
                            value={variation.input.mesh.patternId}
                            label="柄"
                            multiple={false}
                            suggestions={masterData.pattern}
                            onChange={value => {
                              this.inputMeshChange({
                                key: 'patternId',
                                variationId: variation.variationId
                              })(value)
                            }}
                            disabled={variation.variationId != null}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="商品URL"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={variation.input.mesh.url}
                            error={!!variation.errors.mesh.url}
                            helperText={variation.errors.mesh.url}
                            onChange={this.inputChange({
                              key: 'url',
                              variationId: variation.variationId
                            })}
                          />
                        </Grid>
                        <FormSizeList
                          variation={variation}
                          meshId={variation.input.mesh.id}
                        />
                      </Grid>
                    </Grid>
                    {/* 画像 */}
                    {variation.variationId && (
                      <ImageDrop
                        classes={classes}
                        mesh={variation.input.mesh}
                        variation={variation}
                        swap={(dragIdx, dropIdx) =>
                          this.handleSwap(
                            dragIdx,
                            dropIdx,
                            variation.input.mesh
                          )
                        }
                        handleDrop={files => {
                          this.handleDrop(
                            files,
                            variation.input.mesh.id,
                            variation
                          )
                        }}
                        handleDelete={(meshId, picName) =>
                          this.handleDelete(meshId, picName)
                        }
                      />
                    )}
                  </Grid>
                </form>
              </Paper>
            </List>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterData: state.masterData,
  common: state.pages.shoesProductDetail.formShoesCommon,
  variations: state.pages.shoesProductDetail.formShoesVariation.data,
  location: state.router.location
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FormShoesVariation)
