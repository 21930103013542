import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Icon from '@material-ui/core/Icon'

class Sidebar extends Component {
  state = {
    shoesManageMenu: false,
    accountMenu: false
  }

  toggleMenu = type => () => {
    this.setState({ [type]: !this.state[type] })
  }

  currentMenu = path => () => {
    return path === this.props.path
  }

  componentDidMount() {
    if (this.props.pathname.indexOf('shoes') >= 0) {
      this.setState({ shoesManageMenu: true })
    }
    if (
      this.props.pathname.indexOf('admin-users') >= 0 
    ) {
      this.setState({ accountMenu: true })
    }
  }

  render() {
    return (
      <Root>
        <Nav>
          <li>
            <ParentLink to="/" location={this.props.pathname}>
              <SidenavIcon>dashboard</SidenavIcon>
              ダッシュボード
            </ParentLink>
          </li>
          <li>
            <ParentLinkSpan onClick={this.toggleMenu('shoesManageMenu')}>
              <SidenavIcon>settings_applications</SidenavIcon>
              <span>靴管理</span>
              <ToggleIcon>
                {this.state.shoesManageMenu ? 'remove' : 'add'}
              </ToggleIcon>
            </ParentLinkSpan>
            {this.state.shoesManageMenu && (
              <ChildMenu>
                <li>
                  <ChildLink
                    to="/shoes/product/new"
                    location={this.props.pathname}
                  >
                    靴登録
                  </ChildLink>
                </li>
                <li>
                  <ChildLink to="/shoes/product" location={this.props.pathname}>
                    一覧
                  </ChildLink>
                </li>
                <li>
                  <ChildLink to="/shoes/item" location={this.props.pathname}>
                    靴確認
                  </ChildLink>
                </li>
                <li>
                  <ChildLink to="/shoes/public" location={this.props.pathname}>
                    靴公開
                  </ChildLink>
                </li>
                <li>
                  <ChildLink to="/master" location={this.props.pathname}>
                    マスタ管理
                  </ChildLink>
                </li>
              </ChildMenu>
            )}
          </li>
          <li>
            <ParentLink to="/clients" location={this.props.pathname}>
              <SidenavIcon>settings</SidenavIcon>クライアント管理
            </ParentLink>
          </li>
          <li>
            <ParentLink to="/digitizer" location={this.props.pathname}>
              <SidenavIcon>settings</SidenavIcon>デジタイザ管理
            </ParentLink>
          </li>
          <li>
            <ParentLinkSpan onClick={this.toggleMenu('accountMenu')}>
              <SidenavIcon>account_circle</SidenavIcon>
              <span>アカウント管理</span>
              <ToggleIcon>
                {this.state.accountMenu ? 'remove' : 'add'}
              </ToggleIcon>
            </ParentLinkSpan>
            {this.state.accountMenu && (
              <ChildMenu>
                <li>
                  <ChildLink to="/admin-users" location={this.props.pathname}>
                    管理システム
                  </ChildLink>
                </li>
              </ChildMenu>
            )}
          </li>
        </Nav>
      </Root>
    )
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname
})

export default connect(mapStateToProps)(Sidebar)

const Root = styled.div``

const SidenavIcon = styled(Icon)`
  vertical-align: middle;
  position: relative;
  margin-right: 5px;
`

const Nav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  border-top: 1px solid #eee;
  margin-top: 32px;
  > li {
    border-bottom: 1px solid #eee;
  }
`

const ParentLink = styled(Link)`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  background: ${({ to, location }) => (to === location ? '#2196f3' : '#fff')};
  color: ${({ to, location }) => (to === location ? '#fff' : '#333')};
`

const ParentLinkSpan = styled.span`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  position: relative;
  cursor: pointer;
`

const ToggleIcon = styled(Icon)`
  position: absolute;
  right: 10px;
`

const ChildMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
`

const ChildLink = styled(Link)`
  display: block;
  padding: 10px 20px 5px 50px;
  text-decoration: none;
  background: ${({ to, location }) => (to === location ? '#2196f3' : '#fff')};
  color: ${({ to, location }) => (to === location ? '#fff' : '#333')};
`
