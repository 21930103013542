/* @flow */
import queryString from 'query-string'
import { push } from 'react-router-redux'
import debounce from 'lodash/debounce'
import snakeCaseKeys from 'snakecase-keys'
import moment from 'moment'

import * as ActionTypes from '../constants'
import { convertForUrlParams, quantified } from '../utils/searchParams'
import { apiClient } from '../api/v1'
import { APIS } from '../constants'
import crateToast from '../utils/createToast'
import formatSingleError from '../utils/formatSingleError'

const debouncedFetch = debounce((dispatch, getState, pageName) => {
  const state = getState()
  const pageState = state.shoesData[pageName]
  const { searchParams } = pageState

  // 余分なパラメータを削除してURL文字列を作る
  const url = `${APIS[pageName]}?${queryString.stringify(
    snakeCaseKeys(convertForUrlParams(searchParams))
  )}`
  dispatch({ type: ActionTypes.TABLE_LOAD_DATA_REQUEST, meta: { pageName } })
  return apiClient({ method: 'get', url }).then(response => {
    if (response.data.statusCode === 200) {
      // 日付を整形する
      const tmpData = Object.assign(response.data)
      tmpData.data.forEach(obj => {
        const createdTime = moment(obj.createdTime).format('YYYY/MM/DD HH:MM')
        const updatedTime = moment(obj.updatedTime).format('YYYY/MM/DD HH:MM')
        obj.createdTime = createdTime
        obj.updatedTime = updatedTime
      })
      dispatch({
        type: ActionTypes.TABLE_LOAD_DATA_SUCCESS,
        payload: tmpData,
        meta: { pageName }
      })
    } else {
      dispatch({
        type: ActionTypes.TABLE_LOAD_DATA_FAILURE,
        payload: response.data
      })
    }
  })
}, 100)

// ロードしたデータを登録
export const loadData = (pageName: string) => (
  dispatch: Function,
  getState: Function
) => {
  debouncedFetch(dispatch, getState, pageName)
}

export function removeSkip(pageName: string) {
  return { type: ActionTypes.TABLE_SEARCH_REMOVE_SKIP, meta: { pageName } }
}

// 検索条件を更新
export function updateSearchCondition(payload: Object, pageName: string) {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.TABLE_SEARCH_CONDITION_UPDATE,
      // takeとskipがstringでくるのでnumberに変換する
      payload: quantified(payload),
      meta: { pageName }
    })
    const location = getState().router.location
    const currentLocSearch = location.search.substr(1)
    const searchParams = getState().shoesData[pageName].searchParams
    const searchParamStr = queryString.stringify(
      convertForUrlParams(searchParams)
    )
    if (searchParamStr !== currentLocSearch) {
      const url = `${location.pathname}?${searchParamStr}${location.hash}`
      dispatch(push(url))
    }
  }
}

// ロードしたデータを消去
export function deleteLoadedData(pageName: string) {
  return { type: ActionTypes.TABLE_LOAD_DATA_CLEAR, meta: { pageName } }
}

// 検索条件をクリア
export function clearConditions(pageName: string) {
  return function(dispatch: Function, getState: Function) {
    const location = getState().router.location
    dispatch({
      type: ActionTypes.TABLE_SEARCH_CONDITION_CLEAR,
      meta: { pageName }
    })
    dispatch(push(location.pathname))
  }
}

// 行を選択
export function selectRows(payload: Object, pageName: string) {
  return {
    type: ActionTypes.TABLE_ROWS_SELECT,
    meta: { pageName },
    payload
  }
}

// 行選択を解除
export function selectRowsClear(pageName: string) {
  return {
    type: ActionTypes.TABLE_ROWS_SELECT_CLEAR,
    meta: { pageName }
  }
}

// 行の削除をリクエスト
export function deleteRow(targetId: number, pageName: string) {
  return function(dispatch: Function) {
    dispatch({
      type: ActionTypes.TABLE_ROW_DELETE_REQUEST,
      payload: targetId,
      meta: {
        confirm: '本当に削除しますか？',
        callbackAction: applyDeleteRow,
        pageName
      }
    })
  }
}

// 行を削除を実行
export function applyDeleteRow(targetId: number, pageName: string) {
  return function(dispatch: Function) {
    // delete処理を実行
    let apiUrl = ''
    switch (pageName) {
      case 'shoesProduct':
        apiUrl = `products/${targetId}`
        break
      case 'shoesItem':
      case 'shoesPublic':
        apiUrl = `items/${targetId}`
        break
      case 'users':
        apiUrl = `users/${targetId}`
        break
      case 'clients':
        apiUrl = `clients/${targetId}`
        break
      default:
        throw new Error('APIを指定してください')
    }
    apiClient({ method: 'delete', url: apiUrl }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch({
          type: ActionTypes.TABLE_ROW_DELETE_SUCCESS,
          payload: targetId,
          meta: { pageName }
        })
        crateToast('success')(`ID:${targetId}を削除しました`)
        // データを取得しなおす
        dispatch(loadData(pageName))
      } else {
        dispatch({
          type: ActionTypes.TABLE_ROW_DELETE_FAILURE,
          payload: response.data
        })
      }
    })
  }
}

function applyChangeStatus(payload, pageName) {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.TABLE_LOAD_DATA_REQUEST })
    let apiUrl = ''
    if (payload.categoryName === 'workprocess') {
      apiUrl = `/items/workprocess`
    } else {
      apiUrl = `/items/publish`
    }
    apiClient({
      method: 'post',
      url: apiUrl,
      data: payload.sendData
    }).then(response => {
      if (response.data.statusCode === 200) {
        if (payload.categoryName === 'workprocess') {
          crateToast('success')('作業ステータスを変更しました')
        } else {
          crateToast('success')('公開ステータスを変更しました')
        }
        dispatch(selectRowsClear(pageName))
        dispatch(loadData(pageName))
      } else {
        dispatch({
          type: ActionTypes.TABLE_LOAD_DATA_FAILURE,
          payload: {
            message: response.data.message
          }
        })
        dispatch(selectRowsClear(pageName))
        dispatch(loadData(pageName))
      }
    })
  }
}

// アイテムのステータス変更
export function changeStatus(
  pageName: string,
  newStatus: Object,
  categoryName: string
) {
  return function(dispatch: Function, getState: Function) {
    const selections = getState().shoesData[pageName].searchSettings.selections
    const rows = getState().shoesData[pageName].searchSettings.rows
    const message = `${selections.length}件の${
      categoryName === 'workprocess' ? '作業' : 'レコメンド'
    }ステータスを${newStatus.status}に変更しますか？`
    const clientId = getState().shoesData[pageName].searchParams.clientId
    const sendData = rows
      .map((row, idx) => {
        if (categoryName === 'workprocess') {
          return snakeCaseKeys({ id: row.id, workProcessId: newStatus.id })
        }
        return snakeCaseKeys({
          itemId: row.id,
          publicFlag: newStatus.id === 'true' ? true : false,
          clientId
        })
      })
      .filter((row, idx) => {
        return selections.indexOf(idx) >= 0
      })

    dispatch({
      type: ActionTypes.TABLE_ROWS_STATUS_CHANGE_REQUEST,
      payload: {
        categoryName,
        sendData
      },
      meta: {
        confirm: message,
        callbackAction: applyChangeStatus,
        pageName
      }
    })
  }
}

// レコメンドステータスの更新
export function updateRecommendStatus(sendData: Object) {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.TABLE_ROWS_RECOMMEND_STATUS_UPDATE })
    const clientId = parseInt(
      getState().shoesData.recommends.searchParams.clientId,
      10
    )
    if (!clientId) return
    sendData.recommend = !sendData.recommend
    apiClient({
      method: 'post',
      url: '/productlists',
      data: snakeCaseKeys({ ...sendData, clientId })
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(loadData('recommends'))
      } else {
      }
    })
  }
}

// フィードバック入力の遅延実行
const debouncedFeedback = debounce((dispatch, getState, sendData, pageName) => {
  const url = `/items/${sendData.id}/feedback`
  apiClient({
    method: 'post',
    url,
    data: {
      feedback: sendData.feedback
    }
  }).then(response => {
    if (response.data.statusCode === 200) {
      dispatch({
        type: ActionTypes.TABLE_ROWS_FEEDBACK_UPDATE_SUCCESS,
        payload: sendData,
        meta: { pageName }
      })
    } else {
      dispatch({
        type: ActionTypes.TABLE_ROWS_FEEDBACK_UPDATE_FAILURE,
        payload: response.data
      })
    }
  })
}, 100)

// フィードバック
export function feedback(sendData: Object, pageName: string) {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.TABLE_ROWS_FEEDBACK_UPDATE_REQUEST,
      payload: sendData,
      meta: { pageName }
    })
    debouncedFeedback(dispatch, getState, sendData, pageName)
  }
}

// 靴管理CSVアップロード
export function uploadProductCsv(file: any) {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_REQUEST })
    const formData = new FormData()
    formData.append('csvdata', file)
    apiClient({
      method: 'post',
      url: '/products/csv',
      data: formData
    }).then(res => {
      if (res.data.statusCode === 200) {
        dispatch({ type: ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_SUCCESS })
        crateToast('error')('CSVファイルをロードしました')
        dispatch(loadData('shoesProduct'))
      } else {
        dispatch({
          type: ActionTypes.SHOW_DIALOG_ERROR,
          payload: {
            title: 'CSVファイルの内容に不備があります',
            message: res.data.message
          }
        })
        // dispatch({ type: ActionTypes.TABLE_ROWS_PRODUCT_CSV_UPLOAD_FAILURE })
        // crateToast('error')('CSVのファイル内容に不備があります')
      }
    })
  }
}

export function uploadSkuCsv(file: any) {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_REQUEST })
    const formData = new FormData()
    formData.append('csvdata', file)
    apiClient({
      method: 'post',
      url: '/products/csv',
      data: formData
    }).then(
      res => {
        if (res.data.statusCode === 200) {
          dispatch({ type: ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_SUCCESS })
          crateToast('error')('CSVファイルをロードしました')
          dispatch(loadData('shoesProduct'))
        } else {
          dispatch({ type: ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_FAILURE })
          crateToast('error')('CSVのファイル内容に不備があります')
        }
      },
      error => {
        dispatch({ type: ActionTypes.TABLE_ROWS_SKU_CSV_UPLOAD_FAILURE })
        crateToast('error')('CSVのアップロードに失敗しました')
        console.log(error)
      }
    )
  }
}
