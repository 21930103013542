/* @flow */
import React from 'react'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core'

const styles = theme => {
  const { unit } = theme.spacing
  return {
    title: {
      paddingBottom: '5px',
      borderBottom: `1px solid #E0E0E0`,
      margin: `${unit * 4}px 0`,
      fontSize: '20px'
    }
  }
}

const PageTitle = ({ classes, title }) => (
  <h2 className={classes.title}>{title}</h2>
)

export default compose(withStyles(styles))(PageTitle)
