/* @flow */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core'

import styles from '../styles/App.style'
import Toast from '../containers/Toast'
import GlobalHeader from '../containers/GlobalHeader'
import TopPage from './TopPage'
import LoginPage from '../containers/LoginPage'
import PageLoading from '../containers/PageLoading'
import ClientsPage from '../containers/ClientsPage'
import RecommendsPage from '../containers/RecommendsPage'
import MyPage from '../containers/MyPage'
import ErrorDialog from '../containers/ErrorDialog'
import MainLayout from '../containers/MainLayout'
import ShoesProductPage from '../containers/ShoesProductPage'
import ShoesProductDetailPage from '../containers/ShoesProductDetailPage'
import ShoesItemPage from '../containers/ShoesItemPage'
import ShoesItemDetailPage from '../containers/ShoesItemDetailPage'
import ShoesPublicPage from '../containers/ShoesPublicPage'
import DigitizerPage from '../containers/DigitizerPage'
import AdminUsersPage from '../containers/AdminUsersPage'
import FeetPage from '../containers/FeetPage'

const App = ({ classes, history }) => (
  <div>
    <ConnectedRouter history={history}>
      <div>
        <Toast />
        <GlobalHeader />
        <main className={classes.main}>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <MainLayout exact path="/" component={TopPage} />
            <MainLayout
              exact
              path="/shoes/product/new"
              component={ShoesProductDetailPage}
            />
            <MainLayout
              exact
              path="/shoes/product/:productId"
              component={ShoesProductDetailPage}
            />
            <MainLayout
              exact
              path="/shoes/product"
              component={ShoesProductPage}
            />
            <MainLayout exact path="/shoes/item" component={ShoesItemPage} />
            <MainLayout
              exact
              path="/shoes/item/:productId"
              component={ShoesItemDetailPage}
            />
            <MainLayout
              exact
              path="/shoes/public"
              component={ShoesPublicPage}
            />
            <MainLayout exact path="/admin-users" component={AdminUsersPage} />
            <MainLayout exact path="/digitizer" component={DigitizerPage} />
            <MainLayout path="/clients" component={ClientsPage} />
            <MainLayout path="/feet/:clientId" component={FeetPage} />
            <MainLayout path="/recommends" component={RecommendsPage} />
            <MainLayout path="/mypage" component={MyPage} />
          </Switch>
        </main>
        <PageLoading />
        <ErrorDialog />
      </div>
    </ConnectedRouter>
  </div>
)

export default compose(withStyles(styles))(App)
