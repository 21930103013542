/* @flow */
/* 靴詳細画面 */
import produce from 'immer'

import * as ActionTypes from '../../constants'

export type State = {
  data: Object,
  loading: boolean
}

export const initialState: State = {
  loading: false,
  data: {}
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.ITEM_DETAIL_FETCH_REQUEST:
        draft.loading = true
        break
      case ActionTypes.ITEM_DETAIL_FETCH_SUCCESS:
        draft.data = action.payload
        draft.loading = false
        break
      case ActionTypes.ITEM_DETAIL_FETCH_FAILURE:
        draft.loading = false
        break
      default:
    }
  })
}
