import merge from 'lodash/merge'
import ListPageStyle from './ListPage.style'

import { grey } from '@material-ui/core/colors'

const styles = theme => {
  return merge(ListPageStyle(theme), {
    table: {
      fontSize: '14px',
      width: '100%',
      marginBottom: '30px',
      '& th': {
        padding: '5px',
        background: grey[300]
      },
      '& td': {
        padding: '5px',
        background: grey[100],
        textAlign: 'center'
      }
    },
    submit: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
  })
}

export default styles
