export default ({ spacing }) => ({
  row: {
    padding: `${spacing.unit * 3}px`
  },
  buttons: {
    marginTop: `${spacing.unit * 4}px`,
    textAlign: 'center'
  },
  button: {
    margin: `${spacing.unit}px`
  },
  textarea: {
    lineHeight: 1.5
  }
})
