/* @flow */
import * as React from 'react'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import * as actions from '../actions/app'
import * as clientsActions from '../actions/clients'
import styles from '../styles/EditOnModalPage.style'
import withPage from '../hocs/withPage'
import PageTitle from '../components/PageTitle'
import AppModal from './AppModal'
import SubmitButton from '../components/SubmitButton'
import {
  PagingState,
  SortingState,
  CustomPaging
} from '@devexpress/dx-react-grid'
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui'
import crateToast from '../utils/createToast'
import moment from 'moment'
import styled from 'styled-components'
import * as FlowTypes from '../flow-types'

type Props = {
  ...FlowTypes.ShoesData,
  actions: Object
}

type State = {
  submitType: string,
  selectedId: ?string,
  errors: Object,
  digitizerCode: string,
  digitizerCodeError: string
}

const pageSizes = [5, 10, 15]

class Clients extends React.Component<Props, State> {
  state = {
    submitType: 'post',
    selectedId: null,
    errors: {},
    digitizerCode: '',
    digitizerCodeError: ''
  }

  handleAddNew = () => {
    const { actions, pageActions } = this.props
    this.setState({ submitType: 'post' })
    pageActions.initialize()
    actions.modalOpen()
  }

  handleInput = type => e => {
    this.props.pageActions.input({ type, text: e.target.value })
  }

  handleInputDigitizer = e => {
    this.setState({ digitizerCode: e.target.value })
  }

  handleRegisterDigitizer = async () => {
    await this.props.pageActions.registerDigitizer({
      id: this.state.selectedId,
      digitizerCode: this.state.digitizerCode
    })
  }

  handleDelete = id => async () => {
    this.props.pageActions.onDelete(id)
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { actions } = this.props
    let response
    if (this.state.submitType === 'post') {
      response = await this.props.pageActions.onSubmit().catch(error => {
        if (error.errors.nonFieldErrors) {
          crateToast('error')(error.errors.nonFieldErrors[0])
        } else {
          this.setState({ errors: error.errors })
        }
      })
    }
    if (response) {
      this.setState({ errors: {} })
      actions.modalClose()
    }
  }

  handleEditForm = async (id: string) => {
    const { actions, pageActions } = this.props
    pageActions.initialize()
    this.setState({ digitizerCode: '' })
    await pageActions.loadData(id)
    this.setState({ submitType: 'put', selectedId: id })
    actions.modalOpen()
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.modalClose()
  }

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    const client_code = params.get('client_code')
    if (client_code != '') {
      this.props.pageActions.onChange('clientCode', client_code)
    }
    this.props.pageActions.updateSearchCondition()
  }

  render() {
    console.log(this.props)
    const {
      classes,
      indexList,
      clients: { columns, searchParams, totalCount, input },
      pageActions
    } = this.props

    return (
      <div>
        <PageTitle title="クライアント管理" />

        <Grid
          container
          spacing={8}
          justify="flex-end"
          className={classes.headButton}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="bold"
              onClick={this.handleAddNew}
            >
              新規追加
            </Button>
          </Grid>
        </Grid>

        <SearchMenu>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="クライアントコード"
                  InputLabelProps={{ shrink: true }}
                  value={searchParams.clientCode}
                  onChange={e => {
                    pageActions.onChange('clientCode', e.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="企業名"
                  InputLabelProps={{ shrink: true }}
                  value={searchParams.companyName}
                  onChange={e => {
                    pageActions.onChange('companyName', e.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="店舗名"
                  InputLabelProps={{ shrink: true }}
                  value={searchParams.storeName}
                  onChange={e => {
                    pageActions.onChange('storeName', e.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>

              <Grid container item xs={12} justify="flex-end" spacing={24}>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      pageActions.updateSearchCondition()
                    }}
                  >
                    検索する
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    className="bold"
                    color="primary"
                    variant="contained"
                    onClick={() => pageActions.searchClear()}
                  >
                    検索条件をクリア
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SearchMenu>

        <Paper>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                {/* <TextField
                  fullWidth
                  label="クライアント検索"
                  InputLabelProps={{ shrink: true }}
                  value={searchParams.name || ''}
                  onChange={handleChange('name')}
                /> */}
              </Grid>
            </Grid>
          </div>

          <div style={{ position: 'relative' }}>
            <GridTable rows={indexList} columns={columns}>
              <SortingState
                columnExtensions={columns}
                sorting={searchParams.sorting}
                onSortingChange={sorting => {}}
              />
              <Table
                messages={{ noData: 'データがありません' }}
                cellComponent={({ value, column: { name }, row }) => {
                  if (name === 'tool') {
                    return (
                      <Table.Cell>
                        <Button
                          className="bold"
                          color="primary"
                          size="small"
                          onClick={() => this.handleEditForm(row.id)}
                        >
                          デジタイザ管理
                        </Button>
                        <Button className="bold" color="primary" size="small">
                          STORE画面
                        </Button>
                        <Button
                          className="bold"
                          color="primary"
                          size="small"
                          onClick={this.handleDelete(row.id)}
                        >
                          削除
                        </Button>
                      </Table.Cell>
                    )
                  } else if (name == 'clientCode') {
                    return (
                      <Table.Cell>
                        <Link to={'/feet/' + row.id}>{value}</Link>
                      </Table.Cell>
                    )
                  } else {
                    return <Table.Cell>{value}</Table.Cell>
                  }
                }}
              />
              <TableHeaderRow
                showSortingControls
                messages={{ sortingHint: 'ソート' }}
              />
              <PagingState
                currentPage={Math.floor(searchParams.skip / searchParams.take)}
                onCurrentPageChange={currentPage => {
                  pageActions.onPageChange(currentPage)
                }}
                pageSize={searchParams.take}
                onPageSizeChange={pageSize => {
                  pageActions.onChangePageSize(pageSize)
                }}
              />
              <CustomPaging totalCount={totalCount} />
              <PagingPanel
                pageSizes={pageSizes}
                messages={{ rowsPerPage: '1ページあたりの表示数' }}
              />
            </GridTable>
          </div>
        </Paper>
        <AppModal>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="クライアントコード"
                  InputLabelProps={{ shrink: true }}
                  value={input.clientCode}
                  onChange={this.handleInput('clientCode')}
                  maxLength={14}
                  fullWidth
                  error={this.state.errors.clientCode}
                  helperText={this.state.errors.clientCode}
                  disabled={this.state.submitType !== 'post'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="企業名"
                  InputLabelProps={{ shrink: true }}
                  value={input.companyName}
                  onChange={this.handleInput('companyName')}
                  fullWidth
                  error={this.state.errors.companyName}
                  helperText={this.state.errors.companyName}
                  disabled={this.state.submitType !== 'post'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="店舗名"
                  InputLabelProps={{ shrink: true }}
                  value={input.storeName}
                  onChange={this.handleInput('storeName')}
                  fullWidth
                  error={this.state.errors.storeName}
                  helperText={this.state.errors.storeName}
                  disabled={this.state.submitType !== 'post'}
                />
              </Grid>
              {this.state.submitType !== 'post' && (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <div className={classes.header}>
                      <p>
                        <b>デジタイザ登録</b>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={this.handleInputDigitizer}
                      value={this.state.digitizerCode}
                      label="デジタイザコード"
                      style={{ width: '550px' }}
                      helperText={this.state.digitizerCodeError}
                      error={!!this.state.digitizerCodeError}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className="bold"
                      style={{ marginLeft: '20px' }}
                      disabled={!this.state.digitizerCode}
                      onClick={this.handleRegisterDigitizer}
                    >
                      追加
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    {input.digitizerList &&
                      input.digitizerList.map(d => (
                        <List dense={true}>
                          <ListItem divider={true}>
                            <ListItemText
                              primary={d.digitizerCode}
                              secondary={moment(d.createdTime).format(
                                'YYYY/MM/DD HH:MM'
                              )}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() =>
                                  pageActions.onDeleteDigitizer({
                                    digitizerCode: d.digitizerCode,
                                    id: this.state.selectedId
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {this.state.submitType === 'post' && (
              <div className={classes.submit}>
                <SubmitButton
                  variant="contained"
                  color="primary"
                  className="bold"
                  type="submit"
                >
                  登録する
                </SubmitButton>
              </div>
            )}
          </form>
        </AppModal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.pages.clients,
  indexList: state.pages.clients.indexList.map(o => {
    return {
      ...o,
      createdTime: moment(o.createdTime).format('YYYY/MM/DD HH:MM')
    }
  }),
  digitizer: state.pages.clients.digitizer
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  pageActions: bindActionCreators({ ...clientsActions }, dispatch)
})

export default compose(
  withPage({ title: 'クライアント管理' }),
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Clients)

const SearchMenu = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px 0;
`
