/* @flow */
import produce from 'immer'
import store from 'store'
import * as ActionTypes from '../../constants'

type State = {
  token: string
}

const initialState = { token: store.get('token') || '' }

export default (state: State = initialState, action: Function) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.LOGIN_SUCCESS:
        draft.token = action.payload
        store.set('token', action.payload)
        break
      case ActionTypes.LOGOUT:
        draft.token = ''
        store.set('token', '')
        break
      default:
    }
  })
}
