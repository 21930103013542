/* @flow */
import { combineReducers } from 'redux'

import login from './login'
import shoesProduct from './shoesProduct'
import shoesItem from './shoesItem'
import shoesPublic from './shoesPublic'
import shoesProductDetail from './shoesProductDetail'
import shoesItemDetail from './shoesItemDetail'
import users from './users'
import clients from './clients'
import feet from './feet'
import mypage from './mypage'
import digitizer from './digitizer'
import adminUsers from './adminUsers'
import adminClients from './adminClients'

export default combineReducers({
  login,
  shoesProduct,
  shoesItem,
  shoesPublic,
  shoesProductDetail,
  shoesItemDetail,
  users,
  clients,
  feet,
  mypage,
  digitizer,
  adminUsers,
  adminClients
})
