/* @flow */
import { toastShow } from '../actions/app'

let store: ?Object = null

function createToast(type: string) {
  return function(message: string) {
    if (!store) return false
    store.dispatch(toastShow({ message, type }))
  }
}

export function setStore(_store: Object) {
  store = _store
}

export default createToast
