/* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
import { routerActions } from 'react-router-redux'
import withPage from '../hocs/withPage'
import * as loginActions from '../actions/login'
import LoginForm from '../components/LoginForm'
import crateToast from '../utils/createToast'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import { withStyles, Grid } from '@material-ui/core'
import PasswordInitialForm from '../components/PasswordInitialForm'
import { Redirect } from 'react-router-dom'

type Props = {
  actions: Object
}

type State = {
  session: string,
  loading: boolean,
  input: {
    email: string,
    password: string
  },
  error: {
    email: string,
    password: string
  }
}

const styles = theme => {
  const { unit } = theme.spacing
  return {
    title: {
      textAlign: 'center'
    },
    grid: {
      marginTop: `${unit * 8}px`
    },
    formControl: {
      margin: `${unit}px 0`
    },
    submit: {
      margin: `${unit * 2}px 0`,
      fontWeight: 'bold'
    }
  }
}

class LoginPage extends React.Component<Props, State> {
  state = {
    session: '',
    token: '',
    loading: false,
    input: {
      email: '',
      password: ''
    },
    error: {
      email: '',
      password: ''
    }
  }

  handleChange = (param: string) => e => {
    const state = { ...this.state }
    state.input[param] = e.target.value
    this.setState({ ...state })
  }

  handlePasswordInitial = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    const {
      input: { email, password },
      session
    } = this.state
    const {
      data: { statusCode, message, token }
    } = await this.props.actions.passwordInitial({ email, password, session })
    this.setState({ loading: false })
    if (statusCode !== 200) {
      // エラー
      if (isString(message)) {
        crateToast('error')(message)
      }
      if (message['password']) {
        crateToast('error')(message['password'][0])
      }
    } else {
      if (token) {
        const state = { ...this.state }
        state.session = ''
        state.input.password = ''
        this.setState({ ...state })
      }
    }
  }

  handleLogin = async e => {
    e.preventDefault()
    this.setState({ loading: true, error: { email: '', password: '' } })
    const {
      data: { statusCode, message, data: { jwt } }
    } = await this.props.actions.login(this.state.input)
    this.setState({ loading: false })
    if (statusCode !== 200) {
      // エラー
      if (isString(message)) {
        crateToast('error')(message)
      }
    } else {
      if (jwt) {
        // トークンを登録する
        this.props.actions.setToken(jwt)
      }
    }
  }

  render() {
    const { input, error, loading, session } = this.state
    const { token, classes } = this.props

    if (token) {
      return <Redirect to="/" />
    }

    return (
      <Grid container justify="center">
        <Grid item xs={4} className={classes.grid}>
          {session && !token ? (
            <PasswordInitialForm
              onChangeForm={this.handleChange}
              loading={loading}
              onPasswordInitial={this.handlePasswordInitial}
            />
          ) : (
            <LoginForm
              onLogin={this.handleLogin}
              input={input}
              error={error}
              onChangeForm={this.handleChange}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  token: state.common.auth.token,
  prevLocation: state.common.app.previousLocation,
  initialized: state.common.app.initialized,
  pageProps: state.pages.login
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...loginActions }, dispatch),
  routerActions: bindActionCreators(routerActions, dispatch)
})

export default compose(
  withStyles(styles),
  withPage({ title: 'ログイン', auth: false }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginPage)
