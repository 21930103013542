const styles = theme => ({
  headButton: {
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  filter: {
    padding: `${theme.spacing.unit * 3}px`,
    paddingBottom: `${theme.spacing.unit}px`
  },
  line: {
    height: '1px',
    background: '#000'
  }
})

export default styles
