import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { TextField, Button, Grid } from '@material-ui/core'
import Code from './Code'

class MeasValue extends Component {
  state = {
    json: null,
    input: null
  }

  handleChange = param => e => {
    const input = { ...this.state.input }
    input.shoeAdjustment[param] = e.target.value ? Number(e.target.value) : ''
    this.setState({ input })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.onPut(this.state.input)
  }

  handleUploadJson = e => {
    e.preventDefault()
    const file = e.target.files.item(0)
    this.props.onUploadJson(file, this.props.measurement.id)
    this.props.onClose()
  }

  componentDidMount() {
    this.setState({
      json: this.props.json,
      input: { ...this.props.json }
    })
  }

  render() {
    const { measurement } = this.props
    const canEdit = this.state.input && this.state.input.shoeAdjustment

    return (
      <Root>
        <Contents>
          <h2>measurement.id: {measurement.id}</h2>
          <Code json={this.state.json} />
          <form onSubmit={this.handleSubmit}>
            {canEdit && (
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <TextField
                    label="width​"
                    type="number"
                    value={this.state.input.shoeAdjustment.width}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('width')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="heel_height​"
                    type="number"
                    value={this.state.input.shoeAdjustment.heelHeight}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('heelHeight')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="heel_thickness​"
                    type="number"
                    value={this.state.input.shoeAdjustment.heelThickness}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('heelThickness')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="basic_thickness​"
                    type="number"
                    value={this.state.input.shoeAdjustment.basicThickness}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('basicThickness')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="toe_height​"
                    type="number"
                    value={this.state.input.shoeAdjustment.toeHeight}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('toeHeight')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="toe_thickness​"
                    type="number"
                    value={this.state.input.shoeAdjustment.toeThickness}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={this.handleChange('toeThickness')}
                    required
                  />
                </Grid>
              </Grid>
            )}
            <Buttons>
              <Button
                style={{ margin: '0 10px' }}
                color="primary"
                variant="contained"
                type="button"
                component="label"
              >
                JSON登録
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".json"
                  onChange={this.handleUploadJson}
                />
              </Button>
              <Button
                style={{ margin: '0 10px' }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={!canEdit}
              >
                更新する
              </Button>
              <Button
                onClick={this.props.onClose}
                style={{ margin: '0 10px' }}
                variant="contained"
              >
                閉じる
              </Button>
            </Buttons>
          </form>
        </Contents>
      </Root>
    )
  }
}

export default MeasValue

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const Contents = styled.div`
  width: 600px;
  min-height: 500px;
  background: #fff;
  padding: 0 30px 30px 30px;
`

const Buttons = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`
