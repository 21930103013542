/* @flow */
import React from 'react'
import compose from 'recompose/compose'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import PageTitle from '../components/PageTitle'
import { withStyles, Paper, Grid, Button, TextField } from '@material-ui/core'

import withShoesData from '../hocs/withShoesData'
import withPage from '../hocs/withPage'
import * as actions from '../actions/shoesProduct'
import styles from '../styles/ListPage.style'
import AutoSelect from '../components/AutoSelect'
import SubmitButton from '../components/SubmitButton'
import ListTable from '../components/ListTable'
import * as FlowTypes from '../flow-types'

type Props = {
  ...FlowTypes,
  classes: Object,
  actions: Object,
  loading: Object,
  table: Object
}

class ShoesProductPage extends React.PureComponent<Props> {
  handleCsvDownload = e => {
    e.preventDefault()
    this.props.actions.downloadCsv()
  }

  handleChange = (param, value) => {
    this.props.actions.onChange(param, value)
  }

  // IDを名前に変更する
  mappedRows() {
    const { masterData } = this.props
    const { rows } = this.props.table.searchSettings
    return rows.map(row => {
      const tmp = { ...row }
      // ストラップの有無
      tmp.strap = row.strap ? 'あり' : 'なし'
      // ブランド
      const brand = masterData.brand.find(b => b.id === row.brandId)
      tmp.brand = brand.name || ''
      // カテゴリー
      const category = masterData.category.find(b => b.id === row.categoryId)
      tmp.category = category.name || ''
      // 提供
      const provider = masterData.provider.find(b => b.id === row.providerId)
      tmp.provider = provider.name || ''
      // つま先形状
      const toe = masterData.toe.find(b => b.id === row.toeId)
      tmp.toe = toe.name || ''
      // ヒール形状
      const heel = masterData.heelType.find(b => b.id === row.heelTypeId)
      tmp.heelType = heel.name || ''
      // アイテム数
      tmp.items = row.items + ''
      tmp.shoelace = row.shoelace ? 'あり' : 'なし'
      return tmp
    })
  }

  render() {
    const {
      classes,
      loading,
      table,
      handlePageSizeChange,
      handleChangeCurrentPage,
      handleChangeSorting,
      handleClear,
      handleDeleteRow,
      handleHiddenColumnNamesChange,
      handleProductCsvUpload,
      masterData
    } = this.props

    return (
      <div>
        <PageTitle title="靴一覧" />
        <Grid
          className={classes.headButton}
          container
          spacing={8}
          justify="flex-end"
        >
          <Grid item>
            <Button
              className="bold"
              component={Link}
              to="/shoes/product/new"
              variant="contained"
              color="primary"
            >
              新規作成
            </Button>
          </Grid>
          <Grid item>
            <input
              accept="text/csv"
              id="raised-button-file"
              type="file"
              onChange={handleProductCsvUpload}
              onClick={event => {
                event.target.value = null
              }}
              style={{ display: 'none' }}
            />
            <label htmlFor="raised-button-file">
              <SubmitButton
                variant="contained"
                color="primary"
                className="bold"
                component="span"
                loading={loading.csvUpload}
                disabled={loading.csvUpload}
              >
                CSVアップロード
              </SubmitButton>
            </label>
          </Grid>
          <Grid item>
            <SubmitButton
              variant="contained"
              color="primary"
              className="bold"
              onClick={this.handleCsvDownload}
              loading={loading.csvDownload}
              disabled={loading.csvDownload}
            >
              CSVダウンロード
            </SubmitButton>
          </Grid>
        </Grid>

        <SearchMenu>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              {/* 品番検索 */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="品番検索"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={table.searchParams.productNumber || ''}
                  variant="outlined"
                  onChange={e =>
                    this.handleChange('productNumber', e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              {/* ブランド検索 */}
              <Grid item xs={6}>
                <AutoSelect
                  label="ブランド検索"
                  multiple={true}
                  onChange={value => this.handleChange('brandIds', value)}
                  value={table.searchParams.brandIds}
                  suggestions={masterData.brand}
                />
              </Grid>
            </Grid>

            <Grid container spacing={24} justify="space-between">
              {/* 登録日 */}
              <Grid item xs={6}>
                <Grid container xs={12} alignItems="center" item>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="登録日(From)"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={table.searchParams.createdTimeFrom || ''}
                      required
                      onChange={e =>
                        this.handleChange('createdTimeFrom', e.target.value)
                      }
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: 'center' }}>〜</div>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="登録日(To)"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={table.searchParams.createdTimeTo || ''}
                      onChange={e =>
                        this.handleChange('createdTimeTo', e.target.value)
                      }
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* 更新日 */}
              <Grid item xs={6}>
                <Grid container xs={12} alignItems="center" item>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="更新日(From)"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      margin="dense"
                      value={table.searchParams.updatedTimeFrom || ''}
                      onChange={e =>
                        this.handleChange('updatedTimeFrom', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: 'center' }}>〜</div>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="更新日(To)"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      margin="dense"
                      value={table.searchParams.updateTimeTo || ''}
                      onChange={e =>
                        this.handleChange('updateTimeTo', e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} justify="flex-end" spacing={24} item>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  className="bold"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.props.updateSearchCondition()
                  }}
                  disabled={table.loading}
                >
                  検索する
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  className="bold"
                  color="primary"
                  variant="contained"
                  onClick={handleClear}
                  disabled={table.loading}
                >
                  検索条件をクリア
                </Button>
              </Grid>
            </Grid>
          </div>
        </SearchMenu>

        <Paper>
          <ListTable
            loading={table.loading}
            searchParams={table.searchParams}
            searchSettings={table.searchSettings}
            rows={this.mappedRows()}
            changePageSize={pageSize => handlePageSizeChange(pageSize)}
            changeCurrentPage={currentPage =>
              handleChangeCurrentPage(currentPage)
            }
            changeSorting={sorting => handleChangeSorting(sorting)}
            customCell="link"
            customCellLinkTo={value => `/shoes/product/${value}`}
            deleteRow={handleDeleteRow}
            hiddenColumnNamesChange={hiddenColumnNames =>
              handleHiddenColumnNamesChange(hiddenColumnNames)
            }
          />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  page: state.pages.shoesProduct,
  loading: state.pages.shoesProduct.loading,
  masterData: state.masterData
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
})

export default compose(
  withPage(),
  withStyles(styles),
  withShoesData('shoesProduct'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShoesProductPage)

const SearchMenu = styled(Paper)`
  margin-bottom: 16px;
  padding: 12px 0;
`
