import convertKeys from 'convert-keys'
import queryString from 'query-string'
import { push } from 'react-router-redux'
import * as ActionTypes from '../constants'
import crateToast from '../utils/createToast'
import { apiClient } from '../api/v1'

export function initialize() {
  return {
    type: ActionTypes.CLIENT_INPUT_INITIALIZE
  }
}

export function input(inputParams: { type: string, text: string }) {
  return {
    type: ActionTypes.CLIENT_INPUT,
    payload: inputParams
  }
}

export function updateSearchCondition() {
  return function(dispatch, getState) {
    const location = getState().router.location
    const searchParams = getState().pages.clients.searchParams
    const searchParamStr = queryString.stringify(
      convertKeys.toSnake(searchParams)
    )
    const url = `${location.pathname}?${searchParamStr}${location.hash}`
    dispatch(push(url))
    dispatch(loadIndexData())
  }
}

export function loadIndexData() {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const location = getState().router.location
      const { data } = await apiClient({
        method: 'get',
        url: `/clients?${location.search.substr(1)}`
      })
      if (data.statusCode === 200) {
        dispatch({
          type: ActionTypes.CLIENT_INDEX_SUCCESS,
          payload: data
        })
        resolve()
      } else {
        reject()
      }
    })
  }
}

// 送信
export function onSubmit() {
  return async function(dispatch: Function, getState: Function) {
    return new Promise(async (resolve, reject) => {
      const input = getState().pages.clients.input
      const { data } = await apiClient({
        method: 'post',
        url: '/clients',
        data: convertKeys.toSnake(input)
      })
      if (data.statusCode !== 200) {
        reject({ errors: data.message })
      } else {
        crateToast('success')('クライアントを登録しました')
        dispatch(loadIndexData())
        return resolve({ ok: true })
      }
    })
  }
}

// 編集データを取得
export function loadData(id) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({ method: 'get', url: `/clients/${id}` })
      if (data.statusCode === 200) {
        dispatch({
          type: ActionTypes.CLIENT_LOAD_DATA,
          payload: data
        })
        return resolve(data)
      }
      crateToast('error')('クライアントデータが存在しません')
      return reject()
    })
  }
}

// デジタイザ登録
export function registerDigitizer(sendData) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'post',
        url: `/clients/${sendData.id}/digitizer/${sendData.digitizerCode}`
      })
      if (data.statusCode === 200) {
        dispatch(loadData(sendData.id))
        return resolve({ ok: true })
      }
      crateToast('error')(
        data.message.clientDigitizer[0] || 'デジタイザが存在しません'
      )
      return reject()
    })
  }
}

// 削除
export function onDelete(id) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.CLIENT_DELETE,
      payload: id,
      meta: {
        confirm: '本当に削除しますか？',
        callbackAction: deleteRecord
      }
    })
  }
}

export function onDeleteDigitizer(params) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.CLIENT_DELETE_DISITIZER,
      payload: params,
      meta: {
        confirm: '本当に削除しますか？',
        callbackAction: deleteDesitizer
      }
    })
  }
}

function deleteRecord(id) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'delete',
        url: `/clients/${id}`
      })
      if (data.statusCode === 200) {
        crateToast('success')('クライアントを削除しました')
        dispatch(loadIndexData())
        return resolve({ ok: true })
      }
      crateToast('error')('クライアントの削除に失敗しました')
      return reject()
    })
  }
}

function deleteDesitizer(params) {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      const { id, digitizerCode } = params
      const { data } = await apiClient({
        method: 'delete',
        url: `/clients/${id}/digitizer/${digitizerCode}`
      })
      if (data.statusCode === 200) {
        crateToast('success')('クライアントを削除しました')
        dispatch(loadData(id))
        return resolve({ ok: true })
      }
      crateToast('error')('クライアントの削除に失敗しました')
      return reject()
    })
  }
}

export function onChange(type, value) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.CLIENTS_INPUT_CHANGE,
      payload: { type, value }
    })
  }
}

export function onChangePageSize(pageSize) {
  return function(dispatch, getState) {
    console.log('pageSize:', pageSize)
    dispatch({
      type: ActionTypes.CLIENTS_UPDATE_SEARCH,
      payload: {
        take: pageSize
      }
    })
    dispatch(updateSearchCondition())
  }
}

export function onPageChange(currentPage) {
  return function(dispatch, getState) {
    const {
      pages: {
        clients: {
          searchParams: { take }
        }
      }
    } = getState()
    dispatch({
      type: ActionTypes.CLIENTS_UPDATE_SEARCH,
      payload: {
        skip: currentPage * take
      }
    })
    dispatch(updateSearchCondition())
  }
}

export function searchClear() {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.CLIENTS_SEARCH_CLEAR
    })
    dispatch(updateSearchCondition())
  }
}
