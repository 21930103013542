/* @flow */
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import common from './common'
import pages from './pages'
import shoesData from './shoesData'
import shoesProducts from './shoesProducts'
import masterData from './masterData'

export default combineReducers({
  pages,
  shoesData,
  shoesProducts,
  common,
  masterData,
  router: routerReducer
})
