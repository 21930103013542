/* @flow */
import snakeCaseKeys from 'snakecase-keys'

import { apiClient } from '../api/v1'
import crateToast from '../utils/createToast'
import * as actionTypes from '../constants'

export function downloadCsv() {
  return function(dispatch: Function, getState: Function) {
    const params = getState().shoesData.shoesProduct.searchParams
    apiClient({
      method: 'get',
      url: '/products/csv',
      params: snakeCaseKeys(params)
    }).then(response => {
      if (response.status === 200) {
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const content = response.data
        const blob = new Blob([bom, content], { type: 'text/csv' })
        const el = document.createElement('a')
        el.download = 'product.csv'
        el.href = window.URL.createObjectURL(blob)
        el.click()
      } else {
        crateToast('error')('CSVのダウンロードに失敗しました')
        console.log(response)
      }
    })
  }
}

export function onChange(param, value) {
  return {
    type: actionTypes.SHOES_PRODUCT_FORM_CHANGE,
    payload: { param, value }
  }
}
