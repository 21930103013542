import each from 'lodash/each'

export default function(errorData) {
  const { statusCode, message } = errorData
  const newMessage = {}
  each(message, (value, key) => {
    newMessage[key] = value[0]
  })
  return {
    statusCode,
    message: newMessage
  }
}
