/* @flow */
import { push } from 'react-router-redux'
import moment from 'moment'

import * as ActionTypes from '../constants'
import { apiClient } from '../api/v1'
import crateToast from '../utils/createToast'

export function fetch(itemId: number) {
  return function(dispatch: Function, getState: Function) {
    apiClient({ method: 'get', url: `/items/${itemId}` }).then(response => {
      if (response.data.statusCode === 200) {
        // 日付を整形する
        const tmpData = Object.assign(response.data)
        tmpData.createdTime = moment(tmpData.createdTime).format(
          'YYYY/MM/DD HH:MM'
        )
        tmpData.updatedTime = moment(tmpData.updatedTime).format(
          'YYYY/MM/DD HH:MM'
        )
        dispatch({
          type: ActionTypes.ITEM_DETAIL_FETCH_SUCCESS,
          payload: tmpData
        })
      } else {
        dispatch({
          type: ActionTypes.ITEM_DETAIL_FETCH_FAILURE,
          payload: response.data
        })
        crateToast('error')(`ID:${itemId}は存在しません`)
        dispatch(push('/shoes/item'))
      }
    })
  }
}
