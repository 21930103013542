/* @flow */
import confirm from '../utils/confirm'

const confirmMiddleware = (store: any) => (next: any) => (action: Function) => {
  if (!action.meta || !action.meta.confirm) {
    return next(action)
  }
  confirm(action.meta.confirm).then(
    () => {
      delete action.meta.confirm
      store.dispatch(
        action.meta.callbackAction(action.payload, action.meta.pageName)
      )
      return next(action)
    },
    () => {}
  )
}

export default confirmMiddleware
