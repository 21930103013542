/* @flow */
import produce from 'immer'

import * as ActionTypes from '../constants'

type BaseState = {
  // 品番
  productNumber: ?string,
  // ブランドID
  brandInfoId: ?string,
  // 商品名
  productName: ?string,
  // カテゴリID
  categoryCategoryId: ?string,
  // つま先
  toe: ?string,
  // ストラップの有無
  strap: ?number,
  toeId: ?number,
  // プロバイダID
  provider_id: ?number
}

const baseState: BaseState = {
  productNumber: null,
  brandInfoId: null,
  productName: null,
  categoryCategoryId: null,
  toe: null,
  strap: 0,
  toeId: 0,
  provider_id: 0
}

type InitialState = {
  loading: boolean,
  data: {
    // 新規データのIDはnew
    // 編集データのIDはproductNumberとする
    new: Object
  }
}

const initialState: InitialState = {
  loading: false,
  data: {
    // 新規データのIDはnew
    // 編集データのIDはproductNumberとする
    new: { ...baseState }
  }
}

export default (state: InitialState = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      // 靴データ新規登録/更新のリクエスト
      case ActionTypes.ADD_SHOES_PRODUCT_REQUEST:
      case ActionTypes.EDIT_SHOES_PRODUCT_REQUEST:
        draft.loading = true
        break
      // 靴データ新規登録の成功
      case ActionTypes.ADD_SHOES_PRODUCT_SUCCESS:
        draft.loading = false
        break
      // 靴データ編集の成功
      case ActionTypes.EDIT_SHOES_PRODUCT_SUCCESS:
        draft.loading = false
        break
      // 靴データ新規登録/更新の失敗
      case ActionTypes.ADD_SHOES_PRODUCT_FAILURE:
      case ActionTypes.EDIT_SHOES_PRODUCT_FAILURE:
        draft.loading = false
        break
      default:
    }
  })
}
