/* @flow */
import each from 'lodash/each'
import cloneDeep from 'lodash/cloneDeep'

export function convertForUrlParams(params: Object) {
  const newParams = cloneDeep(params)
  // 不要なパラメータの削除
  each(params, (value, key) => {
    if (!value) {
      delete newParams[key]
    }
  })
  return newParams
}

// takeとskipはnumber型にキャストする
export function quantified(params: Object) {
  each(params, (value, key) => {
    if (key === 'take' || key === 'skip') {
      params[key] = value * 1
    }
  })
  return params
}
