/* @flow */
// 文字
export const PAGE_TITLE_BASE = 'ささげ管理システム'
export const ERRORS_MSG = {
  zenkaku: '半角で入力してください',
  blank: '必須項目です',
  number: '数値で入力してください',
  email: {
    inValid: 'メールアドレスの形式が違います'
  }
}

// API
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_BASE_URL_V1 = process.env.REACT_APP_API_BASE_URL_V1
export const APIS = {
  login: '/login',
  masterdata: '/masterdata',
  shoesProduct: '/products',
  shoesItem: '/items/status',
  shoesPublic: '/items',
  users: '/users',
  recommends: '/productlists',
  changePassword: '/admin/password'
}

// Actions

/*
 * 初期化
 */
export const APP_INITIALIZE_REQUEST = 'APP_INITIALIZE_REQUEST'
export const APP_INITIALIZE_SUCCESS = 'APP_INITIALIZE_SUCCESS'
export const APP_INITIALIZE_FAILURE = 'APP_INITIALIZE_FAILURE'
// 初期化時のローディング
export const APP_LOADING_RESUME = 'APP_LOADING_START'
export const APP_LOADING_PAUSE = 'APP_LOADING_PAUSE'
// ログイン前のURLの保存/消去
export const APP_PREV_LOCATION_SET = 'APP_PREV_LOCATION_SET'
export const APP_PREV_LOCATION_DELETE = 'APP_PREV_LOCATION_DELETE'
// モーダルを開く/閉じる
export const APP_MODAL_FORM_OPEN = 'APP_MODAL_FORM_OPEN'
export const APP_MODAL_FORM_CLOSE = 'APP_MODAL_FORM_CLOSE'
// トーストメッセージ
export const APP_TOAST_SHOW = 'APP_TOAST_SHOW'
export const APP_TOAST_HIDE = 'APP_TOAST_HIDE'
// アプリを止める
export const APP_KILL = 'APP_KILL'
// CSVアップロード
export const APP_CSV_UPLOAD_REQUEST = 'APP_CSV_UPLOAD_REQUEST'
export const APP_CSV_UPLOAD_SUCCESS = 'APP_CSV_UPLOAD_SUCCESS'
export const APP_CSV_UPLOAD_FAILURE = 'APP_CSV_UPLOAD_FAILURE'
// CSVダウンロード
export const APP_CSV_DOWNLOAD_REQUEST = 'APP_CSV_DOWNLOAD_REQUEST'
export const APP_CSV_DOWNLOAD_SUCCESS = 'APP_CSV_DOWNLOAD_SUCCESS'
export const APP_CSV_DOWNLOAD_FAILURE = 'APP_CSV_DOWNLOAD_FAILURE'
export const TOGGLE_MENU = 'TOGGLE_MENU'

/*
 * ログアウト
 */
export const LOGOUT = 'LOGOUT'

/*
* マイページ
*/
export const USER_DATA_SET = 'USER_SET'

/*
 * ログインページ
 */
// フォーム入力
export const LOGIN_INPUT_MAIL = 'LOGIN_INPUT_MAIL'
export const LOGIN_INPUT_PASSWORD = 'LOGIN_INPUT_PASSWORD'
// ログイン通信
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
// パスワードの表示/非表示
export const LOGIN_SHOW_PASSWORD = 'LOGIN_SHOW_PASSWORD'
// バリデーション実行
export const LOGIN_VALIDATE_EXEC = 'LOGIN_VALIDATE_EXEC'

/*
 * 靴管理(品番単位)
 */
// CSVアップロード
export const SHOES_PRODUCT_CSV_UPLOAD_REQUEST =
  'SHOES_PRODUCT_CSV_UPLOAD_REQUEST'
export const SHOES_PRODUCT_CSV_UPLOAD_SUCCESS =
  'SHOES_PRODUCT_CSV_UPLOAD_SUCCESS'
export const SHOES_PRODUCT_CSV_UPLOAD_FAILURE =
  'SHOES_PRODUCT_CSV_UPLOAD_FAILURE'
// CSVダウンロード
export const SHOES_PRODUCT_CSV_DOWNLOAD_REQUEST =
  'SHOES_PRODUCT_CSV_DOWNLOAD_REQUEST'
export const SHOES_PRODUCT_CSV_DOWNLOAD_SUCCESS =
  'SHOES_PRODUCT_CSV_DOWNLOAD_SUCCESS'
export const SHOES_PRODUCT_CSV_DOWNLOAD_FAILURE =
  'SHOES_PRODUCT_CSV_DOWNLOAD_FAILURE'

export const SHOES_PRODUCT_FORM_CHANGE = 'SHOES_PRODUCT_FORM_CHANGE'
export const SHOES_ITEM_FORM_CHANGE = 'SHOES_ITEM_FORM_CHANGE'
export const SHOES_PUBLIC_FORM_CHANGE = 'SHOES_PUBLIC_FORM_CHANGE'

/*
 * テーブル検索
 */
// 靴データの情報取得
export const TABLE_LOAD_DATA_REQUEST = 'TABLE_LOAD_DATA_REQUEST'
export const TABLE_LOAD_DATA_SUCCESS = 'TABLE_LOAD_DATA_SUCCESS'
export const TABLE_LOAD_DATA_FAILURE = 'TABLE_LOAD_DATA_FAILURE'
export const TABLE_LOAD_DATA_CLEAR = 'TABLE_LOAD_DATA_CLEAR'
// 検索条件更新
export const TABLE_SEARCH_CONDITION_UPDATE = 'TABLE_SEARCH_CONDITION_UPDATE'
export const TABLE_SEARCH_CONDITION_CLEAR = 'TABLE_SEARCH_CONDITION_CLEAR'
export const TABLE_SEARCH_REMOVE_SKIP = 'TABLE_SEARCH_REMOVE_SKIP'
// 行の選択/解除
export const TABLE_ROWS_SELECT = 'TABLE_ROWS_SELECT'
export const TABLE_ROWS_SELECT_CLEAR = 'TABLE_ROWS_SELECT_CLEAR'
// 行の削除
export const TABLE_ROW_DELETE_REQUEST = 'TABLE_ROW_DELETE_REQUEST'
export const TABLE_ROW_DELETE_SUCCESS = 'TABLE_ROW_DELETE_SUCCESS'
export const TABLE_ROW_DELETE_FAILURE = 'TABLE_ROW_DELETE_FAILURE'
// アイテムのステータス変更
export const TABLE_ROWS_STATUS_CHANGE_REQUEST =
  'TABLE_ROWS_STATUS_CHANGE_REQUEST'
export const TABLE_ROWS_STATUS_CHANGE_SUCCESS =
  'TABLE_ROWS_STATUS_CHANGE_SUCCESS'
export const TABLE_ROWS_STATUS_CHANGE_FAILURE =
  'TABLE_ROWS_STATUS_CHANGE_FAILURE'

export const TABLE_ROWS_FEEDBACK_UPDATE_REQUEST =
  'TABLE_ROWS_FEEDBACK_UPDATE_REQUEST'
export const TABLE_ROWS_FEEDBACK_UPDATE_SUCCESS =
  'TABLE_ROWS_FEEDBACK_UPDATE_SUCCESS'
export const TABLE_ROWS_FEEDBACK_UPDATE_FAILURE =
  'TABLE_ROWS_FEEDBACK_UPDATE_FAILURE'

// レコメンドステータスの更新
export const TABLE_ROWS_RECOMMEND_STATUS_UPDATE =
  'TABLE_ROWS_RECOMMEND_STATUS_UPDATE'

// Product CSVアップロード
export const TABLE_ROWS_PRODUCT_CSV_UPLOAD_REQUEST =
  'TABLE_ROWS_PRODUCT_CSV_UPLOAD_REQUEST'
export const TABLE_ROWS_PRODUCT_CSV_UPLOAD_SUCCESS =
  'TABLE_ROWS_PRODUCT_CSV_UPLOAD_SUCCESS'
export const TABLE_ROWS_PRODUCT_CSV_UPLOAD_FAILURE =
  'TABLE_ROWS_PRODUCT_CSV_UPLOAD_FAILURE'

// SKU CSVアップロード
export const TABLE_ROWS_SKU_CSV_UPLOAD_REQUEST =
  'TABLE_ROWS_SKU_CSV_UPLOAD_REQUEST'
export const TABLE_ROWS_SKU_CSV_UPLOAD_SUCCESS =
  'TABLE_ROWS_SKU_CSV_UPLOAD_SUCCESS'
export const TABLE_ROWS_SKU_CSV_UPLOAD_FAILURE =
  'TABLE_ROWS_SKU_CSV_UPLOAD_FAILURE'

/**
 * 基本情報登録
 */
export const ADD_SHOES_PRODUCT_REQUEST = 'ADD_SHOES_PRODUCT_REQUEST'
export const ADD_SHOES_PRODUCT_SUCCESS = 'ADD_SHOES_PRODUCT_SUCCESS'
export const ADD_SHOES_PRODUCT_FAILURE = 'ADD_SHOES_PRODUCT_FAILURE'

/**
 * 基本情報更新
 */
export const EDIT_SHOES_PRODUCT_REQUEST = 'EDIT_SHOES_PRODUCT_REQUEST'
export const EDIT_SHOES_PRODUCT_SUCCESS = 'EDIT_SHOES_PRODUCT_SUCCESS'
export const EDIT_SHOES_PRODUCT_FAILURE = 'EDIT_SHOES_PRODUCT_FAILURE'

/**
 * 基本情報削除
 */
export const DELITE_SHOES_PRODUCT_REQUEST = 'DELITE_SHOES_PRODUCT_REQUEST'
export const DELITE_SHOES_PRODUCT_SUCCESS = 'DELITE_SHOES_PRODUCT_SUCCESS'
export const DELITE_SHOES_PRODUCT_FAILURE = 'DELITE_SHOES_PRODUCT_FAILURE'

/**
 * 基本情報の編集データを取得
 */
export const FETCH_SHOES_PRODUCT_REQUEST = 'FETCH_SHOES_PRODUCT_REQUEST'
export const FETCH_SHOES_PRODUCT_SUCCESS = 'FETCH_SHOES_PRODUCT_SUCCESS'
export const FETCH_SHOES_PRODUCT_FAILURE = 'FETCH_SHOES_PRODUCT_FAILURE'

/**
 * バリエーションデータ登録
 */
export const ADD_SHOES_VARIATION_REQUEST = 'ADD_SHOES_VARIATION_REQUEST'
export const ADD_SHOES_VARIATION_SUCCESS = 'ADD_SHOES_VARIATION_SUCCESS'
export const ADD_SHOES_VARIATION_FAILURE = 'ADD_SHOES_VARIATION_FAILURE'

/**
 * バリエーションデータ更新
 */
export const EDIT_SHOES_VARIATION_REQUEST = 'EDIT_SHOES_VARIATION_REQUEST'
export const EDIT_SHOES_VARIATION_SUCCESS = 'EDIT_SHOES_VARIATION_SUCCESS'
export const EDIT_SHOES_VARIATION_FAILURE = 'EDIT_SHOES_VARIATION_FAILURE'

/**
 * バリエーションデータ削除
 */
export const DELITE_SHOES_VARIATION_REQUEST = 'DELITE_SHOES_VARIATION_REQUEST'
export const DELITE_SHOES_VARIATION_SUCCESS = 'DELITE_SHOES_VARIATION_SUCCESS'
export const DELITE_SHOES_VARIATION_FAILURE = 'DELITE_SHOES_VARIATION_FAILURE'

/**
 * バリエーションデータ取得
 */
export const FETCH_SHOES_VARIATION_REQUEST = 'FETCH_SHOES_VARIATION_REQUEST'
export const FETCH_SHOES_VARIATION_SUCCESS = 'FETCH_SHOES_VARIATION_SUCCESS'
export const FETCH_SHOES_VARIATION_FAILURE = 'FETCH_SHOES_VARIATION_FAILURE'

/**
 * 商品登録
 */
export const INPUT_SHOES_SET_VALUES = 'INPUT_SHOES_SET_VALUES'
export const INPUT_SHOES_VALIDATE_EXEC = 'INPUT_SHOES_VALIDATE_EXEC'
export const INPUT_SHOES_CHANGE_VALUE = 'INPUT_SHOES_CHANGE_VALUE'
export const INPUT_SHOES_VALIDATE_EXEC_VALIATION =
  'INPUT_SHOES_VALIDATE_EXEC_VALIATION'
export const INPUT_SHOES_CHANGE_VALUE_VALIATION =
  'INPUT_SHOES_CHANGE_VALUE_VALIATION'
export const INPUT_SHOES_VALIDATE_EXEC_VALIATION_EDIT =
  'INPUT_SHOES_VALIDATE_EXEC_VALIATION_EDIT'
export const INPUT_SHOES_CHANGE_VALUE_VALIATION_EDIT =
  'INPUT_SHOES_CHANGE_VALUE_VALIATION_EDIT'
export const INPUT_SHOES_CHANGE_EDIT_MODE = 'INPUT_SHOES_CHANGE_EDIT_MODE'

/*
 * ユーザーページ
 */
export const USER_INPUT_NAME = 'USER_INPUT_NAME'
export const USER_INPUT_ORGANIZATION = 'USER_INPUT_ORGANIZATION'
export const USER_INPUT_MAIL = 'USER_INPUT_MAIL'
export const USER_INPUT_PASSWORD = 'USER_INPUT_PASSWORD'
export const USER_INPUT_PASSWORD_CONFIRM = 'USER_INPUT_PASSWORD_CONFIRM'
export const USER_CHANGE_SUPERUSER = 'USER_CHANGE_SUPERUSER'
export const USER_INPUT_OPEN = 'USER_INPUT_OPEN'
// バリデーション実行
export const USER_VALIDATE_EXEC = 'USER_VALIDATE_EXEC'
// POST通信
export const USER_INPUT_REQUEST = 'USER_INPUT_REQUEST'
export const USER_INPUT_SUCCESS = 'USER_INPUT_SUCCESS'
export const USER_INPUT_ERROR = 'USER_INPUT_ERROR'
export const USER_INPUT_FAILURE = 'USER_INPUT_FAILURE'
// 編集データ取得
export const USER_GET_REQUEST = 'USER_GET_REQUEST'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAILURE = 'USER_GET_FAILURE'
// 編集データ送信
export const USER_PUT_REQUEST = 'USER_PUT_REQUEST'
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS'
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE'
export const USER_PUT_ERROR = 'USER_PUT_ERROR'

/**
 * クライアントページ
 */
export const CLIENT_INPUT = 'CLIENT_INPUT'
export const CLIENT_INDEX_SUCCESS = 'CLIENT_INDEX_SUCCESS'
export const CLIENT_DELETE = 'CLIENT_DELETE'
export const CLIENT_LOAD_DATA = 'CLIENT_LOAD_DATA'
export const CLIENT_INPUT_INITIALIZE = 'CLIENT_INPUT_INITIALIZE'
export const CLIENT_DELETE_DISITIZER = 'CLIENT_DELETE_DISITIZER'
export const CLIENTS_INPUT_CHANGE = 'CLIENTS_INPUT_CHANGE'
export const CLIENTS_SEARCH_CLEAR = 'CLIENTS_SEARCH_CLEAR'
export const CLIENTS_UPDATE_SEARCH = 'CLIENTS_UPDATE_SEARCH'

/**
 * 足カルテリストページ
 */
export const FOOT_INDEX_SUCCESS = 'FOOT_INDEX_SUCCESS'
export const FOOT_DETAIL_SUCCESS = 'FOOT_DETAIL_SUCCESS'
export const FEET_SEARCH_CLEAR = 'FEET_SEARCH_CLEAR'
export const FEET_UPDATE_SEARCH = 'FEET_UPDATE_SEARCH'
export const FEET_INPUT_CHANGE = 'FEET_INPUT_CHANGE'

/**
 * マイページ
 */
export const MYPAGE_VALIDATE_EXEC = 'MYPAGE_VALIDATE_EXEC'
export const MYPAGE_INPUT = 'MYPAGE_INPUT'
export const MYPAGE_INPUT_REQUEST = 'MYPAGE_INPUT_REQUEST'
export const MYPAGE_INPUT_SUCCESS = 'MYPAGE_INPUT_SUCCESS'
export const MYPAGE_INPUT_ERROR = 'MYPAGE_INPUT_ERROR'
export const MYPAGE_INPUT_FAILURE = 'MYPAGE_INPUT_FAILURE'

/**
 * レコメンドページ
 */
export const RECOMMEND_FETCH_REQUEST = 'RECOMMEND_FETCH_REQUEST'
export const RECOMMEND_FETCH_SUCCESS = 'RECOMMEND_FETCH_SUCCESS'
export const RECOMMEND_FETCH_FAILURE = 'RECOMMEND_FETCH_FAILURE'

/**
 * アイテム詳細ページ
 */
export const ITEM_DETAIL_FETCH_REQUEST = 'ITEM_DETAIL_FETCH_REQUEST'
export const ITEM_DETAIL_FETCH_SUCCESS = 'ITEM_DETAIL_FETCH_SUCCESS'
export const ITEM_DETAIL_FETCH_FAILURE = 'ITEM_DETAIL_FETCH_FAILURE'

export const FORM_SHOES_ALL_CLEAR = 'FORM_SHOES_ALL_CLEAR'
export const FORM_SHOES_SET_PRODUCT_COMMON = 'FORM_SHOES_SET_PRODUCT_COMMON'
export const FORM_SHOES_DETAIL_INPUT = 'FORM_SHOES_DETAIL_INPUT'
export const FORM_SHOES_SUBMIT_DETAIL_REQUEST =
  'FORM_SHOES_SUBMIT_DETAIL_REQUEST'
export const FORM_SHOES_SUBMIT_DETAIL_SUCCESS =
  'FORM_SHOES_SUBMIT_DETAIL_SUCCESS'
export const FORM_SHOES_SUBMIT_DETAIL_ERROR = 'FORM_SHOES_SUBMIT_DETAIL_ERROR'
export const FORM_SHOES_SUBMIT_DETAIL_FAILURE =
  'FORM_SHOES_SUBMIT_DETAIL_FAILURE'

export const FORM_SHOES_FETCH_DETAIL_REQUEST = 'FORM_SHOES_FETCH_DETAIL_REQUEST'
export const FORM_SHOES_FETCH_DETAIL_SUCCESS = 'FORM_SHOES_FETCH_DETAIL_SUCCESS'
export const FORM_SHOES_FETCH_DETAIL_FAILURE = 'FORM_SHOES_FETCH_DETAIL_FAILURE'
export const FORM_SHOES_SET_INITIAL_DATA = 'FORM_SHOES_SET_INITIAL_DATA'

export const FORM_SHOES_INPUT_SIZE = 'FORM_SHOES_INPUT_SIZE'
export const FORM_SHOES_INPUT_FF_SIZE = 'FORM_SHOES_INPUT_FF_SIZE'
export const FORM_VARIATION_INPUT_CHANGE = 'FORM_VARIATION_INPUT_CHANGE'
export const FORM_VARIATION_SUBMIT_ERROR = 'FORM_VARIATION_SUBMIT_ERROR'

export const FORM_VARIATION_UPLOAD_IMG_REQUEST =
  'FORM_VARIATION_UPLOAD_IMG_REQUEST'
export const FORM_VARIATION_UPLOAD_IMG_SUCCESS =
  'FORM_VARIATION_UPLOAD_IMG_SUCCESS'
export const FORM_VARIATION_UPLOAD_IMG_FAILURE =
  'FORM_VARIATION_UPLOAD_IMG_FAILURE'

export const FORM_VARIATION_UPLOAD_THREE_REQUEST =
  'FORM_VARIATION_UPLOAD_THREE_REQUEST'
export const FORM_VARIATION_UPLOAD_THREE_SUCCESS =
  'FORM_VARIATION_UPLOAD_THREE_SUCCESS'
export const FORM_VARIATION_UPLOAD_THREE_FAILURE =
  'FORM_VARIATION_UPLOAD_THREE_FAILURE'
export const FORM_VARIATION_UPLOAD_THREE_ERROR =
  'FORM_VARIATION_UPLOAD_THREE_ERROR'

export const FORM_VARIATION_MEASUREMENT_INPUT =
  'FORM_VARIATION_MEASUREMENT_INPUT'

// ダイヤログで出すエラー
export const SHOW_DIALOG_ERROR = 'SHOW_DIALOG_ERROR'
export const HIDE_DIALOG_ERROR = 'HIDE_DIALOG_ERROR'

export const FORM_SHOES_ADD_FEATURE = 'FORM_SHOES_ADD_FEATURE'
export const FORM_SHOES_REMOVE_FEATURE = 'FORM_SHOES_REMOVE_FEATURE'

/*
 * デジタイザ
 */
export const DIGITIZER_INDEX_SUCCESS = 'DIGITIZER_INDEX_SUCCESS'
export const DIGITIZER_DELETE = 'DIGITIZER_DELETE'
export const DIGITIZER_UPDATE_SEARCH = 'DIGITIZER_UPDATE_SEARCH'
export const DIGITIZER_CLIENTS_FETCH = 'DIGITIZER_CLIENTS_FETCH'
export const DIGITIZER_CLIENTS_CHANGE = 'DIGITIZER_CLIENTS_CHANGE'
export const DIGITIZER_CLIENTS_SEARCH_CLEAR = 'DIGITIZER_CLIENTS_SEARCH_CLEAR'

/*
 * 管理者
 */
export const ADMIN_USERS_INDEX_REQUEST = 'ADMIN_USERS_INDEX_REQUEST'
export const ADMIN_USERS_INDEX_SUCCESS = 'ADMIN_USERS_INDEX_SUCCESS'
export const ADMIN_USERS_INDEX_FAILURE = 'ADMIN_USERS_INDEX_FAILURE'
export const ADMIN_USERS_DELETE = 'ADMIN_USERS_DELETE'
export const ADMIN_USERS_UPDATE_SEARCH = 'ADMIN_USERS_UPDATE_SEARCH'

export const ADMIN_CLIENTS_INDEX_REQUEST = 'ADMIN_CLIENTS_INDEX_REQUEST'
export const ADMIN_CLIENTS_INDEX_SUCCESS = 'ADMIN_CLIENTS_INDEX_SUCCESS'
export const ADMIN_CLIENTS_INDEX_FAILURE = 'ADMIN_CLIENTS_INDEX_FAILURE'
export const ADMIN_CLIENTS_DELETE = 'ADMIN_CLIENTS_DELETE'
export const ADMIN_CLIENTS_UPDATE_SEARCH = 'ADMIN_CLIENTS_UPDATE_SEARCH'
export const ADMIN_CLIENTS_CLIENTS_FETCH = 'ADMIN_CLIENTS_CLIENTS_FETCH'

export const RESET_SKIP = 'RESET_SKIP'
