/* @flow */
import * as React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import each from 'lodash/each'

import { withStyles, Paper, Grid, TextField } from '@material-ui/core'

import * as FlowTypes from '../flow-types'
import * as actions from '../actions/shoesData'
import * as appActions from '../actions/app'
import styles from '../styles/EditOnModalPage.style'
import withPage from '../hocs/withPage'
import withShoesData from '../hocs/withShoesData'
import PageTitle from '../components/PageTitle'
import AutoSelect from '../components/AutoSelect'
import ListTable from '../components/ListTable'

type Props = { ...FlowTypes.ShoesData, actions: Object }

class Clients extends React.Component<Props> {
  handleFeedback = (sendData: Object) => {
    const { appActions } = this.props
    appActions.feedback(sendData)
  }

  handleSwitch = (sendData: Object) => {
    const { actions } = this.props
    actions.updateRecommendStatus(sendData)
  }

  mappedRows() {
    const { masterData } = this.props
    const { rows } = this.props.table.searchSettings
    return rows.map(row => {
      const tmp: Object = {
        id: row.id,
        productlistId: row.productlistId,
        productNumber: row.product.productNumber,
        brand: null,
        category: null,
        productName: row.product.productName,
        provider: null,
        toe: null,
        heelType: null,
        heelHeight: row.product.heelHeight,
        strap: null,
        targetName: row.product.targetName,
        makerColor: row.mesh.makerColor,
        makerMaterial: row.mesh.makerMaterial,
        ffColor: row.mesh.ffColor,
        ffMaterial: row.mesh.ffMaterial,
        makerSize: row.measurement.makerSize,
        makerWidth: row.measurement.makerWidth,
        ffSize: row.measurement.ffSize,
        ffWidth: row.measurement.ffWidth,
        pictures: row.mesh.pictures.length,
        jancode: row.jancode,
        comment: row.product.comment,
        feedback: row.feedback,
        createdTime: row.createdTime,
        updatedTime: row.updatedTime
      }
      // ブランド
      const brand = masterData.brand.find(b => b.id === row.product.brandId)
      tmp.brand = brand ? brand.name : ''
      // カテゴリー
      const category = masterData.category.find(
        b => b.id === row.product.categoryId
      )
      tmp.category = category ? category.name : ''
      // 提供
      const provider = masterData.provider.find(
        b => b.id === row.product.providerId
      )
      tmp.provider = provider ? provider.name : ''
      // ストラップの有無
      tmp.strap = row.product.strap ? 'あり' : 'なし'
      // つま先形状
      const toe = masterData.toe.find(b => b.id === row.product.toeId)
      tmp.toe = toe.name || ''
      // ヒール形状
      const heel = masterData.heelType.find(
        b => b.id === row.product.heelTypeId
      )
      tmp.heelType = heel ? heel.name : ''
      // 0がboolean判定されてしまうので文字でキャスト
      each(tmp, (value, key) => {
        if (value === 0) {
          tmp[key] = value + ''
        }
      })
      return tmp
    })
  }

  render() {
    const {
      classes,
      masterData,
      table,
      handleChange,
      handleChangeClient,
      handleBrandsChange,
      handleProvidersChange,
      handlePageSizeChange,
      handleChangeCurrentPage,
      handleChangeSorting,
      handleHiddenColumnNamesChange
    } = this.props

    return (
      <div>
        <PageTitle title="レコメンド管理" />

        <Paper>
          <div className={classes.filter}>
            <Grid container spacing={24}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="品番検索"
                  InputLabelProps={{ shrink: true }}
                  value={table.searchParams.productNumber || ''}
                  onChange={handleChange('productNumber')}
                />
              </Grid>
              <Grid item xs={4}>
                <AutoSelect
                  label="クライアントを選択"
                  onChange={handleChangeClient}
                  value={table.searchParams.clientId}
                  suggestions={masterData.client}
                />
              </Grid>
              <Grid item xs={4}>
                <AutoSelect
                  label="ブランド検索"
                  multiple={true}
                  onChange={value => handleBrandsChange(value)}
                  value={table.searchParams.brandIds}
                  suggestions={masterData.brand}
                />
              </Grid>
              <Grid item xs={8}>
                <AutoSelect
                  label="プロバイダー検索"
                  multiple={true}
                  onChange={value => handleProvidersChange(value)}
                  value={table.searchParams.providerIds}
                  suggestions={masterData.provider}
                />
              </Grid>
            </Grid>
          </div>
          <ListTable
            loading={table.loading}
            searchParams={table.searchParams}
            searchSettings={table.searchSettings}
            changePageSize={pageSize => handlePageSizeChange(pageSize)}
            changeCurrentPage={currentPage =>
              handleChangeCurrentPage(currentPage)
            }
            changeSorting={sorting => handleChangeSorting(sorting)}
            rows={this.mappedRows()}
            customCell="link"
            customCellLinkTo={value => `/shoes/item/${value}`}
            feedbackHandler={this.handleFeedback}
            switchHandler={this.handleSwitch}
            hiddenColumnNamesChange={hiddenColumnNames =>
              handleHiddenColumnNamesChange(hiddenColumnNames)
            }
          />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterData: state.masterData
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default compose(
  withPage({ title: 'レコメンド管理' }),
  withStyles(styles),
  withShoesData('recommends'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Clients)
