import convertKeys from 'convert-keys'
import queryString from 'query-string'
import { push } from 'react-router-redux'
import * as ActionTypes from '../constants'
import crateToast from '../utils/createToast'
import { apiClient } from '../api/v1'

export function updateSearchCondition(id: number) {
  return function(dispatch, getState) {
    const location = getState().router.location
    const searchParams = getState().pages.feet.searchParams
    const searchParamStr = queryString.stringify(
      convertKeys.toSnake(searchParams)
    )
    const url = `${location.pathname}?${searchParamStr}${location.hash}`
    dispatch(push(url))
    dispatch(loadIndexData(id))
  }
}

export function loadIndexData(id: number) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const location = getState().router.location
      const { data } = await apiClient({
        method: 'get',
        url: `/clients/${id}/feet?${location.search.substr(1)}`
      })
      if (data.statusCode === 200) {
        dispatch({
          type: ActionTypes.FOOT_INDEX_SUCCESS,
          payload: data
        })
        resolve()
      } else {
        reject()
      }
    })
  }
}

export function getFootData(footUuid: string) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const location = getState().router.location
      const { data } = await apiClient({
        method: 'get',
        url: `/foot/${footUuid}`
      })
      if (data.statusCode === 200) {
        resolve(data.data)
      } else {
        reject()
      }
    })
  }
}

export function onChange(type, value) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.FEET_INPUT_CHANGE,
      payload: { type, value }
    })
  }
}

export function onChangePageSize(pageSize, id: number) {
  return function(dispatch, getState) {
    console.log('pageSize:', pageSize)
    dispatch({
      type: ActionTypes.CLIENTS_UPDATE_SEARCH,
      payload: {
        take: pageSize
      }
    })
    dispatch(updateSearchCondition(id))
  }
}

export function onPageChange(currentPage, id: number) {
  return function(dispatch, getState) {
    const {
      pages: {
        feet: {
          searchParams: { take }
        }
      }
    } = getState()
    dispatch({
      type: ActionTypes.CLIENTS_UPDATE_SEARCH,
      payload: {
        skip: currentPage * take
      }
    })
    dispatch(updateSearchCondition(id))
  }
}

export function searchClear(id: number) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.FEET_SEARCH_CLEAR
    })
    dispatch(updateSearchCondition(id))
  }
}
