/* @flow */
import { push } from 'react-router-redux'
import path from 'path'
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import convertKeys from 'convert-keys'
import each from 'lodash/each'
import * as ActionTypes from '../constants'
import crateToast from '../utils/createToast'
import { apiClient, apiClient2 } from '../api/v1'
import { API_BASE_URL, API_BASE_URL_V1 } from '../constants'
import formatValidationError from '../utils/formatValidationError'
import formatSingleError from '../utils/formatSingleError'

// プロダクトIDの登録
export function setProductId(id: string) {
  return {
    type: ActionTypes.FORM_SHOES_SET_PRODUCT_COMMON,
    payload: {
      id
    }
  }
}

export function setCommonData(settings: Object) {
  return {
    type: ActionTypes.FORM_SHOES_SET_PRODUCT_COMMON,
    payload: settings
  }
}

// フォーム入力
export function inputFormDetail(key: string, value: string) {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.FORM_SHOES_DETAIL_INPUT,
      payload: { key, value }
    })
  }
}

// 基本情報の登録
export function postFormDetail() {
  return function(dispatch: Function, getState: Function) {
    const input = getState().pages.shoesProductDetail.formShoesDetail.input
    dispatch({ type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_REQUEST })
    apiClient({
      method: 'post',
      url: '/products',
      data: convertKeys.toSnake(input)
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(setCommonData(response.data))
        dispatch({
          type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_SUCCESS,
          payload: response.data.id
        })
        crateToast('success')(response.data.message)
        // 編集画面へリダイレクト
        dispatch(push(`/shoes/product/${response.data.id}`))
        dispatch(fetchDetail(response.data.id))
      } else {
        dispatch({
          type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_ERROR,
          payload: formatValidationError(response.data)
        })
      }
    })
  }
}

// 基本情報の更新
export function putFormDetail() {
  return function(dispatch: Function, getState: Function) {
    const input = getState().pages.shoesProductDetail.formShoesDetail.input
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    dispatch({ type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_REQUEST })
    apiClient({
      method: 'put',
      url: `/products/${productId}`,
      data: convertKeys.toSnake(input)
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(fetchDetail(productId))
        crateToast('success')('基本情報を更新しました')
      } else if (response.data.statusCode === 400) {
        crateToast('error')(
          '基本情報を更新できませんでした' +
            `(${response.data.message.product.join(' ')})`
        )
        dispatch({
          type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_ERROR,
          payload: formatValidationError(response.data)
        })
      } else {
        dispatch({
          type: ActionTypes.FORM_SHOES_SUBMIT_DETAIL_ERROR,
          payload: formatValidationError(response.data)
        })
      }
    })
  }
}

// 詳細情報の削除
export function deleteFormDetail() {
  return function(dispatch: Function, getState: Function) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    apiClient({
      method: 'delete',
      url: `/products/${productId}`
    }).then(response => {
      if (response.data.statusCode === 200) {
        crateToast('success')('削除しました')
        dispatch(formReset())
        // 一覧画面へリダイレクト
        dispatch(push('/shoes/product'))
      } else if (response.data.statusCode === 400) {
        let newMessages = []
        each(response.data.message, (value, key) => {
          newMessages.push(value[0])
        })
        crateToast('error')(
          `詳細情報の削除に失敗しました (${newMessages.join(' ')})`
        )
      } else {
        crateToast('error')('詳細情報の削除に失敗しました')
      }
    })
  }
}

// 詳細情報の取得
export function fetchDetail(productId: string) {
  return function(dispatch: Function, getState: Function) {
    dispatch({ type: ActionTypes.FORM_SHOES_FETCH_DETAIL_REQUEST })
    apiClient({
      method: 'get',
      url: `/products/${productId}`
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(setCommonData(response.data))
        dispatch({
          type: ActionTypes.FORM_SHOES_FETCH_DETAIL_SUCCESS,
          payload: response.data
        })
      } else {
        crateToast('error')(formatSingleError(response.data.message))
        // 一覧画面へリダイレクト
        dispatch(push('/shoes/product'))
      }
    })
  }
}

// フォームのリセット
export function formReset() {
  return { type: ActionTypes.FORM_SHOES_ALL_CLEAR }
}

// サイズの新規入力
export function inputSize(inputParams: Object) {
  return {
    type: ActionTypes.FORM_SHOES_INPUT_SIZE,
    payload: inputParams
  }
}

export function inputFFSize(inputParams: Object) {
  return {
    type: ActionTypes.FORM_SHOES_INPUT_FF_SIZE,
    payload: inputParams
  }
}

// サイズの新規登録
export function registerSize(submitIds: Object) {
  return function(dispatch: Function, getState: Function) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    const variations = getState().pages.shoesProductDetail.formShoesVariation
      .data
    const { meshId, variationId, makerWidth } = submitIds
    const input = variations.find(obj => obj.variationId === variationId)
      .newSize.input
    return apiClient({
      method: 'post',
      url: `/products/${productId}/measurements`,
      data: snakecaseKeys({ ...input, meshId, makerWidth })
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(fetchDetail(productId))
        crateToast('success')('サイズを追加しました')
      } else {
        console.error(response.data)
        crateToast('error')('サイズの登録に失敗しました')
      }
    })
  }
}

// バリエーションの入力
export function formVariationInput(obj: Object, type: string = 'default') {
  return {
    type: ActionTypes.FORM_VARIATION_INPUT_CHANGE,
    payload: obj,
    meta: { type }
  }
}

// バリエーションの登録
export function registerVariation(variation: Object) {
  return function(dispatch: Function, getState: Function) {
    const { input } = variation
    const { mesh } = input
    const submitData = {
      makerColor: mesh.makerColor,
      makerMaterial: mesh.makerMaterial,
      colorId: mesh.colorId,
      materialId: mesh.materialId,
      makerWidth: input.makerWidth,
      price: input.price,
      patternId: mesh.patternId,
      url: input.mesh.url
    }
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    apiClient({
      method: 'post',
      url: `/products/${productId}/variations`,
      data: snakecaseKeys(submitData)
    }).then(response => {
      const { data } = response
      if (data.statusCode === 200) {
        dispatch(fetchDetail(productId))
        crateToast('success')('バリエーションを登録しました')
      } else if (data.statusCode === 400) {
        console.error(data)
        // crateToast('error')(
        //   'バリエーションを登録に失敗しました' +
        //     `(${data.message.variations.join(' ')})`
        // )
        dispatch({
          type: ActionTypes.FORM_VARIATION_SUBMIT_ERROR,
          payload: {
            variationId: null,
            response: formatValidationError(response.data)
          }
        })
      } else {
        dispatch({
          type: ActionTypes.FORM_VARIATION_SUBMIT_ERROR,
          payload: {
            variationId: null,
            response: formatValidationError(response.data)
          }
        })
      }
    })
  }
}

// バリエーションの更新
export function updateVariation(variation: Object) {
  return function(dispatch: Function, getState: Function) {
    const { input } = variation
    const submitData = {
      colorId: input.mesh.colorId,
      materialId: input.mesh.materialId,
      price: input.price
    }
    apiClient({
      method: 'put',
      url: `/variations/${variation.variationId}`,
      data: snakecaseKeys(submitData)
    }).then(response => {
      const { data } = response
      if (data.statusCode === 200) {
        const productId = getState().pages.shoesProductDetail.formShoesCommon
          .productId
        dispatch(fetchDetail(productId))
        crateToast('success')('バリエーションを更新しました')
      } else if (data.statusCode === 400) {
        console.error(data)
        crateToast('error')('バリエーションの更新に失敗しました')
      } else {
        dispatch({
          type: ActionTypes.FORM_VARIATION_SUBMIT_ERROR,
          payload: {
            variationId: variation.variationId,
            response: formatValidationError(response.data)
          }
        })
      }
    })
  }
}

// バリエーション削除
export function formVariationDelete(variationId: string) {
  return function(dispatch: Function, getState: Function) {
    apiClient({
      method: 'delete',
      url: `/variations/${variationId}`
    }).then(response => {
      const { data } = response
      if (data.statusCode === 200) {
        const productId = getState().pages.shoesProductDetail.formShoesCommon
          .productId
        dispatch(fetchDetail(productId))
        crateToast('success')('バリエーションを削除しました')
      } else {
        crateToast('error')(formatSingleError(response.data.message))
      }
    })
  }
}

// 画像登録
export function uploadImage(files: Object, meshId: string, variation: Object) {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.FORM_VARIATION_UPLOAD_IMG_REQUEST,
      payload: { variationId: variation.variationId }
    })
    apiClient2.defaults.headers['Content-Type'] = 'application/octet-stream'
    const promises = []
    files.forEach((file, idx) => {
      const extname = path.extname(file.name)
      const date = Date.now() + idx
      const ajax = apiClient2({
        baseURL : API_BASE_URL_V1,
        method: 'post',
        url: `/shoe/picture/${meshId}/${date}${extname}`,
        data: file
      })
      promises.push(ajax)
    })
    Promise.all(promises).then(responses => {
      const productId = getState().pages.shoesProductDetail.formShoesCommon
        .productId
      if (responses[0].data.statusCode === 200) {
        dispatch({
          type: ActionTypes.FORM_VARIATION_UPLOAD_IMG_SUCCESS,
          payload: { variationId: variation.variationId }
        })
        crateToast('success')('画像を登録しました')
      } else {
        dispatch({
          type: ActionTypes.FORM_VARIATION_UPLOAD_IMG_FAILURE,
          payload: { variationId: variation.variationId }
        })
        crateToast('error')(
          '画像の登録に失敗しました' +
            (responses[0].data.message.picture
              ? '(' + responses[0].data.message.picture[0] + ')'
              : '')
        )
      }
      dispatch(fetchDetail(productId))
    })
  }
}

function getThreeFileUrl(datetime: number, ext: string, measurementId: string) {
  const base = `${String(API_BASE_URL_V1)}/shoe/measurement`
  if (ext.indexOf('csv') >= 0) {
    return `${base}/${measurementId}/${datetime}_noise_reduction.pcd`
  } else {
    return `${base}/${measurementId}/${datetime}.stl`
  }
}

// サイズの削除
export function deleteSize(measurementId: number, meshId: string) {
  return function(dispatch: Function, getState: Function) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    const url = `/products/measurements/${measurementId}`
    apiClient({
      method: 'delete',
      url
    }).then(response => {
      if (response.data.statusCode === 200) {
        dispatch(fetchDetail(productId))
        crateToast('success')('サイズを削除しました')
      } else if (response.data.statusCode === 400) {
        let newMessages = []
        each(response.data.message, (value, key) => {
          newMessages.push(value[0])
        })
        crateToast('error')(
          `サイズの削除に失敗しました (${newMessages.join(' ')})`
        )
      } else {
        crateToast('error')(response.data.message.measurement[0])
      }
    })
  }
}

export function deletePicture(meshId: string, picName: string) {
  return function(dispatch: Function, getState: Function) {
    apiClient2.defaults.headers['Content-Type'] = 'application/octet-stream'
    apiClient2({
      method: 'delete',
      url: `/meshes/picture/${meshId}/${picName}`
    }).then(response => {
      if (response.data.statusCode === 200) {
        const productId = getState().pages.shoesProductDetail.formShoesCommon
          .productId
        dispatch(fetchDetail(productId))
      } else if (response.data.statusCode === 400) {
        let newMessages = []
        each(response.data.message, (value, key) => {
          newMessages.push(value[0])
        })
        crateToast('error')(
          `画像の削除に失敗しました (${newMessages.join(' ')})`
        )
      } else {
        crateToast('error')('画像の削除に失敗しました')
      }
    })
  }
}

// 順番の入れ替え
export function swap(
  dragIdx: number,
  dropIdx: number,
  pictures: Array<any>,
  meshId: string
) {
  return function(dispatch: Function, getState: Function) {
    const tmpArr = Object.assign(pictures)
    const dragItem = pictures[dragIdx]
    const dropItem = pictures[dropIdx]
    tmpArr[dropIdx] = dragItem
    tmpArr[dragIdx] = dropItem
    const order = tmpArr.map((pid, idx) => {
      return {
        id: pid,
        order: idx
      }
    })
    apiClient({
      url: `/meshes/${meshId}/pictures/order`,
      method: 'post',
      data: order
    }).then(response => {
      if (response.data.statusCode === 200) {
        const productId = getState().pages.shoesProductDetail.formShoesCommon
          .productId
        dispatch(fetchDetail(productId))
      } else if (response.data.statusCode === 400) {
        let newMessages = []
        each(response.data.message, (value, key) => {
          newMessages.push(value[0])
        })
        crateToast('error')(`並べ替えに失敗しました (${newMessages.join(' ')})`)
      } else {
        crateToast('error')('並べ替えに失敗しました')
      }
    })
  }
}

// 新規フォームに初期データをセット
export function setInitialData() {
  return function(dispatch: any, getState: any) {
    const masterData = getState().masterData
    const updateData = {
      brandId: masterData.brand[0].id,
      categoryId: masterData.category[0].id,
      toeId: masterData.toe[0].id,
      heelTypeId: masterData.heelType[0].id,
      providerId: masterData.provider[0].id,
      targetId: masterData.target[0].id
    }
    dispatch({
      type: ActionTypes.FORM_SHOES_SET_INITIAL_DATA,
      payload: updateData
    })
  }
}

// measurement datainput form
export function inputFormMeasurement(
  index: integer,
  key: string,
  value: Object
) {
  return function(dispatch: Function, getState: Function) {
    dispatch({
      type: ActionTypes.FORM_VARIATION_MEASUREMENT_INPUT,
      payload: { index, key, value }
    })
  }
}

// measurement Update
export function updateMeasurement() {
  return function(dispatch: Function, getState: Function) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    const measurements = getState().pages.shoesProductDetail.formmeasurementList
      .data
    const setData = measurements.map(v => {
      return {
        measurement_id: v.id,
        ff_width_offset: parseInt(v.ffWidthOffset),
        ff_size_offset: parseInt(v.ffSizeOffset)
      }
    })

    apiClient({
      method: 'put',
      url: `/products/${productId}/measurements`,
      data: setData
    }).then(response => {
      const { data } = response
      if (data.statusCode === 200) {
        dispatch(fetchDetail(productId))
        crateToast('success')('補正値を設定しました')
      } else if (response.data.statusCode === 400) {
        let newMessages = []
        each(response.data.message, (value, key) => {
          newMessages.push(value[0])
        })
        crateToast('error')(
          `補正値の設定に失敗しました (${newMessages.join(' ')})`
        )
      } else {
        crateToast('error')('補正値の設定に失敗しました')
      }
    })
  }
}

// 属性の追加
export function addFeature(addData) {
  return {
    type: ActionTypes.FORM_SHOES_ADD_FEATURE,
    payload: addData
  }
}

// 属性の削除
export function deleteFeature(featureListId) {
  return {
    type: ActionTypes.FORM_SHOES_REMOVE_FEATURE,
    payload: featureListId
  }
}

// JSONファイルのアップロード
export function uploadJson(jsonFile, measurementId) {
  return function(dispatch, getState) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    return new Promise(async (resolve, reject) => {
      const filename = jsonFile.name
      const params = new FormData()
      const blob = new Blob([jsonFile], { type: 'application/json' })
      params.append('measurement_id', measurementId)
      params.append('filename', filename)
      params.append('upfile', jsonFile)
      const res = await apiClient2({
        url: `/products/measurements/value/${measurementId}/${filename}`,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: blob
      })
      if (res.data.statusCode === 200) {
        // 登録成功
        crateToast('success')('JSONを登録しました')
        dispatch(fetchDetail(productId))
      } else {
        // 登録失敗
        console.error(res)
        crateToast('error')('JSONの登録に失敗しました')
      }
    })
  }
}

// 3Dデータのアップロード
export function uploadThreeData(file, measurementId) {
  return function(dispatch, getState) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    return new Promise(async (resolve, reject) => {
      const filename = file.name
      const res = await apiClient2({
        method: 'post',
        url: `/products/measurements/three/${measurementId}/${filename}`,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        data: file
      })
      if (res.data.statusCode === 200) {
        // 登録成功
        crateToast('success')('3Dデータを登録しました')
        dispatch(fetchDetail(productId))
      } else {
        // 登録失敗
        console.error(res)
        crateToast('error')('JSONの登録に失敗しました')
      }
    })
  }
}

// 3Dデータの取得
export function getThreeUrl(filename, measurementId) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'get',
        url: `/products/measurements/three/${measurementId}/${filename}`,
        data: {
          measurementId,
          filename
        }
      })
      if (data.statusCode === 200) {
        resolve(data.message)
      } else {
        console.error(data)
        crateToast('error')('データがありません')
      }
    })
  }
}

// 計測詳細データの取得
export function fetchmeasurement(measurement) {
  return function(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'get',
        url: `/products/measurements/value/${measurement.id}`
      })
      if (data.statusCode === 200) {
        resolve({ json: data.data })
      } else {
        console.error(data)
        crateToast('error')('データがありません')
        reject()
      }
    })
  }
}

// 計測詳細データの更新
export function updatemeasurement(measValue, measurement) {
  return function(dispatch, getState) {
    const productId = getState().pages.shoesProductDetail.formShoesCommon
      .productId
    return new Promise(async (resolve, reject) => {
      const { data } = await apiClient({
        method: 'put',
        url: `/products/${productId}/measurements`,
        data: [
          convertKeys.toSnake({
            measurementId: measurement.id,
            measValue
          })
        ]
      })
      if (data.statusCode === 200) {
        crateToast('success')('更新しました')
        resolve({ ok: true })
      } else {
        crateToast('error')('更新に失敗しました')
        console.error(data)
        reject()
      }
    })
  }
}
