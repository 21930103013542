/* @flow */
import React from 'react'
import Dropzone from 'react-dropzone'

import { API_BASE_URL_V1 } from '../constants'

import {
  Grid,
  Button,
  IconButton,
  GridList,
  GridListTile,
  GridListTileBar
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

type Props = {
  classes: Object,
  mesh: Object,
  handleDrop: () => void,
  handleDelete: (Object, string) => void,
  variation: Object,
  swap: (?number, number) => void
}

type State = {
  draggingIdx: ?number
}

class ImageDrop extends React.Component<Props, State> {
  state = {
    draggingIdx: null
  }

  dragStart = (e: any) => {
    this.setState({ draggingIdx: Number(e.target.id) })
  }

  drop = (e: any) => {
    e.preventDefault()
    this.props.swap(this.state.draggingIdx, Number(e.target.id))
    this.setState({ draggingIdx: null })
  }

  render() {
    const { classes, handleDrop, variation, mesh } = this.props

    return (
      <React.Fragment>
        <Grid item xs={6}>
          {mesh.pictures.length > 0 && (
            <div className={classes.files}>
              <GridList
                cellHeight={160}
                cols={3}
                style={{ overflow: 'hidden' }}
              >
                {mesh.pictures.map((tile: Object, idx) => (
                  <GridListTile key={idx} cols={1}>
                    <img
                      src={`${String(API_BASE_URL_V1)}/shoe/picture/${mesh.id}/${
                        tile.fileName
                      }?height=160`}
                      alt={mesh.id}
                      id={idx}
                      draggable={!variation.loading}
                      onDragOver={e => e.preventDefault()}
                      onDragStart={this.dragStart}
                      onDrop={this.drop}
                    />
                    <GridListTileBar
                      titlePosition="bottom"
                      actionIcon={
                        <IconButton
                          className={classes.titleBarIcon}
                          onClick={() =>
                            this.props.handleDelete(mesh.id, tile.fileName)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                      className={classes.titleBar}
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          )}
          <Dropzone
            onDrop={handleDrop}
            accept="image/*"
            style={{}}
            disabled={variation.loading}
          >
            <div className={classes.drop}>
              <Button
                variant="contained"
                color="primary"
                className={`bold ${classes.addFileButton}`}
                disabled={variation.loading}
              >
                画像をアップロードする
              </Button>
              <p className={classes.text}>
                ドラッグ&ドロップでもアップロードできます
              </p>
            </div>
          </Dropzone>
        </Grid>
      </React.Fragment>
    )
  }
}

export default ImageDrop
