/* @flow */
/* 靴詳細画面 */
import produce from 'immer'

import * as ActionTypes from '../../constants'

type State = {
  productId: ?string,
  unit: ?string,
  inputSize: {
    loading: boolean,
    size: string,
    jancode: string
  }
}

const initialState: State = {
  productId: null,
  unit: null,
  inputSize: {
    loading: false,
    size: '',
    jancode: ''
  }
}

export default (state: State = initialState, action: Object) => {
  return produce(state, draft => {
    switch (action.type) {
      // 詳細情報の登録
      case ActionTypes.FORM_SHOES_SET_PRODUCT_COMMON:
        const common = action.payload
        draft.productId = common.id
        draft.unit = common.unit
        break
      case ActionTypes.FORM_SHOES_ALL_CLEAR:
        draft.productId = null
        draft.unit = null
        draft.inputSize = initialState.inputSize
        break
      default:
    }
  })
}
